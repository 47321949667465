import React, { useEffect } from "react";
import itemOptions from "./itemOptions";
import { useDispatch, useSelector } from "react-redux";
import { deleteProperty } from "../../../Store/Actions/Properties/deletePropery";
import { useHistory } from "react-router-dom";
import { showPropertyModal } from "../../../Store/Actions/Properties/propertyModal";
import { getPropertiesById } from "../../../Store/Actions/Properties/getPropertiesById";
import { showShareDocumentModal } from "../../../Store/Actions/Properties/UploadDocument";
import {shareProperty} from "../../../Store/Actions/Properties/shareProperty";

export default ({ propertiesById, itemId = "id" }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const redirect = () => {
    history.push("/properties");
  };
  useEffect(() => {
    if (itemId === 'id') {
      itemId = localStorage.getItem('properties_by_id')
    }
  }, [])
  const actions = {
    delete: () => {
      dispatch(deleteProperty(localStorage.getItem('properties_by_id'), redirect));
    },
    edit: async () => {
      dispatch(showPropertyModal({ item: propertiesById, itemId: localStorage.getItem('properties_by_id') }));
    },
    download: () => {

    },
    share: () => {
      // dispatch(shareProperty([itemId]))
      dispatch(showShareDocumentModal([localStorage.getItem('properties_by_id')]))
    }
  };
  useEffect(() => {
    itemOptions.map((item) => {
      for (let key in actions) {
        if (item.id === key) {
          item.action = actions[key];
        }
      }
      return item;
    });
  }, []);
  return itemOptions.map((item) => (
    <div
      onClick={() => item.action(localStorage.getItem('properties_by_id'))}
      id={item.id}
      key={item.id}
      className={`option ${item?.border ? "border" : ""}`}
    >
      <span className="icon">
        <img src={item.icon} alt="" />
      </span>
      <span className="text">{item.text}</span>
    </div>
  ));
};
