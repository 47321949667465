import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../Store/Actions/Properties/toastActions";
import { logOut } from "../../Store/Actions/Auth/loginActions";

export const RenderProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.profileReducer.data);
  const [userDropdown, setUserDropdown] = useState(false);
  useEffect(() => {
    return window.addEventListener("mousedown", (e) => {
      if (e.target.closest(".nav__user-dropdown") === null) {
        setUserDropdown(false);
      }
    });
  }, []);
  const logout = () => {
    dispatch(
      logOut(() => {
        history.push("/login");
      })
    );
    dispatch(
      showToast({ title: "Log out", text: "Log out success", type: "success" })
    );
  };
  return (
    <div className="nav__user">
      <div
        onClick={() => setUserDropdown(!userDropdown)}
        className="nav__user-photo"
      >
        <img
          src={
            profileData?.contact_image
              ? profileData?.contact_image
              : "/assets/icons/default-user.svg"
          }
          alt=""
        />
      </div>
      {userDropdown && (
        <div className="nav__user-dropdown">
          <div className="user-dropdown__info">
            <div className="user-dropdown__name">{profileData?.name}</div>
            <div className="user-dropdown__email">
              {profileData?.contact_email}
            </div>
          </div>
          <div className="user-dropdown__links">
            <Link
              onClick={() => setUserDropdown(false)}
              to={"/account/settings"}
              className="user-dropdown__link"
            >
              <span className="icon">
                <img src="/assets/icons/settings.svg" alt="settings" />
              </span>
              <span className="text">Account Settings</span>
            </Link>
            <Link
              onClick={() => setUserDropdown(false)}
              to={"/support"}
              className="user-dropdown__link"
            >
              <span className="icon">
                <img src="/assets/icons/question.svg" alt="settings" />
              </span>
              <span className="text">Support</span>
            </Link>
            <Link
              onClick={() => setUserDropdown(false)}
              to={"/terms"}
              className="user-dropdown__link"
            >
              <span className="icon">
                <img src="/assets/icons/file-text.svg" alt="settings" />
              </span>
              <span className="text">Terms of Use</span>
            </Link>
          </div>
          <div onClick={() => logout()} className="user-dropdown__logout">
            <span className="icon">
              <img src="/assets/icons/log-out.svg" alt="" />
            </span>
            <span className="text">Log out</span>
          </div>
        </div>
      )}
    </div>
  );
};
