import React, {useEffect, useState} from 'react'
import PlacesAutocomplete, {geocodeByAddress} from 'react-places-autocomplete'
import moment from "moment";
import {useSelector, useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";

import Api from "../../../API/api";
import {loaderHide, loaderShow} from "../../../Store/Actions/Loader/loaderActions";
import {BackButton, Button, Calendar, Input} from "../../../UI";
import {CHANGE__PROFILE_BIRTH, CHANGE_PROFILE_ADDRESS, CHANGE_PROFILE_NAME} from "../../../API/endpoints";
import {profileInfoData} from "../../../Global/initialData";

import './index.scss'
import {getProfile} from "../../../Store/Actions/Profile/getProfile";
import {showToast} from "../../../Store/Actions/Properties/toastActions";
import { Helmet } from 'react-helmet';


export default () => {

  const dispatch = useDispatch()
  const history = useHistory()

  const profileData = useSelector(state => state.profileReducer.data)
  const [data, setData] = useState({
    fullName: {...profileInfoData, id: 'fullName', placeholder: 'John Smith', label: 'Full name'},
    address: {
      ...profileInfoData,
      id: 'address',
      placeholder: '4821 Ridge Top Cir Anchorage, AK, 99593',
      label: 'Address'
    },
    birth: {...profileInfoData, id: 'birth', placeholder: 'MM / DD / YYYY', label: 'Date of birth'}
  })
  const [address, setAddress] = useState('')
  const [userAddress, setUserAddress] = useState({
    address: profileData?.address,
    address2: profileData?.address2,
    city: profileData?.city,
    state: profileData?.state,
    country: profileData?.country,
    zip: profileData?.zip,
  })
  const [selectDate, setSelectDate] = useState('')
  const [showCalendar, setShowCalendar] = useState(false)

  useEffect(() => {
    setData(old => ({
      ...old,
      fullName: {...old.fullName, value: profileData?.name ?? ''},
      address: {...old.address, value: profileData?.address ?? ''},
      birth: {...old.birth, value: profileData?.dob ?? ''}
    }))
  }, [profileData])

  useEffect(() => {
    setUserAddress(old => ({
      ...old,
      address: profileData?.address,
      address2: profileData?.address2,
      city: profileData?.city,
      state: profileData?.state,
      country: profileData?.country,
      zip: profileData?.zip,
    }))
    setAddress(profileData?.address)
  }, [profileData])

  useEffect(() => {
    return window.addEventListener('mousedown', (e) => {
      if (e.target.closest('.sdp') === null) {
        setShowCalendar(false)
      }
    })
  }, [])

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    setAddress(results[0].formatted_address)
    setUserAddress(old => ({
      ...old,
      address: results[0].formatted_address,
      address2: results[0].formatted_address
    }))
    results[0].address_components.map(item => {
      item.types.map(type => {
        if (type === "administrative_area_level_1") {
          setUserAddress(old => ({
            ...old,
            state: item.long_name
          }))
        }
        if (type === "administrative_area_level_2") {
          setUserAddress(old => ({
            ...old,
            city: item.long_name
          }))
        }
        if (type === "postal_code") {
          setUserAddress(old => ({
            ...old,
            zip: item.long_name
          }))
        }
        if (type === "country") {
          setUserAddress(old => ({
            ...old,
            country: item.short_name
          }))
        }
      })
    })
  }

  const inputHandler = (value, id) => {
    for (let key in data) {
      if (key === id) {
        setData(old => {
          if(key == 'birth') {
            let str = `${value[0] !== undefined ? Number(value[0]) > 1 ? '0' : value[0] : ''}${value[1] !== undefined ? Number(value[1]) > 2 ? '2' : value[1] : ""}${value[2] !== undefined ? "." : ""}${value[2] !== undefined && value[2] !== "." ? value[2] : ""}${value[3] !== undefined ? Number(value[3]) > 3 ? '3' : value[3] : ''}${value[4] !== undefined ? Number(value[3]) >= 3 && Number(value[4]) > 1 ? '1' : value[4] : ""}${value[5] !== undefined ? "." : ""}${value[5] !== undefined && value[5] !== "." ? value[5] : ""}${value[6] !== undefined ? Number(value[6]) > 2 ? '2' : value[6] : ''}${value[7] !== undefined ? value[7] : ""}${value[8] !== undefined ? value[8] : ''}${value[9] !== undefined ? value[9] : ''}`
            setSelectDate(str)
            return  {
              ...old,
              [key]: {
                ...old[key],
                value: str,
              }
            }
          }
          else {
            return {
              ...old,
              [key]: {
                ...old[key],
                value: value
              }
            }
          }
        })
      }
    }
  }

  const saveHandler = () => {
    if (profileData?.name !== data.fullName.value) {
      dispatch(loaderShow())
      const body = {
        name: data.fullName.value
      }
      Api.putData(CHANGE_PROFILE_NAME, body, localStorage.getItem('userToken'))
        .then((res) => {
          dispatch(showToast({title: 'Personal information', text: res.data.message, type: 'success'}))
          dispatch(getProfile())
        })
        .catch((err) => {
          dispatch(showToast({title: 'Personal information', text: err.response.data.message, type: 'error'}))
          dispatch(loaderHide())
        })
    }
    if(profileData?.address !== userAddress.address) {
      dispatch(loaderShow())
      Api.putData(CHANGE_PROFILE_ADDRESS, userAddress, localStorage.getItem('userToken'))
        .then((res) => {
          dispatch(showToast({title: 'Personal information', text: res.data.message, type: 'success'}))
          dispatch(getProfile())
        })
        .catch((err) => {
          dispatch(showToast({title: 'Personal information', text: err.response.data.message, type: 'error'}))
          dispatch(loaderHide())
        })
    }
    if(profileData?.dob !== data.birth.value) {
      dispatch(loaderShow())
      let body = {
        dob: selectDate
      }
      Api.putData(CHANGE__PROFILE_BIRTH, body, localStorage.getItem('userToken'))
        .then((res) => {
          dispatch(showToast({title: 'Personal information', text: res.data.message, type: 'success'}))
          dispatch(getProfile())
        })
        .catch((err) => {
          dispatch(showToast({title: 'Personal information', text: err.response.data.message, type: 'error'}))
          dispatch(loaderHide())
        })
    }
  }

  const datePicker = (date) => {
    let str = moment(date).format("MM.DD.yyyy")
    setData(old => ({
      ...old,
      birth: {...old.birth, value: moment(date).format("MM.DD.yyyy")}
    }))
    setSelectDate(str)
    setShowCalendar(false);

  }

  const cancelHandler = () => {
    history.goBack()
  }

  const searchOptions = {
    types: ['address'],
    componentRestrictions: {country: "US"}
  }
  return (
    <section style={showCalendar ? {paddingBottom: '150px'} : {}} className="account__information">
      <Helmet>
        <title>Personal Information | Missit</title>
      </Helmet>
      <div className="container account__container">
        <div className="account__nav">
          <BackButton type={"block"} link={'/account/settings'}/>
          <div className="account__nav-info">
            <h1 className="account__nav-title">Personal information</h1>
            <h3 className="account__nav-description">Full name, address, date of birth, language.</h3>
          </div>
        </div>
        <div className="information__name">
          <Input id={data.fullName.id} label={data.fullName.label} value={data.fullName.value}
                 placeholder={data.fullName.placeholder} action={inputHandler} error={data.fullName.error}
                 showError={data.fullName.errorVisible}/>
        </div>
        <div className="information__hr"></div>
        <div className="information__address">
          <PlacesAutocomplete
            value={address}
            onChange={setAddress}
            onSelect={handleSelect}
            searchOptions={searchOptions}
          >
            {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
              <div>
                <label className='information__placesLabel'>Address</label>
                <input className='information__placesInput' {...getInputProps({placeholder: 'Type address'})}/>
                <div>
                  {loading ? <div className='information__places'>...loading</div> : null}
                  {suggestions.map((suggestion) => {
                    const style = {
                      backgroundColor: suggestion.active ? '#F4F5F6' : '#fff',
                      cursor: 'pointer'
                    }
                    return <div
                      key={suggestion.description}
                      {...getSuggestionItemProps(suggestion, {style})}
                    >
                      {suggestion.description}
                    </div>
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </div>
        <div className="information__hr"></div>
        <div className="information__address">
          <Input id={data.birth.id} label={data.birth.label} value={data.birth.value}
                 placeholder={data.birth.placeholder} action={inputHandler} error={data.birth.error}
                 showError={data.birth.errorVisible} disabled={false} click={() => {
                  setShowCalendar(true);
                }}/>
          {showCalendar && <Calendar action={datePicker}/>}
        </div>
        <div className="account__btns">
          <div className="account__btn">
            <Button action={saveHandler} type={'success'}><span>Save</span></Button>
          </div>
          <div className="account__btn">
            <Button action={cancelHandler} type={'gray'}><span>Cancel</span></Button>
          </div>
        </div>
      </div>
    </section>
  )
}
