import { loaderHide, loaderShow } from "../Loader/loaderActions";
import Api from "../../../API/api";
import { GET_PROPERTIES, PROPERTY_DOCUMENT } from "../../../API/endpoints";
import { getProperties } from "./getProperties";
import { hidePropertyModal } from "./propertyModal";
import {
  GET_FILE_BY_DOCUMENT_SUCCESS,
  HIDE_CREATE_DOCUMENT_MODAL,
  HIDE_DOCUMENT_UPLOAD_MODAL,
  HIDE_MOVE_DOCUMENT_MODAL,
  SHOW_SHARE_DOCUMENT_MODAL,
  HIDE_SHARE_DOCUMENT_MODAL,
  SHOW_CREATE_DOCUMENT_MODAL,
  SHOW_DOCUMENT_UPLOAD_MODAL,
  SHOW_MOVE_DOCUMENT_MODAL,
} from "../../Types/Properties/properties";
import { getAllDocuments } from "./getAllDocuments";
import { showToast } from "./toastActions";
import {getItemById} from "./getPropertiesById";

export const createDocument = (body, redirect, location) => (dispatch) => {
  dispatch(loaderShow());
  const formData = new FormData();
  formData.append("name", body.name);
  formData.append("property_id", body.property);
  Api.postData(PROPERTY_DOCUMENT, formData, localStorage.getItem("userToken"))
    .then((res) => {
      dispatch(
        showToast({
          title: "Document",
          text: res.data.message,
          type: "success",
        })
      );
      dispatch(loaderHide());
      dispatch(hideCreateDocumentModal());
      dispatch(getAllDocuments());
      if (location.pathname !== "/my-documents") {
        dispatch(showUploadFileModal());
      }
      if (location.pathname === '/properties/item/info') {
        dispatch(getItemById(localStorage.getItem('item_by_id')))
      }
      redirect();
    })
    .catch((err) => {
      dispatch(
        showToast({
          title: "Document",
          text: err.response.data.message,
          type: "error",
        })
      );
      dispatch(hideCreateDocumentModal());
      dispatch(loaderHide());
    });
};

export const addFileToDocument = (body) => (dispatch) => {
  dispatch(loaderShow());
  const formData = new FormData();
  // formData.append('file', body.files)
  for (let i = 0; i < body.files.length; i++) {
    formData.append("file", body.files[i]);
  }

  Api.postData(
    `${PROPERTY_DOCUMENT}/${body.id}`,
    formData,
    localStorage.getItem("userToken")
  )
    .then((res) => {
      dispatch(
        showToast({ title: "File", text: res.data.message, type: "success" })
      );
      dispatch(hideUploadFileModal());
      dispatch(loaderHide());
      // dispatch(getProperties())
    })
    .catch((err) => {
      dispatch(
        showToast({
          title: "File",
          text: err.response.data.message,
          type: "error",
        })
      );
      dispatch(loaderHide());
    });
};

export const documentDelete = (id) => (dispatch) => {
  dispatch(loaderShow());
  Api.delete(
    `${PROPERTY_DOCUMENT}/${id}`,
    {},
    localStorage.getItem("userToken")
  )
    .then((res) => {
      dispatch(
        showToast({
          title: "Document",
          text: res.data.message,
          type: "success",
        })
      );
      dispatch(getAllDocuments());
      // dispatch(getProperties())
    })
    .catch((err) => {
      dispatch(
        showToast({
          title: "Document",
          text: err.response.data.message,
          type: "error",
        })
      );
      dispatch(loaderHide());
    });
};

export const moveFile = (body) => (dispatch) => {
  dispatch(loaderShow());
  Api.postData(
    `${PROPERTY_DOCUMENT}/${body.fileId}/${body.propertyId}`,
    {},
    localStorage.getItem("userToken")
  )
    .then((res) => {
      dispatch(
        showToast({
          title: "Document",
          text: res.data.message,
          type: "success",
        })
      );
      dispatch(getAllDocuments());
      dispatch(hideMoveDocumentModal());
      // dispatch(getProperties())
    })
    .catch((err) => {
      dispatch(
        showToast({
          title: "Document",
          text: err.response.data.message,
          type: "error",
        })
      );
      dispatch(loaderHide());
    });
};

export const getFileByDocument = (body) => (dispatch) => {
  dispatch(loaderShow());
  Api.getData(
    `${PROPERTY_DOCUMENT}/${body.fileId}`,
    localStorage.getItem("userToken")
  )
    .then((res) => {
      const data = res.data.data.map((item, idx) => {
        if (idx === 0) {
          item.picked = true;
        } else {
          item.picked = false;
        }
        return item;
      });
      dispatch(getFileByDocumentsSuccess({ data }));
      dispatch(loaderHide());
    })
    .catch((err) => {
      dispatch(
        showToast({
          title: "Document",
          text: err.response.data.message,
          type: "error",
        })
      );
      dispatch(loaderHide());
    });
};

export const showUploadFileModal = (payload) => {
  return {
    type: SHOW_DOCUMENT_UPLOAD_MODAL,
    payload,
  };
};
export const hideUploadFileModal = () => {
  return {
    type: HIDE_DOCUMENT_UPLOAD_MODAL,
  };
};

export const showCreateDocumentModal = (payload) => {
  return {
    type: SHOW_CREATE_DOCUMENT_MODAL,
    payload,
  };
};

export const hideCreateDocumentModal = () => {
  return {
    type: HIDE_CREATE_DOCUMENT_MODAL,
  };
};

export const showMoveDocumentModal = (payload) => {
  return {
    type: SHOW_MOVE_DOCUMENT_MODAL,
    payload,
  };
};

export const hideMoveDocumentModal = () => {
  return {
    type: HIDE_MOVE_DOCUMENT_MODAL,
  };
};

export const getFileByDocumentsSuccess = (payload) => {
  return {
    type: GET_FILE_BY_DOCUMENT_SUCCESS,
    payload,
  };
};

export const showShareDocumentModal = (id) => {
  return {
    type: SHOW_SHARE_DOCUMENT_MODAL,
    payload: id
  };
};

export const hideShareDocumentModal = () => {
  return {
    type: HIDE_SHARE_DOCUMENT_MODAL,
  };
};
