import { LOG_OUT } from "../Types/Auth/login";
import { PROFILE_DATA } from "../Types/Profile/profile";

const initialState = {
  data: null,
};

export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case PROFILE_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case LOG_OUT: {
      return {
        data: null,
      };
    }
    default:
      return state;
  }
}
