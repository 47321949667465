import React from "react";
import {loaderHide, loaderShow} from "../Loader/loaderActions";
import Api from "../../../API/api";
import {GET_PROPERTIES, ITEM} from "../../../API/endpoints";
import {getProperties} from "./getProperties";
import {hidePropertyModal} from "./propertyModal";
import {getItemById, getPropertiesById} from "./getPropertiesById";
import {useSelector} from "react-redux";
import {showToast} from "./toastActions";

export const editProperty = (id, body, location) => (dispatch) => {
    dispatch(loaderShow())
    const formData = new FormData()
    if (body.image.data) {
        formData.append('image', body.image.data)
    }
    formData.append('name', body.name)
    formData.append('value', body.value)
    formData.append('type_id', body.type_id)
    Api.postData(`${GET_PROPERTIES}/${id}`, formData, localStorage.getItem('userToken'))
        .then(async (res) => {
            dispatch(showToast({title: 'Property', text: res.data.message, type: 'success'}))
            dispatch(hidePropertyModal())
            dispatch(getProperties())
            await dispatch(getPropertiesById(id))
        })
        .catch(err => {
            dispatch(showToast({title: 'Property', text: err.response.data.message, type: 'error'}))
            dispatch(loaderHide())
        })
}

