import React, {useEffect, useState} from 'react'
import {BackButton, Button, Input} from "../../../UI";

import {DefaultModal} from "../../../Components";
import { Helmet } from 'react-helmet';

import './index.scss'
import {passwordData} from "../../../Global/initialData";
import {Link, useHistory, useLocation} from "react-router-dom";
import {validationPassword} from "../../../Global/validation";
import Api from "../../../API/api";
import {CHANGE_PROFILE_PASSWORD} from "../../../API/endpoints";
import {useSelector, useDispatch} from "react-redux";
import {loaderHide, loaderShow} from "../../../Store/Actions/Loader/loaderActions";
import {showToast} from "../../../Store/Actions/Properties/toastActions";

export default () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const location = useLocation()
    const [statusPasswordModal, setStatusPasswordModal] = useState(false)
    const profileData = useSelector(state => state.profileReducer.data)
    const [modalData, setModalData] = useState({
        current: {...passwordData, label: 'Current password', id: "current"},
        new: {...passwordData, label: "New password", id: "new"},
        confirm: {...passwordData, label: 'Confirm new password', id: "confirm"}
    })

    const changePassword = () => {
        showPasswordModal()
    }
    const changePIN = () => {
        if(profileData?.hasPasscode) {
            history.push('/account/security/pin', {type: 'enter', prevPath: location.pathname})
        } else {
            history.push('/account/security/pin', {type: 'create', prevPath: location.pathname})
        }
    }
    const closePasswordModal = () => {
        setStatusPasswordModal(false)
    }
    const showPasswordModal = () => {
        setStatusPasswordModal(true)
    }
    const modalInputHandler = (value, id) => {
        for (let key in modalData) {
            if (key === id) {
                setModalData(old => {
                    return {
                        ...old,
                        [key]: {
                            ...old[key],
                            value: value,
                            errorVisible: false
                        }
                    }
                })
            }
        }
    }
    const checkPassword = (password, key) => {
        if (validationPassword(password)) {
            setModalData(old => {
                return {
                    ...old,
                    [key]: {...old[key], errorVisible: false}
                }
            })
            return true
        } else {
            setModalData(old => {
                return {
                    ...old,
                    [key]: {...old[key], errorVisible: true}
                }
            })
            return false
        }
    }
    const newPasswordHandler = () => {
        const params = {
            current: null,
            new: null,
            confirm: null
        }
        checkPassword(modalData.current.value, 'current') ? params.current = modalData.current.value : params.current = null
        checkPassword(modalData.new.value, 'new') ? params.new = modalData.new.value : params.new = null
        checkPassword(modalData.confirm.value, 'confirm') ? params.confirm = modalData.confirm.value : params.confirm = null
        if (modalData.new.value !== modalData.confirm.value) {
            setModalData(old => {
                return {...old, confirm: {...old.confirm, errorVisible: true}}
            })
            params.confirm = null
        } else {
            setModalData(old => {
                return {...old, confirm: {...old.confirm, errorVisible: false}}
            })
            params.confirm = modalData.confirm.value
        }
        if (params.current && params.new && params.confirm) {
            dispatch(loaderShow())
            const formData = new FormData()
            formData.append('email', profileData?.contact_email)
            formData.append('password', params.new)
            formData.append('current_password', params.current)
            Api.postData(CHANGE_PROFILE_PASSWORD, formData, localStorage.getItem('userToken'))
              .then((res) => {
                  dispatch(showToast({title: 'Change password', text: res.data.message, type: 'success'}))
                  dispatch(loaderHide())
                  setModalData(old => {
                      return {...old,
                          current: {...old.current, value: ''},
                          new: {...old.new, value: ''},
                          confirm: {...old.confirm, value: ''}
                      }
                  })
                  closePasswordModal()
              })
              .catch((err) => {
                  dispatch(showToast({title: 'Change password', text: err.response.data.message, type: 'error'}))
                  dispatch(loaderHide())
                  setModalData(old => {
                      return {...old, current: {...old.current, errorVisible: true, error: err.response.data.message}}
                  })
              })
        }
    }
    const renderInput = () => {
        let result = Object.keys(modalData).map((key) => [key, modalData[key]]);
        return result.map(item => (
            <div key={item[1].id} className="security__modal-input">
                <Input value={item[1].value} label={item[1].label}
                       id={item[1].id} type={item[1].type}
                       placeholder={item[1].placeholder}
                       error={item[1].error} showError={item[1].errorVisible}
                       action={modalInputHandler}/>
            </div>
        ))
    }
    const renderModal = () => (
        <DefaultModal close={closePasswordModal} title={'Change password'}>
            <div className="security__modal">
                {renderInput()}
                <div onClick={() => history.push('/forgot-password', {type: 'account'})} className="security__forgot">Forgot password?</div>
                <div className="account__hr"></div>
                <div className="security__modal-btn">
                    <Button type={'success'} action={newPasswordHandler}><span>Save</span></Button>
                </div>
            </div>
        </DefaultModal>
    )
    return (
        <section className="account__security">
            <Helmet>
                <title>Security | Missit</title>
            </Helmet>
            {statusPasswordModal && renderModal()}
            <div className="container account__container">
                <div className="account__nav">
                    <BackButton type={"block"} link={'/account/settings'}/>
                    <div className="account__nav-info">
                        <h1 className="account__nav-title">Security</h1>
                        <h3 className="account__nav-description">Account password
                            {/*, "Will it" PIN.*/}
                        </h3>
                    </div>
                </div>
                <div onClick={changePassword} className="security__link">
                    <span>Change Account password<img src="/assets/icons/profile-arrow.svg" alt="arrow"/></span>
                </div>
                {/*<div onClick={changePIN} className="security__link">*/}
                {/*    <span>Change PIN for “Will it”<img src="/assets/icons/profile-arrow.svg" alt="arrow"/></span>*/}
                {/*</div>*/}
            </div>
        </section>
    )
}
