import React, {useState} from "react";
import { Helmet } from "react-helmet";

import './index.scss'

export default () => {
  const [type, setType] = useState('terms')

  const renderTerms = () => {
    return (
      <>
        <h1>Terms of Use</h1>
        <h3>Last updated June 07, 2021</h3>
        <h2>AGREEMENT TO TERMS</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet aspernatur consequuntur cupiditate eius fugit
          impedit inventore laboriosam molestias neque odio omnis optio pariatur qui quos sapiente soluta, ullam, unde
          voluptatum?</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet aspernatur consequuntur cupiditate eius fugit
          impedit inventore laboriosam molestias neque odio omnis optio pariatur qui quos sapiente soluta, ullam, unde
          voluptatum?</p>
        <h2>INTELLECTUAL PROPERTY RIGHTS</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet aspernatur consequuntur cupiditate eius fugit
          impedit inventore laboriosam molestias neque odio omnis optio pariatur qui quos sapiente soluta, ullam, unde
          voluptatum?</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet aspernatur consequuntur cupiditate eius fugit
          impedit inventore laboriosam molestias neque odio omnis optio pariatur qui quos sapiente soluta, ullam, unde
          voluptatum?</p>
        <ol>
          <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
          <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
          <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
        </ol>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet aspernatur consequuntur cupiditate eius fugit
          impedit inventore laboriosam molestias neque odio omnis optio pariatur qui quos sapiente soluta, ullam, unde
          voluptatum?</p>
        <h2>CONTACT US</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet aspernatur consequuntur cupiditate eius fugit
          impedit inventore laboriosam molestias neque odio omnis optio pariatur qui quos sapiente soluta, ullam, unde
          voluptatum?</p>
        <span>Missit inc.</span>
        <span>4175 River Street</span>
        <span>Brigden, Ontario N0N 1B0</span>
        <span>Canada</span>
        <span>Phone: (+1_0000000000) </span>
        <span>Fax: (+1_0000000000)</span>
        <a href="#">missit.com</a>
      </>
    )
  }
  const renderPrivacy = () => {
    return (
      <>
        <h1>MISSIT SECURITY DATA POLCY</h1>
        <h2>AGREEMENT TO TERMS</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet aspernatur consequuntur cupiditate eius fugit
          impedit inventore laboriosam molestias neque odio omnis optio pariatur qui quos sapiente soluta, ullam, unde
          voluptatum?</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet aspernatur consequuntur cupiditate eius fugit
          impedit inventore laboriosam molestias neque odio omnis optio pariatur qui quos sapiente soluta, ullam, unde
          voluptatum?</p>
        <ul>
          <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
          <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
          <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
        </ul>
      </>
    )
  }

  return (
    <div className='terms container'>
      <Helmet>
        <title>Terms and Privacy | Missit</title>
      </Helmet>
      <div className="terms__tabs">
        <div onClick={() => {
          setType('terms')
        }} className={`terms__tab ${type === 'terms' && 'active-tab'}`}>Terms of service
        </div>
        <div onClick={() => {
          setType('privacy')
        }} className={`terms__tab ${type === 'privacy' && 'active-tab'}`}>Privacy Policy
        </div>
      </div>
      {type === 'terms' ? renderTerms() : renderPrivacy()}
    </div>
  )
}
