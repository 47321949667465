import React from "react";
import {Search} from "../../../UI";

export default ({getSearchValue, typeList, setTypeList}) => {
    return (
        <div className="properties-item__docs-header">
            <div></div>
            <div className="properties-item__search">
                <Search getValue={getSearchValue}/>
            </div>
            <div className="properties-item__types">
                <div onClick={() => setTypeList(true)}
                     className={`properties-item__type ${typeList ? 'active' : ''}`}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="6.5" y="9.5" width="6" height="6" rx="2" transform="rotate(90 6.5 9.5)"
                              fill="#2B1D8D"/>
                        <rect x="6.5" y="0.5" width="6" height="6" rx="2" transform="rotate(90 6.5 0.5)"
                              fill="#2B1D8D"/>
                        <rect x="15.5" y="0.5" width="6" height="6" rx="2" transform="rotate(90 15.5 0.5)"
                              fill="#2B1D8D"/>
                        <rect x="15.5" y="9.5" width="6" height="6" rx="2" transform="rotate(90 15.5 9.5)"
                              fill="#2B1D8D"/>
                    </svg>
                </div>
                <div onClick={() => setTypeList(false)}
                     className={`properties-item__type ${!typeList ? 'active' : ''}`}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="16" y="10.5" width="5" height="16" rx="2.5" transform="rotate(90 16 10.5)"
                              fill="#ACAFB1"/>
                        <rect x="16" y="0.5" width="5" height="16" rx="2.5" transform="rotate(90 16 0.5)"
                              fill="#ACAFB1"/>
                    </svg>
                </div>
            </div>
        </div>
    )
}
