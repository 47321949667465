import React, {useState} from "react";
import {DefaultModal} from "../index";
import {useDispatch} from "react-redux";
import {getRelatives, hideAddMemberModal, showWillItemModal} from "../../Store/Actions/WillItem/getItemDetails";
import {addMember} from "../../Global/initialData";
import {validationText} from "../../Global/validation";
import {Button, Input} from "../../UI";

import './index.scss'
import {loaderHide, loaderShow} from "../../Store/Actions/Loader/loaderActions";
import Api from "../../API/api";
import {CREATE_RELATIVE} from "../../API/endpoints";
import {showToast} from "../../Store/Actions/Properties/toastActions";

export default () => {
  const dispatch = useDispatch()
  const [data, setData] = useState({
    fullName: {...addMember, id: 'fullName', placeholder: 'Enter assignees full name', label: 'Full name'},
    relationship: {...addMember, id: 'relationship', placeholder: 'Select your relationship with an assignee', label: 'Relationship'}
  })

  const inputHandler = (value, id) => {
    for (let key in data) {
      if (key === id) {
        setData(old => {
          return {
            ...old,
            [key]: {
              ...old[key],
              value: value,
              errorVisible: false
            }
          }
        })
      }
    }
  }

  const inputRender = () => {
    let result = Object.keys(data).map((key) => [key, data[key]]);
    return result.map(item => (
      <div key={item[1].id} className="new-password__input">
        <Input value={item[1].value} label={item[1].label}
               id={item[1].id} type={item[1].type}
               placeholder={item[1].placeholder}
               error={item[1].error} showError={item[1].errorVisible}
               action={inputHandler}/>
      </div>
    ))
  }

  const checkInput = (value, key) => {
    if (validationText(value)) {
      setData(old => {
        return {
          ...old,
          [key]: {...old[key], errorVisible: false}
        }
      })
      return true
    } else {
      setData(old => {
        return {
          ...old,
          [key]: {...old[key], errorVisible: true}
        }
      })
      return false
    }
  }

  const handleSave = () => {
    const params = {
      name: null,
      relationship: null,
    }
    checkInput(data.fullName.value, 'fullName') ? params.name = data.fullName.value : params.name = null;
    checkInput(data.relationship.value, 'relationship') ? params.relationship = data.relationship.value : params.relationship = null;
    if(params.name && params.relationship) {
      dispatch(loaderShow())
      Api.postData(`${CREATE_RELATIVE}?passcode_token=${localStorage.getItem('passcode_token')}`, params, localStorage.getItem('userToken'))
        .then((res) => {
          dispatch(hideAddMemberModal())
          dispatch(loaderHide())
          dispatch(showToast({title: 'Add relative', text: res.data.message, type: 'success'}))
        })
        .catch((err) => {
          dispatch(showToast({title: 'Add relative', text: err.response.data.message, type: 'error'}))
          dispatch(loaderHide())
        })
    }
  }


  const hideModal = () => {
    dispatch(hideAddMemberModal())
    dispatch(getRelatives())
  }
  return (
    <DefaultModal close={hideModal} title={'Add Member'}>
      <div className="addMemberModal">
        <div className="addMemberModal__form">
          {inputRender()}
        </div>
        <div className="addMemberModal__btn">
          <Button type={'success'} action={handleSave}>
            <span>Save</span>
          </Button>
        </div>
      </div>
    </DefaultModal>
  )
}
