import {FORGOT_CODE, FORGOT_RESET} from "../Types/Auth/forgot";

const initialState = {
    verify_token: null,
    password_token: null,
}

export default function  authReducer (state = initialState, action) {
    switch (action.type) {
        case FORGOT_RESET:
            return {
                ...state,
                verify_token: action.payload
            }
        case FORGOT_CODE:
            return {
                ...state,
                password_token: action.payload
            }
        default:
            return state;
    }
}
