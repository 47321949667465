import React, {useState} from 'react';
import Select from 'react-select'
import {Button} from "../index";

import './index.scss'

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: '100%',
  }),

  control: () => ({
    width: '100%',
    border: '1px solid #E5E7E9',
    borderRadius: 8,
    display: 'flex',
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#5140C8' : '#222B45',
    backgroundColor: state.isSelected ? '#DBE0E4' : '#FFFFFF'
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return {...provided, opacity, transition};
  }
}

export default ({defaultValue,options, placeholder, handleSelect, showButton=false, handleButton, buttonText, typeButton, customRef}) => {
  const customOption = ({innerRef, innerProps, isDisabled, children}) =>
    !isDisabled ? (
        <div  ref={innerRef} {...innerProps} className="customMenu">
          <div className="customMenu__children">
            {children}
          </div>
          {showButton && (<Button type={typeButton} action={handleButton}><span>{buttonText}</span></Button>)}
        </div>
    ) : null;
  return (
    <>
    <Select
        ref={customRef && customRef}
        options={options}
        styles={customStyles}
        defaultValue={defaultValue}
        placeholder={placeholder}
        isSearchable={false}
        onChange={handleSelect}
        components={{Menu: customOption}}
      />
    </>
  );
};

