import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";

import { Button, Input } from "../../../UI";

import {
  validationEmail,
  validationPassword,
} from "../../../Global/validation";
import { login } from "../../../Store/Actions/Auth/loginActions";
import { emailData, passwordData } from "../../../Global/initialData";

import "./index.scss";
import { showToast } from "../../../Store/Actions/Properties/toastActions";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  // DATA
  const dispatch = useDispatch();
  const history = useHistory();
  const [inputsData, setInputsData] = useState({
    email: { ...emailData },
    password: { ...passwordData },
  });
  useEffect(() => {
    if (localStorage.getItem("userToken")) {
      history.push("/properties");
    } else {
    }
  }, []);
  // RENDERS
  const inputRender = () => {
    let result = Object.keys(inputsData).map((key) => [key, inputsData[key]]);
    return result.map((item) => (
      <div key={item[1].id} className="login__input">
        <Input
          value={item[1].value}
          label={item[1].label}
          id={item[1].id}
          type={item[1].type}
          placeholder={item[1].placeholder}
          error={item[1].error}
          showError={item[1].errorVisible}
          action={inputHandler}
        >
          {item[1].isPassword && (
            <img
              onClick={() => {
                for (let key in inputsData) {
                  if (key === item[1].id) {
                    setInputsData((old) => ({
                      ...old,
                      [key]: {
                        ...old[key],
                        type: old[key].type == "password" ? "text" : "password",
                      },
                    }));
                  }
                }
              }}
              src={
                inputsData.password.type === "password"
                  ? "./assets/icons/eye.svg"
                  : "./assets/icons/eye-off.svg"
              }
              alt="svg"
            />
          )}
        </Input>
      </div>
    ));
  };
  // ACTIONS
  const inputHandler = (value, id) => {
    for (let key in inputsData) {
      if (key === id) {
        setInputsData((old) => {
          return {
            ...old,
            [key]: {
              ...old[key],
              value: value,
            },
          };
        });
      }
    }
  };
  const redirect = (type) => {
    if (type) {
      history.push("/properties");
    } else {
      history.push("/new-password");
    }
  };
  const submitHandler = (e) => {
    e?.preventDefault();
    const params = {
      email: null,
      password: null,
    };
    // CHANGE BACK VALIDATION
    if (true) {
      setInputsData((old) => {
        return { ...old, email: { ...old.email, errorVisible: false } };
      });
      params.email = inputsData.email.value;
    } else {
      setInputsData((old) => {
        return { ...old, email: { ...old.email, errorVisible: true } };
      });
    }
    if (validationPassword(inputsData.password.value)) {
      setInputsData((old) => {
        return { ...old, password: { ...old.password, errorVisible: false } };
      });
      params.password = inputsData.password.value;
    } else {
      setInputsData((old) => {
        return { ...old, password: { ...old.password, errorVisible: true } };
      });
    }
    if (params.email && params.password) {
      dispatch(login(params, redirect));
    }
  };
  // JSX
  return (
    <section className="login">
      <Helmet>
        <title>Login | Missit</title>
      </Helmet>
      <div className="left">
        <div className="login__img">
          <img src="/assets/images/login-bg.png" alt="image" />
        </div>
      </div>
      <div className="right">
        <form
          onSubmit={submitHandler}
          autoComplete={"off"}
          className="login__content"
        >
          <div className="login__logo">
            <img src="/assets/big-logo.svg" alt="logo" />
          </div>
          <h1 className="login__title">Login as a Customer</h1>
          <div className="login__form">{inputRender()}</div>
          <div className="login__link df">
            <Link to={"/forgot-password"}>Forgot password?</Link>
            <Link to={"/signup"}>Sign Up</Link>
          </div>
          <div className="login__btn">
            <Button type={"purple"}>
              <span>Login</span>
            </Button>
          </div>
        </form>
      </div>
    </section>
  );
};
