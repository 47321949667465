import {HIDE_PROPERTY_MODAL, SHOW_PROPERTY_MODAL} from "../../Types/Properties/properties";

export const hidePropertyModal = () => {
    return {
        type: HIDE_PROPERTY_MODAL
    }
}
export const showPropertyModal = (action) => {
    return {
        type: SHOW_PROPERTY_MODAL,
        payload: action
    }
}
