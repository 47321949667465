import { loaderHide, loaderShow } from "../Loader/loaderActions";
import Api from "../../../API/api";
import { GET_ALL_ITEMS, GET_PROPERTIES } from "../../../API/endpoints";
import {
  ALL_PROPERTIES,
  GET_ALL_ITEMS_SUCCESS,
  GET_ALL_TYPE_PROPERTY,
  MAIN_ID,
  SHOW_ITEM,
  SHOW_PROPERTY,
} from "../../Types/Properties/properties";

export const getProperties = () => (dispatch) => {
  dispatch(loaderShow());
  Api.getData(GET_PROPERTIES, localStorage.getItem("userToken"))
    .then((res) => {
      dispatch(getSuccess(res.data.data));
      dispatch(loaderHide());
    })
    .catch((err) => {
      dispatch(loaderHide());
    });
};
export const getAllItems = () => (dispatch) => {
  dispatch(loaderShow());
  Api.getData(GET_ALL_ITEMS, localStorage.getItem("userToken"))
    .then((res) => {
      dispatch(getAllItemsSuccess(res.data.data));
      dispatch(loaderHide());
    })
    .catch((err) => {
      dispatch(loaderHide());
    });
};

export const getAllPropertyType = () => (dispatch) => {
  dispatch(loaderShow());
  Api.getData(`${GET_PROPERTIES}/types`, localStorage.getItem("userToken"))
    .then((res) => {
      const array = res.data.data.map((item) => {
        return { value: item.id, label: item.name };
      });
      dispatch(loaderHide());
      dispatch(getAllTypes(array));
    })
    .catch((err) => {});
};

export const getSuccess = (payload) => {
  return {
    type: ALL_PROPERTIES,
    payload,
  };
};

export const mainID = (payload) => {
  return {
    type: MAIN_ID,
    payload,
  };
};

export const getAllItemsSuccess = (payload) => {
  return {
    type: GET_ALL_ITEMS_SUCCESS,
    payload,
  };
};

export const getAllTypes = (payload) => {
  return {
    type: GET_ALL_TYPE_PROPERTY,
    payload,
  };
};

export const showPropertyHandler = () => {
  return {
    type: SHOW_PROPERTY,
  };
};

export const showItemHandler = () => {
  return {
    type: SHOW_ITEM,
  };
};
