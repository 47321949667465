import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import RenderHeader from "./RenderHeader";
import RenderNav from "./RenderNav";
import RenderDocsHeader from "./RenderDocsHeader";
import RenderTabs from "./RenderTabs";

import RenderContent from "./RenderContent";
import "./index.scss";
import {
  getItemById,
  getPropertiesById,
} from "../../../Store/Actions/Properties/getPropertiesById";
import { Helmet } from "react-helmet";
import {
  deleteProperty,
  deletePropertyItem,
  showDeleteModal,
  showEditable,
} from "../../../Store/Actions/Properties/deletePropery";
import { shareProperty } from "../../../Store/Actions/Properties/shareProperty";
import { showShareDocumentModal } from "../../../Store/Actions/Properties/UploadDocument";
import { logDOM } from "@testing-library/react";
import Api from "../../../API/api";
import { GET_PROPERTIES } from "../../../API/endpoints";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ idArray, setIdArray }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const propertiesById = useSelector(
    (state) => state.propertiesReducer.propertiesById
  );
  const showProperty = useSelector(
    (state) => state.propertiesReducer.showProperty
  );
  // STATE
  const [itemDropdown, setItemDropdown] = useState(false);
  const [data, setData] = useState([]);
  const [docsData, setDocsData] = useState([]);
  const [initialDocsData, setInitialDocsData] = useState([]);
  const [activeTab, setActiveTab] = useState(true);
  const [typeList, setTypeList] = useState(true);
  const [editable, setEditable] = useState(false);
  const [isShared, setIsShared] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false)
  const dataModal = useSelector((state) => state.propertiesReducer.deleteItems);

  // LOAD
  useEffect(() => {
    propertiesById && setData([...propertiesById?.contents]);
    propertiesById && setDocsData([...propertiesById?.documents]);
    propertiesById && setInitialDocsData([...propertiesById?.documents]);
    setActiveTab(location.state?.tab ?? true);
    localStorage.setItem("property-item", propertiesById?.name);
  }, [propertiesById]);
  useEffect(() => {
    if (location.pathname === "/properties/item") {
      if (propertiesById === null && !showProperty) {
        dispatch(getPropertiesById(localStorage.getItem("properties_by_id")));
      }
    }
  }, [idArray, isAllSelected]);
  useEffect(() => {
    setTimeout(() => {
      Api.getData(
        `${GET_PROPERTIES}/${localStorage.getItem(
          "properties_by_id"
        )}/contents`,
        localStorage.getItem("userToken")
      ).then((response) => {
        setData(response.data.data.sort((a, b) => b.id - a.id));
      });
      Api.getData(
        `${GET_PROPERTIES}/${localStorage.getItem(
          "properties_by_id"
        )}/documents`,
        localStorage.getItem("userToken")
      ).then((response) => {
        setDocsData(response.data.data);
      });
    }, 1000);
  }, [propertiesById]);

  useEffect(() => {
    dispatch(showEditable(false));
  }, [location.pathname]);

  // ACTIONS
  const getSearchValue = (value) => {
    let newData = [...docsData];
    newData = newData.filter((item) => item.name.toLowerCase().includes(value));
    value.length > 0 ? setDocsData(newData) : setDocsData(initialDocsData);
  };
  const deleteCheckedItems = () => {
    idArray.forEach((item) => {
      dispatch(deletePropertyItem(item, location.state?.itemId));
    });
    setEditable(false);
  };
  const deleteModal = () => {
    if (idArray.length > 0) {
      dispatch(showDeleteModal(idArray, location.state?.itemId, editable));
    }
  };
  const sharedCheckedItems = () => {
    if (idArray.length > 0) {
      // dispatch(shareProperty(idArray))
      dispatch(showShareDocumentModal(idArray));
      // setIdArray([])
      setEditable(true);
      dispatch(showEditable(true));
    }
  };

  const dropdownShow = () => {
    setItemDropdown(!itemDropdown);
  };
  const select = () => {
    if (dataModal.editable) {
      dispatch(showEditable(false));
    } else {
      dispatch(showEditable(true));
    }
  };
  const getCheckboxId = (id) => {
    let ids = idArray;
    if (ids.length > 0) {
      for (let i = 0; i < ids.length; i++) {
        if (ids[i] === id) {
          ids = ids.filter((item) => item !== ids[i]);
          setIdArray([...ids]);
        } else {
          setIdArray([...ids, id]);
        }
      }
    } else {
      setIdArray([id]);
    }
  };

  const selectAll = () => {
    setIsAllSelected(!isAllSelected)
    setIdArray(data.map(el => el.id))
    if (isAllSelected) {
      setIdArray([])
    }
  }

  return (
    <section className="properties-item">
      <Helmet>
        <title>Property | Missit</title>
      </Helmet>
      <div className="container properties-item__container">
        <RenderNav propertiesById={propertiesById} />
        <RenderHeader
          itemId={location.state?.itemId}
          propertiesById={propertiesById}
          dropdownShow={dropdownShow}
          itemDropdown={itemDropdown}
        />
        {dataModal?.editable ? (
          <div className="properties-item__edit-actions">
            <div className="left">
              <div
                  onClick={selectAll}
                  className="properties-item__edit-action"
              >
                <div className="img">
                  <img src="/assets/icons/select-all.svg" alt="share" />
                </div>
                <div className="text">Select all</div>
              </div>
              {isShared && (
                <div
                  onClick={sharedCheckedItems}
                  className="properties-item__edit-action"
                >
                  <div className="img">
                    <img src="/assets/icons/share.svg" alt="share" />
                  </div>
                  <div className="text">Share</div>
                </div>
              )}
              {isDelete && (
                <div
                  onClick={deleteModal}
                  className="properties-item__edit-action"
                >
                  <div className="img">
                    <img src="/assets/icons/delete.svg" alt="share" />
                  </div>
                  <div className="text delete">Delete</div>
                </div>
              )}
            </div>
            <div className="right">
              <div className="properties-item__edit-counter">
                {idArray.length} selected
              </div>
              <div
                onClick={() => {
                  setEditable(false);
                  select();
                  setIdArray([]);
                  setIsShared(false);
                  setIsDelete(false);
                }}
                className="properties-item__edit-action close"
              >
                <div className="img">
                  <img src="/assets/icons/x.svg" alt="close" />
                </div>
                <div className="text">Cancel</div>
              </div>
            </div>
          </div>
        ) : (
          <RenderTabs activeTab={activeTab} setActiveTab={setActiveTab} />
        )}
        {!dataModal?.editable && data.length > 0 && activeTab && (
          <div className="properties-item__edit">
            <button
              onClick={() => {
                setEditable(true);
                setIsShared(!isShared);
                setIsDelete(!isDelete);
                select();
              }}
            >
              Select
            </button>
          </div>
        )}
        {!activeTab && (
          <RenderDocsHeader
            getSearchValue={getSearchValue}
            typeList={typeList}
            setTypeList={setTypeList}
          />
        )}
        <RenderContent
          itemId={location.state?.itemId}
          activeTab={activeTab}
          data={data}
          docsData={docsData}
          typeList={typeList}
          editable={editable}
          setEditable={setEditable}
          getCheckboxId={getCheckboxId}
          idArray={idArray}
          setIsShared={setIsShared}
          setIsDelete={setIsDelete}
          isAllSelected={isAllSelected}
        />
      </div>
    </section>
  );
};
