import {loaderHide, loaderShow} from "../Loader/loaderActions";
import Api from "../../../API/api";
import {FORGOT_SET_PASSWORD, FORGOT_VERIFY, LOGIN, RESET_PASSWORD} from "../../../API/endpoints";
import {loginSuccess} from "./loginActions";
import {FORGOT_CODE, FORGOT_RESET} from "../../Types/Auth/forgot";
import {showToast} from "../Properties/toastActions";

export const forgotPassword = (payload, redirect) => (dispatch) => {
    dispatch(loaderShow())
    Api.postData(RESET_PASSWORD, payload)
        .then(res => {
            dispatch(showToast({title: 'Forgot password', text: res.data.message, type: 'success'}))
            dispatch(loaderHide())
            dispatch(forgotSendEmail(res.data.data.verify_token))
            redirect()
        })
        .catch(err => {
            dispatch(showToast({title: 'Forgot password', text: err.response.data.message, type: 'error'}))
            dispatch(loaderHide())
        })
}

export const forgotCode = (payload, redirect) => (dispatch) => {
    dispatch(loaderShow())
    Api.postData(FORGOT_VERIFY, payload)
        .then(res => {
            dispatch(showToast({title: 'Forgot code', text: res.data.message, type: 'success'}))
            dispatch(loaderHide())
            dispatch(forgotSendCode(res.data.data.password_token))
            redirect()
        })
        .catch(err => {
            dispatch(showToast({title: 'Forgot code', text: err.response.data.message, type: 'error'}))
            dispatch(loaderHide())
        })
}

export const forgotSetPassword = (payload, redirect) => (dispatch) => {
    dispatch(loaderShow())
    Api.postData(FORGOT_SET_PASSWORD, payload)
        .then(res => {
            dispatch(showToast({title: 'Set password', text: res.data.message, type: 'success'}))
            dispatch(loaderHide())
            redirect()
        })
        .catch(err => {
            dispatch(showToast({title: 'Set password', text: err.response.data.message, type: 'error'}))
            dispatch(loaderHide())
        })
}

export const forgotSendEmail = (payload) => {
    return {
        type: FORGOT_RESET,
        payload
    }
}

export const forgotSendCode = (payload) => {
    return {
        type: FORGOT_CODE,
        payload
    }
}

