import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useHistory } from "react-router-dom";
import Api from "../../API/api";
import { useDispatch } from "react-redux";
import {
  loaderHide,
  loaderShow,
} from "../../Store/Actions/Loader/loaderActions";
import { showToast } from "../../Store/Actions/Properties/toastActions";
import { useSelector } from "react-redux";
import "./index.scss";

const STRIPE_KEY =
  "pk_live_51JoHwaABB9tg3mtTvTseQHdyErCrbVVhAmh0Emwy0zSdDgM91x7r03TBLD0CUNnCvXThkpn4ay50vMbBhA18NQvo00CsqnQlzP";

// LIVE STRIPE KEY = pk_live_51JoHwaABB9tg3mtTvTseQHdyErCrbVVhAmh0Emwy0zSdDgM91x7r03TBLD0CUNnCvXThkpn4ay50vMbBhA18NQvo00CsqnQlzP
// TEST STRIPE KEY = pk_test_51JoHwaABB9tg3mtT8dwyXJpx5XnaofwTBi19bg19Z5hUGGkm9JzckWE4Vf8wTixXZoFr8n9R0BziT1nMVOG7c6ti00GR3Opzoq

// eslint-disable-next-line import/no-anonymous-default-export
export const CheckoutForm = ({ clientSecret, price_stripe_id, totalFee }) => {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.profileReducer.data);

  const CARD_OPTIONS = {
    labels: "floating",
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#979B9D",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSmoothing: "antialiased",
        backgroundColor: "#F4F5F6",
        padding: "5px 10px 5px 20px",
        // paddingLeft: 10,
        // marginLeft: 10,
        // left: 2,
        lineHeight: "50px",
        borderRadius: 10,
        fontWeight: 500,
        fontSize: 14,
        color: "#1A1D1F",
        ":-webkit-autofill": {
          color: "#fce883",
        },
        "::placeholder": {
          color: "#000000",
          text: "Card",
        },
      },
      input: {
        backgroundColor: "#F4F5F6",
        lineHeight: 16,
        borderRadius: 10,
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "red",
      },
      icon: {
        // left: 15,
        // paddingLeft: 15,
      },
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(loaderShow());
    if (elements == null) {
      return;
    }
    await stripe
      .confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: userData?.name_full,
          },
        },
      })
      .then(function (result) {
        if (result.error) {
          dispatch(
            showToast({
              title: "Error",
              text: result.error.message,
              type: "error",
            })
          );
          dispatch(loaderHide());
          // Inform the customer that there was an error.
        } else {
          Api.postData(
            `profile/subscriptions/subscribe`,
            {
              payment_method: result.setupIntent.payment_method,
              price_stripe_id: price_stripe_id,
            },
            localStorage.getItem("userToken")
          )
            .then((res) => {
              dispatch(loaderHide());
              dispatch(
                showToast({
                  title: "Success",
                  text: res.data.message,
                  type: "success",
                })
              );
              history.push("/");
            })
            .catch((err) => {
              dispatch(loaderHide());
              dispatch(
                showToast({
                  title: "Sessions",
                  text: err.response.data.message,
                  type: "error",
                })
              );
            });
        }
      });
  };
  return (
    <form className="Form" onSubmit={handleSubmit}>
      <div className="FormRow">
        <div className={"substrate-icon"}></div>
        <CardElement style={{ marginTop: 100 }} options={CARD_OPTIONS} />
      </div>
      <button
        className={`SubmitButton`}
        type="submit"
        disabled={!stripe || !elements || !totalFee}
      >
        Pay ${totalFee}
      </button>
    </form>
  );
};

const stripePromise = loadStripe(STRIPE_KEY);
export const Stripe = (props) => {
  const [clientSecret, setClientSecret] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (clientSecret == null) {
      dispatch(loaderShow());
      Api.getData(
        `profile/subscriptions/get-secret`,
        localStorage.getItem("userToken")
      )
        .then((res) => {
          dispatch(loaderHide());
          setClientSecret(res.data.data.secret);
        })
        .catch((error) => {
          dispatch(loaderHide());
        });
    }
  }, []);

  if (!clientSecret) {
    return <h1>Wait</h1>;
  } else {
    return (
      <Elements stripe={stripePromise}>
        <section className="signup__subscription">
          {clientSecret && (
            <CheckoutForm
              price_stripe_id={props.price_stripe_id}
              clientSecret={clientSecret}
              totalFee={props.totalFee}
            />
          )}
        </section>
      </Elements>
    );
  }
};
