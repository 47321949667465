import {loaderHide, loaderShow} from "../Loader/loaderActions";
import Api from "../../../API/api";
import {GET_PROFILE} from "../../../API/endpoints";
import {PROFILE_DATA} from "../../Types/Profile/profile";
import {showToast} from "../Properties/toastActions";

export const getProfile = () => (dispatch) => {
    dispatch(loaderShow())
    Api.getData(GET_PROFILE, localStorage.getItem('userToken'))
        .then(res => {
            dispatch(successProfile(res.data.data))
            dispatch(loaderHide())
        })
        .catch(err => {
            dispatch(showToast({title: 'Profile', text: err.response.data.message, type: 'error'}))
        })
}

export const successProfile = (payload) => {
    return {
        type: PROFILE_DATA,
        payload
    }
}
