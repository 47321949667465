export default [
    // {
    //     id: 'download',
    //     text: 'Download property',
    //     icon: '/assets/icons/download.svg',
    // },
    {
        id: 'share',
        text: 'Share property',
        icon: '/assets/icons/share.svg',
    },
    {
        id: 'edit',
        text: 'Edit',
        icon: '/assets/icons/edit.svg',
    },
    {
        id: 'delete',
        text: 'Delete this property',
        icon: '/assets/icons/delete.svg',
    }
]
