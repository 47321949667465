import React, {useState} from 'react';
import './index.scss'

export default ({id, action, status, label, uid, uniq}) => {
  return (
    <div className={'checkbox'}>
      <input checked={status} type="checkbox" onChange={() => action && action(id, uid)} id={uniq}/>
      <label htmlFor={uniq}>
        <span>{label}</span>
      </label>
    </div>
  )
}
