import React, { useEffect, useMemo, useRef, useState } from "react";

import PinField from "react-pin-field";

import { BackButton } from "../../../UI";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import "./index.scss";
import Api from "../../../API/api";
import { CHANGE_PROFILE_PIN, FORGOT_PASSCODE } from "../../../API/endpoints";
import {
  loaderHide,
  loaderShow,
} from "../../../Store/Actions/Loader/loaderActions";
import { showToast } from "../../../Store/Actions/Properties/toastActions";
import { Helmet } from "react-helmet";
import { getProfile } from "../../../Store/Actions/Profile/getProfile";

export default () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const codeFiled = useRef("");
  let pageType = location.state?.type;
  const [prevPath, setPrevPath] = useState(location.state?.prevPath);
  const history = useHistory();
  const [titles, setTitles] = useState({
    enter: "Enter current PIN",
    create: "Create a new PIN",
    repeat: "Repeat the new PIN",
  });

  useEffect(() => {
    codeFiled.current.inputs[0].focus();
    pageType = location.state?.type;
  }, [location.state?.type]);
  const body = {
    current_passcode: "",
    passcode: "",
  };

  const stepOne = (value) => {
    if (location.state?.type === "enter") {
      codeFiled.current.inputs.forEach((input) => (input.value = ""));
      codeFiled.current.inputs[0].focus();
      body.current_passcode = value;
      history.push("/account/security/pin", { type: "create", state: body });
    }
  };
  const stepTwo = (value) => {
    if (location.state?.type === "create") {
      codeFiled.current.inputs.forEach((input) => (input.value = ""));
      codeFiled.current.inputs[0].focus();
      if (location.state.state !== undefined) {
        location.state.state.passcode = value;
        history.push("/account/security/pin", {
          type: "repeat",
          state: location.state.state,
        });
      } else {
        body.passcode = value;
        history.push("/account/security/pin", { type: "repeat", state: body });
      }
    }
  };
  const stepThree = (value) => {
    if (location.state?.type === "repeat") {
      codeFiled.current.inputs.forEach((input) => (input.value = ""));
      codeFiled.current.inputs[0].focus();
      if (location.state.state.passcode === value) {
        dispatch(loaderShow());
        Api.postData(
          CHANGE_PROFILE_PIN,
          location.state.state,
          localStorage.getItem("userToken")
        )
          .then((res) => {
            dispatch(
              showToast({
                title: "Personal information",
                text: res.data.message,
                type: "success",
              })
            );
            dispatch(getProfile());
            setTimeout(() => {
              dispatch(loaderHide());
              history.push(`${prevPath}`);
            }, 500);
          })
          .catch((err) => {
            dispatch(
              showToast({
                title: "Personal information",
                text: err.response.data.message,
                type: "error",
              })
            );
            dispatch(loaderHide());
          });
      } else {
        dispatch(
          showToast({
            title: "Personal information",
            text: "Passwords do not match",
            type: "error",
          })
        );
      }
    }
  };
  const forgotPin = () => {
    dispatch(loaderShow());
    Api.postData(FORGOT_PASSCODE, null, localStorage.getItem("userToken"))
      .then((res) => {
        dispatch(loaderHide());
        history.push("/confirm-code", { typePage: "pin" });
      })
      .catch((err) => {
        dispatch(loaderHide());
        dispatch(
          showToast({
            title: "Forgot PIN",
            text: err.response.data.message,
            type: "error",
          })
        );
      });
  };
  useEffect(() => {
    for (let i = 0; i < document.querySelectorAll("input").length; i++) {
      document.querySelectorAll("input")[i].setAttribute("type", "number");
    }
  }, []);
  return (
    <section className="account__pin">
      <Helmet>
        <title>Account Pin || Missit</title>
      </Helmet>
      <div className="container account__container">
        <div className="account__nav">
          <BackButton type={"block"} link={"/account/security"} />
          <div className="account__nav-info">
            <h1 className="account__nav-title">PIN for “Will it”</h1>
            <h3 className="account__nav-description">Change PIN</h3>
          </div>
        </div>
        <div className="pin__content">
          <div className="pin__image">
            <img src="/assets/icons/lock-pin.svg" alt="lock" />
          </div>
          <h1 className="pin__title">
            {titles[location.state?.type] ?? "Default title"}
          </h1>
          {location.state?.type === "enter" && (
            <div className="pin__code">
              <PinField
                ref={codeFiled}
                type={"number"}
                inputmode={"number"}
                length={6}
                validate={/^[0-9]$/}
                validate={/^[0-9]+$/}
                format={(key) => key}
                onComplete={(value) => stepOne(value)}
              />
            </div>
          )}
          {location.state?.type === "create" && (
            <div className="pin__code">
              <PinField
                ref={codeFiled}
                type={"number"}
                inputmode={"number"}
                length={6}
                validate={/^[0-9]+$/}
                format={(key) => key}
                onComplete={(value) => stepTwo(value)}
              />
            </div>
          )}
          {location.state?.type === "repeat" && (
            <div className="pin__code">
              <PinField
                ref={codeFiled}
                type={"number"}
                inputmode={"number"}
                length={6}
                validate={/^[0-9]+$/}
                format={(key) => key}
                onComplete={(value) => stepThree(value)}
              />
            </div>
          )}
          {location.state?.type === "enter" && (
            <div onClick={() => forgotPin()} className="pin__forgot">
              Forgot PIN?
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
