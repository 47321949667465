export default [
  {
    id: "personalInformation",
    title: "Personal information",
    description: "Full name,  address, date of birth, language.",
    icon: "/assets/icons/box.svg",
    to: "/account/personal-information",
  },
  {
    id: "subscription",
    title: "Subscription",
    description: "Your next billing date is",
    icon: "/assets/icons/star.svg",
    to: "/account/subscription",
  },
  {
    id: "share",
    title: "Share access with an agent",
    description: "Provision of access.",
    icon: "/assets/icons/share-2.svg",
    to: "/account/share",
  },
  {
    id: "session",
    title: "Session",
    description: "Here you can see the devices logged.",
    icon: "/assets/icons/session.svg",
    to: "/account/session",
  },
  {
    id: "email",
    title: "Email",
    description: "Change email.",
    icon: "/assets/icons/email.svg",
    to: "/account/email",
  },
  {
    id: "security",
    title: "Security",
    description: 'Account password',
    icon: "/assets/icons/lock.svg",
    to: "/account/security",
  },
  {
    id: "phoneNumber",
    title: "Phone Number",
    description: "Change phone number.",
    icon: "/assets/icons/phone.svg",
    to: "/account/phone-number",
  },
];
