export default [
    {
        id: 'share',
        text: 'Share item',
        icon: '/assets/icons/share.svg',
    },
    {
        id: 'edit',
        text: 'Edit',
        icon: '/assets/icons/edit.svg',
    },
    {
        id: 'delete',
        text: 'Delete this property',
        icon: '/assets/icons/delete.svg',
    }
]
