import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { showPropertyItemModal } from "../../Store/Actions/Properties/addItemToProperty";
import { showCreateDocumentModal } from "../../Store/Actions/Properties/UploadDocument";
import { Button } from "../../UI";
import { DefaultModal } from "../../Components";
import Api from "../../API/api";
import { ITEM } from "../../API/endpoints";
import { getItemById } from "../../Store/Actions/Properties/getPropertiesById";
import { loaderShow } from "../../Store/Actions/Loader/loaderActions";

export default ({ itemId, type }) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [data, setData] = useState({
    image: {
      preview: null,
      data: null,
    },
  });
  const addDocument = () => {
    dispatch(showCreateDocumentModal({ id: itemId }));
  };
  const closeModal = () => {
    setModal(false);
  };
  const showModal = () => {
    setModal(true);
  };
  const addImage = (pathPreview, dataPreview) => {
    setData((old) => {
      return {
        ...old,
        image: {
          preview: pathPreview,
          data: dataPreview,
        },
      };
    });
  };
  const submitHandler = () => {
    dispatch(loaderShow());
    const formData = new FormData();
    formData.append("photos[]", data.image.data);
    Api.postData(
      `${ITEM}/${itemId}/upload-photos`,
      formData,
      localStorage.getItem("userToken")
    )
      .then((res) => {
        setModal(false);
        dispatch(getItemById(itemId));
        setData({
          image: {
            preview: null,
            data: null,
          },
        });
      })
      .catch((err) => {
      });
  };
  const uploadImageModal = () => {
    return (
      <DefaultModal title="Upload Images" close={closeModal}>
        <div className="property-add__modal">
          <div className="property-modal__image">
            {data.image.preview ? (
              <div className="property-modal__preview">
                <img src={data.image.preview} alt="" />
              </div>
            ) : (
              <>
                <div className="img-placeholder">
                  <img src="/assets/icons/image-placeholder.png" alt="" />
                </div>
                <div className="cover">Cover image</div>
                <div className="browse">Browse Files</div>
              </>
            )}
            <div className="input">
              <input
                onChange={(e) =>
                  addImage(
                    URL.createObjectURL(e.target.files[0]),
                    e.target.files[0]
                  )
                }
                type="file"
              />
            </div>
          </div>
          <div className="account__hr"></div>
          <Button action={submitHandler} type="success">
            <span style={{ paddingBottom: 12, paddingTop: 12 }}>Save</span>
          </Button>
        </div>
      </DefaultModal>
    );
  };
  return (
    <div className="dashboard__content-empty">
      {modal && uploadImageModal()}
      <div className="empty__img">
        <img src="/assets/images/properties-item__empty.svg" alt="empty" />
      </div>
      <div className="empty__title">There are no {type ? 'images' : 'documents'} yet</div>
      <p className="empty__description">
        Here you can add and manage {type ? 'images' : 'documents'} related to this property.
      </p>
      <div className="empty__btn">
        <Button action={type ? showModal : addDocument} type="success">
          <span>Add {type ? "image" : "document"}</span>
        </Button>
      </div>
    </div>
  );
};
