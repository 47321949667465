import React from "react";

import "./index.scss";
import { useHistory } from "react-router-dom";
export default ({ type = "text", link }) => {
  const history = useHistory();
  return (
    <>
      {type === "text" ? (
        <div
          onClick={() =>
            link !== "back" ? history.push(link) : history.goBack()
          }
          className="back-button"
        >
          <img src="/assets/icons/arrow.svg" alt="arrow" />
          <span>Back</span>
        </div>
      ) : (
        <div
          onClick={() =>
            link !== "back" ? history.push(link) : history.goBack()
          }
          className="back-button block"
        >
          <img src="/assets/icons/back-button-arrow.svg" alt="" />
        </div>
      )}
    </>
  );
};
