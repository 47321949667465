import React, { useEffect, useState } from "react";
import { DefaultModal } from "../index";

import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllItems,
  getProperties,
} from "../../Store/Actions/Properties/getProperties";
import { Button, Input } from "../../UI";
import {
  createDocument,
  hideCreateDocumentModal,
} from "../../Store/Actions/Properties/UploadDocument";
import { useHistory, useLocation } from "react-router-dom";
import { getPropertiesById } from "../../Store/Actions/Properties/getPropertiesById";
import { getAllDocuments } from "../../Store/Actions/Properties/getAllDocuments";
import Select from "../../UI/Select";
export default () => {
  const dispatch = useDispatch();
  const properties = useSelector(
    (state) => state.propertiesReducer.data?.properties
  );
  const itemId = useSelector(
    (state) => state.propertiesReducer.createDocument?.id
  );
  const allItems = useSelector((state) => state.propertiesReducer.allItems);
  const propertiesById = useSelector(
    (state) => state.propertiesReducer.propertiesById
  );
  const mainID = useSelector((state) => state.propertiesReducer.mainId);
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState({
    name: "",
    property: null,
  });
  const [options, setOptions] = useState([]);
  useEffect(() => {
    dispatch(getAllItems());
  }, []);
  useEffect(() => {
    if (itemId) {
      setData((old) => ({
        ...old,
        property: itemId,
      }));
    }
    if (mainID) {
      setData((old) => ({
        ...old,
        property: mainID,
      }));
    }
  }, [itemId, mainID]);
  const getName = (name) => {
    setData((old) => ({ ...old, name }));
  };
  useEffect(() => {
    const array = [...properties, ...allItems].map((item) => {
      return { value: item.id, label: item.name };
    });
    setOptions(array);
  }, [properties, allItems]);

  const redirect = () => {
    if (location.pathname === "/properties/item") {
      dispatch(getPropertiesById(data.property));
      history.push("/properties/item", { itemId: data.property, tab: false });
    }
  };
  const submitHandler = () => {
    if (data.name.length > 0 && data.property) {
      dispatch(createDocument(data, redirect, location));
    }
  };
  const handleSelect = (value) => {
    setData((old) => ({ ...old, property: value }));
  };
  return (
    <DefaultModal
      close={() => dispatch(hideCreateDocumentModal())}
      title={"Create document"}
    >
      <div className="document-modal__container">
        <div>
          <div className="document-modal__input">
            <Input
              value={data.name}
              label={"Name"}
              placeholder={"Enter document name"}
              id={"name"}
              action={getName}
            />
          </div>
          {location.pathname !== '/my-documents' ? (
              !data.property && (
                  <div className="document-modal__select">
                    <Select
                        options={options}
                        placeholder={"Select property or item"}
                        handleSelect={(e) => handleSelect(e.value)}
                    />
                  </div>
              )
          ) :
              (
                  <div className="document-modal__select">
                    <Select
                        options={options}
                        placeholder={"Select property or item"}
                        handleSelect={(e) => handleSelect(e.value)}
                    />
                  </div>
              )}
        </div>
        <div className="document-modal__btn">
          <Button
            action={submitHandler}
            disable={!data.property || data.name.length === 0 ? true : false}
            type={"purple"}
          >
            <span>Save</span>
          </Button>
        </div>
      </div>
    </DefaultModal>
  );
};
