import {loaderHide, loaderShow} from "../Loader/loaderActions";
import Api from "../../../API/api";
import {INVITE_AGENTS} from "../../../API/endpoints";
import {PROFILE_DATA} from "../../Types/Profile/profile";
import {showToast} from "../Properties/toastActions";
import {AGENTS_DATA} from "../../Types/Profile/profile";

export const getResentAgents = () => (dispatch) => {
  dispatch(loaderShow())
  Api.getData(INVITE_AGENTS, localStorage.getItem('userToken'))
    .then((res) => {
      dispatch(successAgents(res.data.data))
      dispatch(loaderHide())
    })
    .catch((err) => {
      dispatch(showToast({title: 'Agents', text: err.response.data.message, type: 'error'}))
      dispatch(loaderHide())
    })
}

export const successAgents = (payload) => {
  return {
    type: AGENTS_DATA,
    payload
  }
}
