import React from "react";

import "./index.scss";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  label,
  type,
  action,
  placeholder,
  id,
  children,
  error,
  showError,
  value,
  actionName,
  disabled,
  click,
  maxLength = 200,
}) => {
  return (
    <div className="input" onClick={click ? () => click() : () => {}}>
      <div className="input__label">
        <label>{label}</label>
        <div className="input__action">{actionName && actionName()}</div>
      </div>
      <div className="input__field">
        <input
          disabled={disabled}
          value={value}
          className={showError ? "error" : ""}
          type={type}
          onChange={(e) => action && action(e.target.value, id)}
          placeholder={placeholder}
          id={id}
          maxLength={maxLength}
        />
        <div className="input__icon">{children}</div>
      </div>
      {showError && <div className="input__error">{error}</div>}
    </div>
  );
};
