import React from "react";
import {PropertyDoc} from "../../../Components";


export default ({typeList, docsData, itemId}) => {
    return (
        <div className={`dashboard__list ${typeList ? '' : 'line'}`}>
            {docsData.map((item, idx) => (
                <PropertyDoc itemId={itemId} item={item} key={idx} link={'/my-document'}/>
            ))}
        </div>
    )
}
