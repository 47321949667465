import React, { useEffect } from "react";
import {Link} from "react-router-dom";

export default ({propertiesById}) => {
    useEffect(() => {
        localStorage.setItem('property-name', propertiesById?.name)
    }, [])
    return (
        <div className="properties-item__nav">
            <Link to={'/properties'}>Properties</Link>
            <span>
                    <img src="/assets/icons/arrow_gray_right.svg" alt=""/>
                </span>
            <span>{propertiesById?.name}</span>
        </div>
    )
}
