import React, {useEffect, useRef, useState} from 'react'

import PinField from "react-pin-field"

import {BackButton} from "../../../UI";
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";

import './index.scss'
import Api from "../../../API/api";
import {SET_PIN} from "../../../API/endpoints";
import {loaderHide, loaderShow} from "../../../Store/Actions/Loader/loaderActions";
import {showToast} from "../../../Store/Actions/Properties/toastActions";
import { Helmet } from 'react-helmet';
// const code = '1234'
export default () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const codeFiled = useRef('')
  let pageType = location.state?.type
  const history = useHistory();
  const [titles, setTitles] = useState({
    create: 'Create a new PIN',
    repeat: 'Repeat the new PIN',
  })


  useEffect(() => {
    codeFiled.current.inputs[0].focus()
    pageType = location.state?.type
  }, [location.state?.type])
  const body = {
    token: location.state?.token,
    passcode: '',
  }
  const stepTwo = (value) => {
    if (location.state?.type === 'create') {
      codeFiled.current.inputs.forEach(input => (input.value = ""))
      codeFiled.current.inputs[0].focus()
      body.passcode = value
      history.push('/account/security/pin-forgot', {type: 'repeat', state: body})
    }
  }
  const stepThree = (value) => {
    if (location.state?.type === 'repeat') {
      codeFiled.current.inputs.forEach(input => (input.value = ""))
      codeFiled.current.inputs[0].focus()
      if (location.state.state.passcode === value) {
        dispatch(loaderShow())
        Api.postData(SET_PIN, location.state.state, localStorage.getItem('userToken'))
          .then((res) => {
            // localStorage.setItem('passcode_token', res.data.data.passcode_token)
            dispatch(loaderHide())
            history.push('/account/security')
            dispatch(showToast({title: 'Forgot PIN', text: res.data.message, type: 'success'}))
          })
          .catch((err) => {
            dispatch(loaderHide())
            dispatch(showToast({title: 'Forgot PIN', text: err.response.data.message, type: 'error'}))
          })
      } else {
        dispatch(showToast({
          title: 'Personal information',
          text: 'Passwords do not match',
          type: 'error'
        }))
      }

    }
  }
  // const forgotPin = () => {
  //   history.push('/forgot-password', {type: 'account', typePage: 'pin'})
  // }
  useEffect(() => {
    for (let i = 0; i < document.querySelectorAll('input').length; i++) {
      document.querySelectorAll('input')[i].setAttribute('type', 'number')
    }
  }, [])
  return (
    <section className="account__pin">
      <Helmet>
        <title>Account Pin | Missit</title>
      </Helmet>
      <div className="container account__container">
        <div className="account__nav">
          <BackButton type={"block"} link={'/account/security'}/>
          <div className="account__nav-info">
            <h1 className="account__nav-title">PIN for “Will it”</h1>
            <h3 className="account__nav-description">Change PIN</h3>
          </div>
        </div>
        <div className="pin__content">
          <div className="pin__image">
            <img src="/assets/icons/lock-pin.svg" alt="lock"/>
          </div>
          <h1 className="pin__title">{titles[location.state?.type] ?? 'Default title'}</h1>
          {/*{location.state?.type === 'enter' && (*/}
          {/*  <div className="pin__code">*/}
          {/*    <PinField ref={codeFiled} length={4} validate={/^[a-zA-Z0-9]$/} format={key => key} onComplete={(value) => stepOne(value) }/>*/}
          {/*  </div>*/}
          {/*)}*/}
          {location.state?.type === 'create' && (
            <div className="pin__code">
              <PinField ref={codeFiled} length={6} inputmode={'number'} validate={/^[0-9]+$/} format={key => key} onComplete={(value) => stepTwo(value) }/>
            </div>
          )}
          {location.state?.type === 'repeat' && (
            <div className="pin__code">
              <PinField ref={codeFiled} length={6} inputmode={'number'} validate={/^[0-9]+$/} format={key => key} onComplete={(value) => stepThree(value) }/>
            </div>
          )}
          {/*{location.state?.type === 'enter' && <div onClick={() => forgotPin()} className="pin__forgot">Forgot PIN?</div>}*/}
        </div>
      </div>
    </section>
  )
}
