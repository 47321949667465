import React, {useEffect, useState} from 'react';
import './index.scss'
import {DefaultModal} from "../index";
import {useDispatch, useSelector} from "react-redux";
import {hideMoveDocumentModal, moveFile} from "../../Store/Actions/Properties/UploadDocument";
import {getAllItems, getProperties} from "../../Store/Actions/Properties/getProperties";
import {Button} from "../../UI";

export default () => {
    const dispatch = useDispatch()
    const [selectItem, setSelectItem] = useState(null)
    const moveFileData = useSelector(state => state.propertiesReducer.moveDocumentModal)
    const allItemsData = useSelector(state => state.propertiesReducer.allItems)
    const properties = useSelector(state => state.propertiesReducer.data)
    const [data, setData] = useState([])
    const [allProperties, setAllProperties] = useState([])
    const [allItems, setAllItems] = useState([])
    const [activeItem, setActiveItem] = useState(false)
    useEffect(() => {
        dispatch(getProperties())
        dispatch(getAllItems())
    }, [])
    useEffect(() => {
        setData(properties.properties)
        setAllProperties(properties.properties)
    }, [properties])
    useEffect(() => {
        setAllItems(allItemsData)
    }, [allItemsData])
    const closeMoveDocumentModal = () => {
        dispatch(hideMoveDocumentModal())
    }
    const MoveDocumentProperty = ({item}) => {
        return (
            <div className={`move__property ${selectItem === item.id && 'move__property_active'}`}>
                <div className="move__left-side">
                    <div className="move__img">
                        <img src={item.preview_img} alt=""/>
                    </div>
                    <div className="move__box">
                        <h4 className="box__title">
                            {item.name}
                        </h4>
                        <p className="box__desc">
                            {item.itemized_value}
                        </p>
                    </div>
                </div>
                <div className="move__right-side">
                    <div onClick={() => {
                        pickItem(item.id)
                    }} className={`${selectItem === item.id ? 'radio_active' : 'move__radio'}`}>
                        <div className={`${selectItem === item.id ? 'move__radio_active' : 'move__radio_disabled'}`}>
                        </div>
                    </div>
                    {/*<input onChange={function (e) {*/}
                    {/*    pickItem(e.target.value)*/}
                    {/*}} value={item.id} id={item.id} type="radio"*/}
                    {/*       name={"move"}/>*/}
                </div>
            </div>
        )
    }
    const moveHandler = () => {
        dispatch(moveFile({fileId: moveFileData.fileId, propertyId: selectItem}))
    }
    const pickItem = (value) => {
        setSelectItem(value)
    }
    const showProperties = () => {
        setActiveItem(false)
        setData(allProperties)
    }
    const showItems = () => {
        setActiveItem(true)
        setData(allItems)
    }
    return (
        <DefaultModal title={'Move file to'} close={closeMoveDocumentModal}>
            <div className="move__select">
                <div onClick={showProperties} className={`move__item ${!activeItem && 'move__item_active'}`}>
                    <h4 className="move__title">
                        Property
                    </h4>
                </div>
                <div onClick={showItems} className={`move__item ${activeItem && 'move__item_active'}`}>
                    <h4 className="move__title">
                        Item
                    </h4>
                </div>
            </div>
            <div className="move__properties">
                {
                    data?.length > 0 && data.map((el) => {
                        return (
                            <MoveDocumentProperty key={el.id} item={el}/>
                        )
                    })
                }
            </div>
            <div className="move__btn">
                <Button action={moveHandler} type={"success"}><span>Move</span></Button>
            </div>
        </DefaultModal>
    );
};

