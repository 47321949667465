import React from "react";
import { DefaultModal } from "../index";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePropertyItem,
  hideDeleteModal,
  showDeleteModal,
} from "../../Store/Actions/Properties/deletePropery";

import { Button } from "../../UI";
import "./index.scss";

export default ({ idArray, setIdArray }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.propertiesReducer.deleteItems);
  const deleteCheckedItems = () => {
    data?.data.forEach((item) => {
      dispatch(deletePropertyItem(item, data?.itemId, setIdArray));
    });
    hideModal();
    // setEditable(false)
  };
  const hideModal = () => {
    dispatch(hideDeleteModal());
  };
  return (
    <DefaultModal
      close={hideModal}
      title={`Delete ${data?.data?.length} item(s)`}
    >
      <p className="deleteModal__text">Are you sure you want to delete?</p>
      <div className="deleteModal__btns">
        <div className="deleteModal__btn">
          <Button type={"danger"} action={deleteCheckedItems}>
            <span>Delete</span>
          </Button>
        </div>
        <div className="deleteModal__btn">
          <Button type={"gray"} action={hideModal}>
            <span>Cancel</span>
          </Button>
        </div>
      </div>
    </DefaultModal>
  );
};
