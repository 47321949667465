/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Api from "../../../API/api";
import {
  loaderHide,
  loaderShow,
} from "../../../Store/Actions/Loader/loaderActions";
import { showToast } from "../../../Store/Actions/Properties/toastActions";
import { BackButton } from "../../../UI";
import "./index.scss";

export default () => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [confirmModal, setConfirmModal] = useState();
  const profileData = useSelector((state) => state.profileReducer.data);

  useEffect(() => {
    getSubData();
  }, []);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setConfirmModal(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  const cancelSubscription = () => {
    dispatch(loaderShow());
    Api.postData(
      `profile/subscriptions/cancel`,
      {},
      localStorage.getItem("userToken")
    )
      .then((res) => {
        dispatch(loaderHide());
        dispatch(
          showToast({
            title: "Sessions",
            text: res.data.message,
            type: "success",
          })
        );
      })
      .catch((err) => {
        dispatch(loaderHide());
        dispatch(
          showToast({
            title: "Sessions",
            text: err.response.data.message,
            type: "error",
          })
        );
      });
  };
  const getSubData = () => {
    dispatch(loaderShow());
    Api.getData(`profile/subscriptions`, localStorage.getItem("userToken"))
      .then((res) => {
        dispatch(loaderHide());
        setData(res.data.data);
      })
      .catch((err) => {
        dispatch(loaderHide());
        dispatch(
          showToast({
            title: "Sessions",
            text: err.response.data.message,
            type: "error",
          })
        );
      });
  };
  console.log();

  const renderConfirmModal = () => (
    <div className="session__sure">
      <div className="session__sure-content">
        <div className="session__sure-header">
          <h5 className="session__sure-title">Are you sure</h5>
          <div
            className="session__sure-close"
            onClick={() => {
              setConfirmModal(false);
            }}
          >
            <img src="/assets/icons/close.svg" alt="close" />
          </div>
        </div>
        <div className="session__sure-descr">
          Are you sure you want to cancel subscription?
        </div>
        <div className="session__sure-btns">
          <div
            className="session__sure-cancel"
            onClick={() => {
              setConfirmModal(false);
            }}
          >
            Cancel
          </div>
          <div
            className="session__sure-terminate"
            onClick={() => {
              cancelSubscription();
            }}
          >
            Cancel subscription
          </div>
        </div>
      </div>
      <div
        className="session__sure-bg"
        onClick={() => {
          setConfirmModal(false);
        }}
      ></div>
    </div>
  );
  return (
    <section className="account__share subscription-info">
      <Helmet>
        <title>Account Share | Missit</title>
      </Helmet>
      {confirmModal && renderConfirmModal()}
      <div className="container account__container">
        <div className="account__nav">
          <BackButton type={"block"} link={"/account/settings"} />
          <div className="account__nav-info">
            <h1 className="account__nav-title">Subscription</h1>
            <h3 className="account__nav-description">
              {profileData?.next_period_date
                ? `Your next billing date is ${profileData?.next_period_date}`
                : "Your subscription canceled"}
            </h3>
          </div>
        </div>
        <div className="content">
          <div className="left">
            <div className="subscription-info__payment">
              <h2>Payment info</h2>
              <p>
                **** **** **** {data?.payment_methods[0]?.card_number ?? "****"}
              </p>
              <span>
                {profileData?.next_period_date
                  ? `Your next billing date is ${profileData?.next_period_date}`
                  : "Your subscription canceled"}
              </span>
            </div>
            <div className="subscription-info__receipts">
              <h2>Receipts</h2>
              <div className="receipts">
                <span>{data?.payment?.package_title}</span>
                <span>
                  from {data?.payment?.start_date} to {data?.payment?.end_date}
                </span>
              </div>
            </div>
          </div>
          <div className="right">
            {profileData?.next_period_date &&
            data?.payment?.provider === "apple" ? (
              <div className="subscription-info__message">
                To deactivate your account, please do it inside the iOS app.
              </div>
            ) : (
              <div
                className="subscription-info__cancel"
                onClick={() => {
                  setConfirmModal(true);
                }}
              >
                Cancel subscription
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
