import React from "react";


export default ({activeTab, setActiveTab}) => {
    return (
        <div className="properties-item__tabs">
            <div onClick={() => setActiveTab(!activeTab)}
                 className={`properties-item__tab ${activeTab ? 'active' : ''}`}>
                <div className="icon">
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_4451_2240)">
                            <path
                                d="M11.243 1.70927L17.9097 5.0426C18.1874 5.18059 18.421 5.39329 18.5844 5.65681C18.7479 5.92033 18.8345 6.2242 18.8347 6.53427V14.4759C18.8345 14.786 18.7479 15.0899 18.5844 15.3534C18.421 15.6169 18.1874 15.8296 17.9097 15.9676L11.243 19.3009C11.0115 19.4168 10.7561 19.4771 10.4972 19.4771C10.2383 19.4771 9.98289 19.4168 9.75134 19.3009L3.08468 15.9676C2.80728 15.8278 2.57447 15.6133 2.4125 15.3483C2.25053 15.0832 2.16585 14.7782 2.16801 14.4676V6.53427C2.16817 6.2242 2.25483 5.92033 2.41824 5.65681C2.58165 5.39329 2.81533 5.18059 3.09301 5.0426L9.75968 1.70927C9.99014 1.59475 10.244 1.53516 10.5013 1.53516C10.7587 1.53516 11.0125 1.59475 11.243 1.70927V1.70927Z"
                                stroke="#222B45" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_4451_2240">
                                <rect width="20" height="20" fill="white" transform="translate(0.5 0.5)"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className="text">Contents</div>
            </div>
            <div onClick={() => setActiveTab(!activeTab)}
                 className={`properties-item__tab ${!activeTab ? 'active' : ''}`}>
                <div className="icon">
                    <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8.2 1H2.6C2.17565 1 1.76869 1.16857 1.46863 1.46863C1.16857 1.76869 1 2.17565 1 2.6V15.4C1 15.8243 1.16857 16.2313 1.46863 16.5314C1.76869 16.8314 2.17565 17 2.6 17H12.2C12.6243 17 13.0313 16.8314 13.3314 16.5314C13.6314 16.2313 13.8 15.8243 13.8 15.4V6.6L8.2 1Z"
                            stroke="#979B9D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M8.19922 1V6.6H13.7992" stroke="#979B9D" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                </div>
                <div className="text">Docs</div>
            </div>
        </div>
    )
}
