import React, {useEffect, useState} from "react";
import { Helmet } from "react-helmet";
import './index.scss'
import {Checkbox} from "../../UI";
import {useDispatch, useSelector} from "react-redux";
import {getSharedHistory} from "../../Store/Actions/Properties/shareProperty";
import moment from "moment";
import Api from "../../API/api";
import {showToast} from "../../Store/Actions/Properties/toastActions";
import {loaderShow} from "../../Store/Actions/Loader/loaderActions";
import {logDOM} from "@testing-library/react";
export default () => {
  // STATE
  const dispatch = useDispatch()
  const historyData = useSelector((state) => state.propertiesReducer.sharedHistory)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(15)
    const [deleteIds, setDeleteIds] = useState([])
    const [currentId, setCurrentId] = useState(null)
    const [allData, setAllData] = useState([])
  // RENDER
  useEffect(() => {
    dispatch(getSharedHistory(page,perPage))
  }, [])

    useEffect(() => {
    }, [deleteIds])
    const deleteItem = (uid, id) => {
      dispatch(loaderShow())
        Api.delete(`share/${uid}`, {itemIds: [id]}, localStorage.getItem('userToken'))
            .then(res => {
                dispatch(getSharedHistory(page,perPage))
                dispatch(showToast({title: "Share", text: res.data.message, type: "success"}))
                // dispatch(showToast({title: "Share", text: res.data.message, type: "success"}))
            })
            .catch(err => {
                dispatch(showToast({title: "Share", text: err.response.data.message, type: "error"}))
            })
    }
    const deleteAllBlock = (uid) => {
        dispatch(loaderShow())
        Api.delete(`share/${uid}`, {itemIds: []}, localStorage.getItem('userToken'))
            .then(res => {
                dispatch(getSharedHistory(page,perPage))
                dispatch(showToast({title: "Share", text: res.data.message, type: "success"}))
            })
            .catch(err => {
                dispatch(showToast({title: "Share", text: err.response.data.message, type: "error"}))
            })
    }
    const checkedAll = () => {
    }
    const checkboxHandler = (id, uid) => {
      let ids = deleteIds;
      if (ids.length > 0) {
          for (let i = 0; i < ids.length; i++) {
              if (ids[i].id === id && ids[i].uid === uid) {
                  ids = ids.filter(item => item.id !== ids[i].id)
                  setDeleteIds([...ids])
              } else {
                  setDeleteIds([...ids, {id, uid}])
              }
          }
      } else {
          setDeleteIds([{id, uid}])
      }
    }

    const deleteCheckedItems = () => {
      for (let i = 0; i < deleteIds.length; i++) {
          deleteItem(deleteIds[i].uid, deleteIds[i].id)
      }
      setDeleteIds([])
    let inputs = document.querySelectorAll('input')
    for (let i = 0; i < inputs.length; i++) {
        inputs[i].checked = false
    }
    }


    const renderDataItem = (elem) => {
    return elem?.items.map((item) => {
            return (
                <div key={item.id} className="shared__block-item">
                    <div className="shared__item-column">
                        {/*<div className="shared__checkbox">*/}
                        {/*    <Checkbox id={item.id} uniq={Math.floor(Math.random()*505223)} uid={elem.uid} action={checkboxHandler}/>*/}
                        {/*</div>*/}
                        <div className="shared__item-preview">
                            <img src={item.preview_img ? item.preview_img : '/assets/icons/image-placeholder.png'} alt=""/>
                        </div>
                        <p className="shared__item-name">{item.name}</p>
                    </div>
                    <div className="shared__item-column">
                        <p className="shared__item-text"></p>
                    </div>
                    <div className="shared__item-column">
                        <p className="shared__item-text">{item.currency} {item.value}</p>
                    </div>
                    <div className="shared__item-column">
                        <p className="shared__item-text">{item.type ?? '-'}</p>
                    </div>
                    {/*<div className="shared__item-column">*/}
                    {/*    <div onClick={() => {*/}
                    {/*        deleteItem(elem.uid, item.id)*/}
                    {/*    }} className="shared__item-btn">*/}
                    {/*        <img src='/assets/icons/delete.svg' alt=""/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            )
    })
  }
  const renderDataView = (item) => {
    return (
        <div className="shared__block">
          <div className="shared__date">
            <div className="shared__date_item">
                {/*<div onClick={e => console.log(e.target)} className="shared__checkbox">*/}
                {/*    <Checkbox id={item.id}/>*/}
                {/*</div>*/}
                <p className="shared__dateText">{moment(item?.created_at).format('MMMM DD, YYYY, hh:mm A')}</p>
            </div>
              <div onClick={() => deleteAllBlock(item.uid)} className="share__delete">
                  Cancel share
              </div>
          </div>
          {renderDataItem(item)}
        </div>
    )
  }
    // const renderData = () => {
    //     historyData && historyData.map(item => {
    //         return renderDataView(item)
    //     })
    // }
  return (
    <div className="shared">
          <Helmet>
            <title>Shared | Missit</title>
          </Helmet>
        <div className="container shared__container">
            <div className="shared__top">
                <h1 className="shared__title">Shared history:</h1>
                {deleteIds?.length > 0 && <h2 onClick={deleteCheckedItems} className="shared__delete">Delete selected</h2>}
            </div>
          {
              historyData?.length !== 0 ? historyData?.map(item => {
                  return renderDataView(item)
              }) : <div className="shared__empty">
                  <h2>Once you share an item you’ll see it here</h2>
              </div>
          }
        </div>
    </div>
  );
};
