import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Api from "../../../API/api";
import {
  loaderHide,
  loaderShow,
} from "../../../Store/Actions/Loader/loaderActions";
import { showToast } from "../../../Store/Actions/Properties/toastActions";
import { BackButton } from "../../../UI";

import "./index.scss";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.profileReducer.data);
  const [sessions, setSessions] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  useEffect(() => {
    getSessions();
  }, []);
  const getSessions = () => {
    dispatch(loaderShow());
    Api.getData("sessions", localStorage.getItem("userToken"))
      .then((res) => {
        dispatch(loaderHide());
        setSessions(res.data.data);
      })
      .catch((err) => {
        dispatch(loaderHide());
        dispatch(
          showToast({
            title: "Sessions",
            text: err.response.data.message,
            type: "error",
          })
        );
      });
  };

  const renderEmpty = () => (
    <div className="session__empty">
      {/* <div className="session__empty-descr">Your list of agents</div> */}
      <div className="session__empty-block">
        <img src="/assets/images/session-empty.svg" alt="empty" />
        <p>Here you can see the devices logged in to your account</p>
      </div>
    </div>
  );

  const renderModal = () => (
    <div className="session__modal">
      <div className="session__modal-content">
        <div className="session__modal-header">
          <h2 className="session__modal-title">Session</h2>
          <div
            className="session__modal-close"
            onClick={() => {
              setModalShow(false);
            }}
          >
            <img src="/assets/icons/close.svg" alt="svg" />
          </div>
        </div>
        <div className="session__modal-preview">
          {currentItem?.app !== "" ? (
            currentItem?.app === "Desktop Web" ? (
              <img src="/assets/icons/laptop.svg" alt="preview_image" />
            ) : (
              <img src="/assets/icons/session-phone.svg" alt="preview_image" />
            )
          ) : currentItem?.type === "web" ? (
            <img src="/assets/icons/session-phone.svg" alt="preview_image" />
          ) : (
            <img src="/assets/icons/session-phone.svg" alt="preview_image" />
          )}
          <h5>
            {currentItem?.device_model !== ""
              ? currentItem?.device_model
              : currentItem?.app !== ""
              ? currentItem?.app
              : currentItem.type}
          </h5>
          <p>{currentItem?.last_seen}</p>
        </div>
        <div className="session__modal-data">
          <div className="block">
            <div className="key">App</div>
            <div className="value">
              {currentItem?.type === "web" ? "-" : currentItem?.app}
            </div>
          </div>
          <div className="block">
            <div className="key">IP Address</div>
            <div className="value">{currentItem?.ip_address}</div>
          </div>
          <div className="block">
            <div className="key">Location</div>
            <div className="value">{currentItem?.location}</div>
          </div>
        </div>
        <div className="session__modal-btn">
          <button
            onClick={() => {
              termiateHandler(currentItem?.id);
            }}
          >
            Terminate Session
          </button>
        </div>
      </div>
      <div
        className="session__modal-bg"
        onClick={() => {
          setModalShow(false);
        }}
      ></div>
    </div>
  );

  const termiateHandler = (session_id) => {
    dispatch(loaderShow());
    Api.postData(
      `sessions/terminate`,
      { session_id },
      localStorage.getItem("userToken")
    )
      .then((res) => {
        dispatch(loaderHide());
        history.push("/confirm-code", {
          typePage: "session",
          type: "session",
          phone: profileData?.contact_phone,
          token: res.data.data.token,
        });
      })
      .catch((err) => {
        dispatch(
          showToast({
            title: "Sessions",
            text: err.response.data.message,
            type: "error",
          })
        );
        dispatch(loaderHide());
      });
  };

  const renderConfirmModal = () => (
    <div className="session__sure">
      <div className="session__sure-content">
        <div className="session__sure-header">
          <h5 className="session__sure-title">Are you sure</h5>
          <div
            className="session__sure-close"
            onClick={() => {
              setConfirmModal(false);
            }}
          >
            <img src="/assets/icons/close.svg" alt="close" />
          </div>
        </div>
        <div className="session__sure-descr">
          Are you sure you want to terminate all other sessions?
        </div>
        <div className="session__sure-btns">
          <div
            className="session__sure-cancel"
            onClick={() => {
              setConfirmModal(false);
            }}
          >
            Cancel
          </div>
          <div
            className="session__sure-terminate"
            onClick={() => {
              termiateHandler(null);
            }}
          >
            Terminate all sessions
          </div>
        </div>
      </div>
      <div
        className="session__sure-bg"
        onClick={() => {
          setConfirmModal(false);
        }}
      ></div>
    </div>
  );

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setModalShow(false);
        setConfirmModal(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  return (
    <section className="account__share session">
      <Helmet>
        <title>Account Share | Missit</title>
      </Helmet>
      {modalShow && renderModal()}
      {confirmModal && renderConfirmModal()}
      <div className="container account__container">
        <div className="account__nav">
          <BackButton type={"block"} link={"/account/settings"} />
          <div className="account__nav-info">
            <h1 className="account__nav-title">Session</h1>
            <h3 className="account__nav-description">
              Here you can see the devices logged.
            </h3>
          </div>
        </div>
        {/* {renderEmpty()} */}

        <div className="session__current">
          <div className="session__subtitle">Current session</div>
          <div className="session__item" style={{ cursor: "default" }}>
            <div className="info">
              <img
                src={
                  sessions?.current_session?.type == "web"
                    ? "/assets/icons/laptop.svg"
                    : "/assets/icons/session-phone.svg"
                }
                alt="laptop"
              />
              <div className="text">
                <p>
                  {sessions?.current_session?.device_model !== ""
                    ? sessions?.current_session?.device_model
                    : sessions?.current_session?.app !== ""
                    ? sessions?.current_session?.app
                    : sessions?.current_session?.type}
                </p>
                <span>
                  {sessions?.current_session?.location ?? "USA"}
                  {/* &bull; Online */}
                </span>
              </div>
            </div>
            {/* <div className="dots">
              <span></span>
              <span></span>
              <span></span>
            </div> */}
          </div>

          {sessions?.sessions.length > 0 && (
            <>
              <div
                className="session__terminate"
                onClick={() => {
                  setConfirmModal(true);
                }}
              >
                <img src="/assets/icons/terminate.svg" alt="terminate" />
                <p>Terminate other sessions</p>
              </div>
              <div className="session__text_gray">
                Terminate on all devices except the current one
              </div>
            </>
          )}
        </div>
        {sessions?.sessions.length > 0 ? (
          <div className="session__current">
            <div className="session__subtitle">Active Sessions</div>
            {sessions?.sessions?.map((item, index) => (
              <div
                className="session__item"
                key={index}
                onClick={() => {
                  setModalShow(true);
                  setCurrentItem(item);
                }}
              >
                <div className="info">
                  {item?.app !== "" ? (
                    item?.app === "Desktop Web" ? (
                      <img src="/assets/icons/laptop.svg" alt="preview_image" />
                    ) : (
                      <img
                        src="/assets/icons/session-phone.svg"
                        alt="preview_image"
                      />
                    )
                  ) : item?.type === "web" ? (
                    <img
                      src="/assets/icons/session-phone.svg"
                      alt="preview_image"
                    />
                  ) : (
                    <img
                      src="/assets/icons/session-phone.svg"
                      alt="preview_image"
                    />
                  )}
                  <div className="text">
                    <p>
                      {item?.device_model !== ""
                        ? item?.device_model
                        : item?.app !== ""
                        ? item?.app
                        : item.type}
                    </p>
                    <span>
                      {item?.location ? item?.location + "," : ''} {item?.last_seen}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          renderEmpty()
        )}
      </div>
    </section>
  );
};
