import React, {useEffect, useMemo, useRef, useState} from 'react'

import PinField from "react-pin-field"
import { Helmet } from 'react-helmet';

import {BackButton} from "../../../UI";
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";

import './index.scss'
import Api from "../../../API/api";
import {FORGOT_PASSCODE, GET_PASSCODE_TOKEN} from "../../../API/endpoints";
import {loaderHide, loaderShow} from "../../../Store/Actions/Loader/loaderActions";
import {showToast} from "../../../Store/Actions/Properties/toastActions";
export default () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const codeFiled = useRef('')
  let pageType = location.state?.type
  const history = useHistory();
  const [titles, setTitles] = useState({
    enter: 'Enter current PIN',
    create: 'Enter your PIN',
    repeat: 'Repeat the new PIN',
  })

  useEffect(() => {
    codeFiled.current.inputs[0].focus()
    pageType = location.state?.type
  }, [location.state?.type])
  const body = {
    passcode: '',
  }

  useEffect(() => {
    for (let i = 0; i < document.querySelectorAll('input').length; i++) {
      document.querySelectorAll('input')[i].setAttribute('type', 'number')
    }
  }, [])
  const stepTwo = (value) => {
    if (location.state?.type === 'create') {
      codeFiled.current.inputs.forEach(input => (input.value = ""))
      codeFiled.current.inputs[0].focus()
      body.passcode = value
      dispatch(loaderShow())
      Api.postData(GET_PASSCODE_TOKEN, body, localStorage.getItem('userToken'))
        .then((res) => {
          localStorage.setItem('passcode_token', res.data.data.passcode_token)
          dispatch(loaderHide())
          history.push('/will-it')
        })
        .catch((err) => {
          dispatch(loaderHide())
          dispatch(showToast({title: 'Enter PIN', text: err.response.data.message, type: 'error'}))
        })
    }
  }
  const forgotPin = () => {
    dispatch(loaderShow())
    Api.postData(FORGOT_PASSCODE, null, localStorage.getItem('userToken'))
      .then((res) => {
        dispatch(loaderHide())
        history.push('/confirm-code', {typePage: 'pin'})
      })
      .catch(err => {
        dispatch(loaderHide())
      })
  }
  return (
    <section className="account__pin">
      <Helmet>
        <title>Account Pin | Missit</title>
      </Helmet>
      <div className="container account__container">
        <div className="account__nav">
        </div>
        <div className="pin__content">
          <div className="pin__image">
            <img src="/assets/icons/lock-pin.svg" alt="lock"/>
          </div>
          <h1 className="pin__title">{titles[location.state?.type] ?? 'Default title'}</h1>
          {location.state?.type === 'create' && (
            <div className="pin__code">
              <PinField ref={codeFiled} inputmode={'number'} length={6} validate={/^[0-9]+$/} format={key => key} onComplete={(value) => stepTwo(value) }/>
            </div>
          )}
          {location.state?.type === 'create' && <div onClick={() => forgotPin()} className="pin__forgot">Forgot PIN?</div>}
        </div>
      </div>
    </section>
  )
}
