import React, {useEffect} from "react";
import { Helmet } from "react-helmet";
import {useDispatch, useSelector} from "react-redux";
import {getSharedItems} from "../../../Store/Actions/Properties/shareProperty";

import './index.scss'
import {useHistory} from "react-router-dom";

export default () => {
    const history = useHistory()
    // STATES
    const dispatch = useDispatch()
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const sharedItem = useSelector((state) => state.propertiesReducer.sharedItem)
    // RENDER
    useEffect(() => {
        dispatch(getSharedItems(params.uid))
    }, [])
    const renderSharedItem = (el) => {
        return (
            <div className="col-3">
                <div onClick={() => {
                    history.push('/shared-property', {id: el.id})
                }} className="shared__item">
                    <div className="shared__item_img">
                        <img src={el.preview_img} alt="item img"/>
                    </div>
                    <div className="shared__content">
                        <span><h5 className="shared__subtitle">
                            {el.name}
                        </h5></span>
                        <div>
                            <p className="shared__date">
                                {el?.bought_date}
                            </p>
                            <p className="shared__price">
                                {`${el.currency[0]}${el.value}`}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className="shared">
            <Helmet>
                <title>Shared-View | Missit</title>
            </Helmet>
            <div className="container shared__container">
                <div className="shared__info">
                    <div className="shared__img">
                        <img src={sharedItem?.user?.photo} alt="shared img"/>
                    </div>
                    <h3 className="shared__name">{sharedItem?.user?.name}</h3>
                </div>
                <h3 className="shared__title">Shared:</h3>
                <div className="row shared__row">
                    {
                        sharedItem?.items?.map((el) => {
                            return renderSharedItem(el)
                        })
                    }
                </div>
            </div>
        </div>
    );
};
