import React, {useEffect, useState} from "react";

import Api from "../../API/api";
import {GET_FAQ} from "../../API/endpoints";
import {showToast} from "../../Store/Actions/Properties/toastActions";
import {useDispatch} from "react-redux";
import {loaderHide, loaderShow} from "../../Store/Actions/Loader/loaderActions";

import './index.scss'
import { Helmet } from "react-helmet";

export default () => {
  const dispatch = useDispatch()
  const [list, setList] = useState([])
  const [showFaq, setShowFaq] = useState(false)

  useEffect(() => {
    getFaq()
  }, [])

  const getFaq = () => {
    dispatch(loaderShow())
    Api.getData(GET_FAQ, localStorage.getItem('userToken'))
      .then(res => {
        dispatch(loaderHide())
        setList(res.data.data)
      })
      .catch(err => {
        dispatch(loaderShow())
        dispatch(showToast({
          title: 'FAQ',
          text: err.response.data.message,
          type: 'error'
        }))
      })
  }

  const renderFaq = () => {
    return list.map(item => (
      <div key={item.id} className='faq__item'>
        <p onClick={() => {setShowFaq(!showFaq)}} className="faq__item-title">{item.title} <span><img src="/assets/icons/faq-arrow.svg" alt=""/></span></p>
        <p className={`faq__item-desc ${showFaq && 'active-desc'}`}>{item.body}</p>
      </div>
    ))
  }
  return (
    <div className='faq container'>
      <Helmet>
        <title>Support | Missit</title>
      </Helmet>
      <h1 className="faq__title">Frequently Asked Questions</h1>
      <div className="faq__content">
        {renderFaq()}
      </div>
      <p className="faq__subtitle">Still have a questions</p>
      <p className="faq__text">Missit encourages input from all of our users. Please send us any feedback and ideas you
        feel may make your application better. Send to: info@missit.com</p>
    </div>
  )
}
