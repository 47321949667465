import { loaderHide, loaderShow } from "../Loader/loaderActions";
import Api from "../../../API/api";
import { WILL_LIST_DATA } from "../../Types/WillItem/willitem";
import { GET_WILLIT } from "../../../API/endpoints";
import { showToast } from "../Properties/toastActions";

export const getWillList = (query, changePIN) => (dispatch) => {
  dispatch(loaderShow());
  Api.getData(
    `${GET_WILLIT}?passcode_token=${localStorage.getItem("passcode_token")}${
      // query?.status !== undefined
      //   ? `&assigned=${!query?.status ? null : query?.status}`
      //   : ""
      query?.status === true
        ? `&assigned=${true}`
        : query?.status === false
        ? `&assigned=${false}`
        : ``
    }${
      query?.property !== null && query?.property !== undefined
        ? `&itemId=${query?.property}`
        : ""
    }${
      query?.assigned !== null && query?.assigned !== undefined
        ? `&inheritor_id=${query?.assigned}`
        : ""
    }${
      query?.search !== null &&
      query?.search !== undefined &&
      query?.search !== ""
        ? `&name=${query?.search}`
        : ""
    }`,
    localStorage.getItem("userToken")
  )
    .then((res) => {
      dispatch(successWillList(res.data.data));
      dispatch(loaderHide());
    })
    .catch((err) => {
      dispatch(successWillList(null));
      changePIN(err.response);
      dispatch(loaderHide());
      if (err?.response?.status !== 403) {
        dispatch(
          showToast({
            title: "Will It",
            text: err?.response.data?.message,
            type: "error",
          })
        );
      }
    });
};

export const successWillList = (payload) => {
  return {
    type: WILL_LIST_DATA,
    payload,
  };
};
