import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import RenderHeader from "./RenderHeader";
import RenderNav from "./RenderNav";
import RenderDocsHeader from "./RenderDocsHeader";
import RenderTabs from "./RenderTabs";

import RenderContent from "./RenderContent";
import "./index.scss";
import { getItemById } from "../../Store/Actions/Properties/getPropertiesById";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { GET_PROPERTIES, ITEM } from "../../API/endpoints";
import Api from "../../API/api";

export default () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const propertiesById = useSelector(
    (state) => state.propertiesReducer.itemInfo
  );
  const showItem = useSelector((state) => state.propertiesReducer.showItem);
  // STATE
  const [itemDropdown, setItemDropdown] = useState(false);
  const [data, setData] = useState([]);
  const [docsData, setDocsData] = useState([]);
  const [initialDocsData, setInitialDocsData] = useState([]);
  const [activeTab, setActiveTab] = useState(true);
  const [typeList, setTypeList] = useState(true);
  // LOAD
  useEffect(() => {
    propertiesById?.photos && setData([...propertiesById?.photos]);
    propertiesById?.documents && setDocsData([...propertiesById?.documents]);
    propertiesById?.documents &&
      setInitialDocsData([...propertiesById?.documents]);
    setActiveTab(location.state?.tab ?? true);
  }, [propertiesById]);
  useEffect(() => {
    return window.addEventListener("mousedown", (e) => {
      if (e.target.closest(".property-item__dropdown") === null) {
        setItemDropdown(false);
      }
    });
  }, []);

  // ACTIONS
  const getSearchValue = (value) => {
    let newData = [...docsData];
    newData = newData.filter((item) =>
      item.name.toLowerCase().includes(value)
    );
    value.length > 0 ? setDocsData(newData) : setDocsData(initialDocsData);
  };

  useEffect(() => {
    if (propertiesById === null && !showItem) {
      dispatch(getItemById(localStorage.getItem("item_by_id")));
    }
  }, []);
  const dropdownShow = () => {
    setItemDropdown(!itemDropdown);
  };
  useEffect(() => {
    setTimeout(() => {
      Api.getData(`${ITEM}/${localStorage.getItem('item_by_id')}/photos`, localStorage.getItem("userToken"))
        .then(response => {
          setData(response.data.data.sort((a,b) => b.id - a.id))
        })
      Api.getData(`${GET_PROPERTIES}/${localStorage.getItem('item_by_id')}/documents`, localStorage.getItem("userToken"))
        .then(response => {
          setDocsData(response.data.data.sort((a,b) => b.id - a.id))
        })
    }, 1000);
  }, [propertiesById])
  return (
    <section className="properties-item">
      <Helmet>
        <title>Item | Missit</title>
      </Helmet>
      <div className="container properties-item__container">
        <RenderNav propertiesById={propertiesById} />
        <RenderHeader
          itemId={location.state?.itemId}
          propertiesById={propertiesById}
          dropdownShow={dropdownShow}
          itemDropdown={itemDropdown}
        />
        <RenderTabs activeTab={activeTab} setActiveTab={setActiveTab} />
        {!activeTab && (
          <RenderDocsHeader
            getSearchValue={getSearchValue}
            typeList={typeList}
            setTypeList={setTypeList}
          />
        )}
        <RenderContent
          itemId={location.state?.itemId}
          activeTab={activeTab}
          data={data}
          docsData={docsData}
          typeList={typeList}
        />
      </div>
    </section>
  );
};
