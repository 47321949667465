import React, {useState, useEffect} from "react";
import {Button, Search} from "../../../UI";

import './index.scss'
import {dropdownOptions, initialData, itemMore} from "./actions";
import {useDispatch, useSelector} from "react-redux";
import {getAllDocuments} from "../../../Store/Actions/Properties/getAllDocuments";
import {
    documentDelete,
    showMoveDocumentModal,
    showUploadFileModal
} from "../../../Store/Actions/Properties/UploadDocument";
import {DefaultModal} from "../../../Components";
import {getProperties} from "../../../Store/Actions/Properties/getProperties";
import {useHistory} from "react-router-dom";
import Select from "../../../UI/Select";
import { Helmet } from "react-helmet";

export default () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const documents = useSelector(state => state.propertiesReducer.documents.data)
    const properties = useSelector(state => state.propertiesReducer.data)
    // USE STATES
    const [filter, setFilter] = useState({label: '', id: ''})
    const [data, setData] = useState([])
    const [initialData, setInitialData] = useState([])
    const [options, setOptions] = useState(dropdownOptions)
    const [searchText, setSearchText] = useState('')
    const [showFilterModal, setShowFilterModal] = useState(false)
    const [filterOptions, setFilterOptions] = useState([])
    const [showFilterSelect, setShowFilterSelect] = useState(false)
    // USE EFFECTS
    useEffect(() => {
        return window.addEventListener('mousedown', (e) => {
            if (e.target.closest('.property-item__dropdown') === null && e.target.closest('.table__content-more') === null) {
                setData((old) => {
                    return old.map(item => {
                        item.more = false
                        return item
                    })
                })
            }
        })
    }, [])
    useEffect(() => {
        dispatch(getAllDocuments())
    }, [])
    const actions = {
        delete: (id) => {
            dispatch(documentDelete(id))
        },
        download: (id) => {
            dispatch(showUploadFileModal(id))
        },
        move: (fileId) => {
            dispatch(showMoveDocumentModal({fileId}))
        }
    }
    useEffect(() => {
        setData(documents)
        setInitialData(documents)
    }, [documents])
    useEffect(() => {
        options.map(item => {
            for (let key in actions) {
                if (item.id === key) {
                    item.action = actions[key]
                }
            }
        })
        setFilterOptions(properties?.properties?.map((el) => {
            return {label: el.name, id: el.id}
        }))
    }, [properties])
    // ACTIONS
    const moreItem = (id) => {
        itemMore(data, setData, id)
    }
    const filterHandler = () => {
        dispatch(getAllDocuments({name: searchText, itemId: filter.id}))
        setShowFilterModal(false)
    }
    const getFilterSelect = (event) => {
        let label = event.label;
        let value = event.id;
        setFilter({label: label, id: value})
    }
    const showModal = () => {
        if (!properties?.properties) {
            dispatch(getProperties())
        }
        setShowFilterModal(true)
    }
    const getSearchValue = (value) => {
        setSearchText(value)
    }
    const search = (value) => {
        dispatch(getAllDocuments({name: searchText, itemId: filter.id}))
    }
    // RENDERS CONTENT
    const renderDropdownOptions = (elem) => {
        return options.map(item => (
            <div onClick={() => item.action(elem.id)} id={item.id} key={item.id}
                 className={`option ${item?.border ? 'border' : ''}`}>
                <span className="icon">
                    <img src={item.icon} alt=""/>
                </span>
                <span className="text">{item.text}</span>
            </div>
        ))
    }
    const renderEmpty = () => {
        return (
            <div className="docs__empty">
                <div className="dashboard__content-empty">
                    <div className="empty__img">
                        <img src="/assets/images/documents.png" alt="empty"/>
                    </div>
                    <div className="empty__title">There are no files yet</div>
                    <p className="empty__description">Upload files that you can download later.</p>
                    <div className="empty__btn">
                        <Button action={() => dispatch(showUploadFileModal())}
                                type="success"><span>Upload file</span></Button>
                    </div>
                </div>
            </div>
        )
    }
    const renderRow = () => {
        return data.map((item, idx) => (
            <div onClick={(e) => {
                if (e.target.closest('.table__content-more') !== null || e.target.closest('.property-item__dropdown') !== null) {
                } else {
                    history.push('/my-document', {fileId: item.id, name: item.name})
                }
            }} key={item.id} className="table__content-row">
                <div className="table__content-name">
                    <div className="img"><img src="/assets/images/folder.png" alt="file"/></div>
                    <div className="text">
                        <span className="key">{item.name}</span>
                        <span className="value">{item.hierarchy}</span>
                    </div>
                </div>
                <div className="table__content-size">
                    {/*<span>162mb</span>*/} -
                </div>
                <div className="table__content-type">
                    {/*<span>Auto</span>*/} -
                </div>
                <div onClick={() => moreItem(item.id)} className="table__content-more">
                    <div className="dots"></div>
                    <div className="dots"></div>
                    <div className="dots"></div>
                </div>
                {item.more && (
                    <div className="property-item__dropdown">
                        {renderDropdownOptions(item)}
                    </div>
                )}
            </div>
        ))
    }
    const renderTableHeader = () => {
        return (
            <div className="docs__table-header">
                <div className="table__header-name">Name</div>
                <div className="table__header-size">Size</div>
                <div className="table__header-type">Type</div>
                <div className="table__header-more"></div>
            </div>
        )
    }
    const renderFilterModal = () => {
        return (
            <DefaultModal close={() => setShowFilterModal(false)} title={"Filter"}>
                <div className="docs__filter-modal">
                    <div className="filter-modal__select">
                        <div className="filter-modal__select-label">
                            By property:
                        </div>
                        {filterOptions.length > 0 && <div onClick={() => setShowFilterSelect(!showFilterSelect)} className={`filter-modal__select-input ${showFilterSelect ? 'filter-modal__select-input_active' : ''}`}>
                            <Select options={filterOptions} placeholder={"By Property"} handleSelect={getFilterSelect} />
                        </div> }
                    </div>
                    <div className="account__hr"></div>
                    <div className="filter-modal__btn">
                        <Button type={"purple"} action={filterHandler}><span>Apply</span></Button>
                    </div>
                </div>
            </DefaultModal>
        )
    }
    return (
        <section className="docs">
            <Helmet>
                <title>My Documents | Missit</title>
            </Helmet>
            {showFilterModal && renderFilterModal()}
            <div className="container docs__container">
                <div className="docs__header">
                    <h1 className="docs__title">Browse My Docs</h1>
                    <div className="docs__upload">
                        <Button action={() => dispatch(showUploadFileModal())} type={"gray"}>
                            <span> <img src="/assets/icons/upload-cloud.svg" alt=""/></span>
                            <span>Upload File</span>
                        </Button>
                    </div>
                </div>
                <div className="docs__content">
                    <div className="docs__row">
                        <div className="docs__content-left">
                            <div className="docs__search">
                                <Search getValue={getSearchValue} type={"left"}/>
                            </div>
                            <div className="docs__search-btn">
                                <Button action={search} type={"purple"}><span>Search</span></Button>
                            </div>
                        </div>
                        <div className="docs__content-right">
                            <div onClick={() => showModal()} className="docs__filter">
                                <span className="icon"><img src="/assets/icons/filter-icon.svg" alt="filter"/></span>
                                <span className="key">Filter:</span>
                                <span className="value">{filter.label}</span>
                                {filter.label && <span className='clear' onClick={() => {
                                    dispatch(getAllDocuments())
                                    setFilter({label: '', id: ''})
                                }}>x</span>}
                            </div>
                        </div>
                    </div>
                    <div className="docs__table">
                        {renderTableHeader()}
                        <div className="docs__table-content">
                            {data.length > 0 ? renderRow() : renderEmpty()}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
