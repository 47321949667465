import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
  useHistory,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from "./Route/PrivatRoute";
import {
  AddMemberModal,
  CreateDocModal,
  WillItItemModal,
  Nav,
  PropertyItemModal,
  PropertyModal,
  UploadDocumentModal,
  MoveDocumentModal,
  ShareDocumentModal,
  DeleteModal,
} from "./Components";
import {
  Confirm,
  Dashboard,
  Forgot,
  Login,
  NewPassword,
  PropertiesItem,
  InfoItem,
  PinForgot,
  PinLock,
  WillIt,
  Support,
  Terms,
  Shared,
  SharedView,
  SharedProperty,
} from "./Sections";

import {
  AccountEmail,
  AccountEmailVerification,
  AccountPersonalInformation,
  AccountPhoneNumber,
  AccountPin,
  AccountSecurity,
  AccountSession,
  AccountSettings,
  AccountShare,
  AccountSubscription,
  EmailVerification,
} from "./Sections/Account";
import { FileInfo, MyDocuments } from "./Sections/MyDocuments";
import { Loader } from "./UI";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "./Store/Actions/Profile/getProfile";
import { login } from "./Store/Actions/Auth/loginActions";
import {
  getAllPropertyType,
  getProperties,
  mainID,
} from "./Store/Actions/Properties/getProperties";
import SharedFile from "./Sections/Shared/SharedFile";
import SharedInfoItem from "./Sections/Shared/SharedInfoItem";
import { showEditable } from "./Store/Actions/Properties/deletePropery";
import Signup from "./Sections/Auth/Signup";
import PhoneNumber from "./Sections/Auth/PhoneNumber";
import About from "./Sections/Auth/About";
import SubscriptionInfo from "./Sections/Account/SubscriptionInfo";

function App() {
  const history = useHistory();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.profileReducer.data);
  const propertyModal = useSelector(
    (state) => state.propertiesReducer.propertyModal
  );
  const deleteModalItems = useSelector(
    (state) => state.propertiesReducer.deleteItems
  );
  const allTypes = useSelector((state) => state.propertiesReducer.allTypes);
  const properties = useSelector(
    (state) => state.propertiesReducer.data?.properties
  );
  const propertyItemModal = useSelector(
    (state) => state.propertiesReducer.addPropertyItem
  );
  const documentUploadModal = useSelector(
    (state) => state.propertiesReducer.documentUpload
  );
  const createDocumentModal = useSelector(
    (state) => state.propertiesReducer.createDocument
  );
  const toastData = useSelector((state) => state.toastReducer);
  const willItemModal = useSelector((state) => state.willItemReducer);
  const moveDocumentModal = useSelector(
    (state) => state.propertiesReducer.moveDocumentModal
  );
  const shareDocumentModal = useSelector(
    (state) => state.propertiesReducer.shareDocumentModal
  );
  const loader = useSelector((state) => state.loaderReducer.show);
  const location = useLocation();
  const [showNav, setShowNav] = useState(true);
  const [idArray, setIdArray] = useState([]);

  useEffect(() => {
    if (location.pathname === "/") {
      history.push("/login");
    }
    if (location.pathname === "/login" || location.pathname === "/signup") {
      setShowNav(false);
    } else {
      setShowNav(true);
    }
    localStorage.getItem("userToken") && !userData && dispatch(getProfile());
    localStorage.getItem("userToken") &&
      !properties &&
      dispatch(getProperties());
    if (location.pathname !== "/properties/item") {
      if (location.pathname !== "/properties/item/info") {
        localStorage.getItem("userToken") && dispatch(mainID(null));
      }
    }
  }, [location.pathname]);
  useEffect(() => {
    if (allTypes.length === 0) {
      localStorage.getItem("userToken") && dispatch(getAllPropertyType());
    }
  }, [location.pathname]);

  useEffect(() => {
    notify();
  }, [toastData]);

  useEffect(() => {
    if (
      propertyModal.show ||
      propertyItemModal.show ||
      documentUploadModal.show ||
      createDocumentModal.show ||
      willItemModal.willItemModal.show ||
      moveDocumentModal.show ||
      shareDocumentModal.show
    ) {
      document.querySelector("html").style.overflow = "hidden";
    } else {
      document.querySelector("html").style.overflow = "visible";
    }
  }, [
    propertyModal.show,
    propertyItemModal.show,
    documentUploadModal.show,
    createDocumentModal.show,
    willItemModal.willItemModal.show,
    moveDocumentModal.show,
    shareDocumentModal.show,
  ]);

  const notify = () => {
    if (toastData.show) {
      return toast[toastData.type](<Msg />, toastData.settings);
    }
  };
  const Msg = ({ closeToast }) => (
    <div>
      <h2>{toastData.title}</h2>
      <p>{toastData.text}</p>
    </div>
  );

  return (
    <div className="App">
      {showNav && <Nav />}
      {loader && <Loader />}
      {propertyModal.show && <PropertyModal />}
      {propertyItemModal.show && <PropertyItemModal />}
      {documentUploadModal.show && <UploadDocumentModal />}
      {createDocumentModal.show && <CreateDocModal />}
      {toastData.show && <ToastContainer />}
      {willItemModal.willItemModal.show && <WillItItemModal />}
      {willItemModal.addMemberModal.show && <AddMemberModal />}
      {moveDocumentModal.show && <MoveDocumentModal />}
      {shareDocumentModal.show && (
        <ShareDocumentModal idArray={idArray} setIdArray={setIdArray} />
      )}
      {deleteModalItems.show && (
        <DeleteModal idArray={idArray} setIdArray={setIdArray} />
      )}
      <Switch>
        <Route exact path={"/login"}>
          <Login />
        </Route>
        <Route exact path={"/signup"}>
          <Signup />
        </Route>
        <Route exact path="/signup/phone-number">
          <PhoneNumber />
        </Route>
        <Route exact path={"/forgot-password"}>
          <Forgot />
        </Route>
        <Route exact path={"/confirm-code"}>
          <Confirm />
        </Route>
        <Route exact path={"/new-password"}>
          <NewPassword />
        </Route>
        <Route exact path="/signup/about">
          <About />
        </Route>
        <PrivateRoute exact path={"/properties"} component={Dashboard} />
        <PrivateRoute
          exact
          path={"/properties/item"}
          component={PropertiesItem}
          idArray={idArray}
          setIdArray={setIdArray}
        />
        <PrivateRoute
          exact
          path={"/properties/item/info"}
          component={InfoItem}
        />
        <PrivateRoute exact path={"/account/email"} component={AccountEmail} />
        <PrivateRoute
          exact
          path={"/account/personal-information"}
          component={AccountPersonalInformation}
        />
        <PrivateRoute
          exact
          path={"/account/phone-number"}
          component={AccountPhoneNumber}
        />
        <PrivateRoute
          exact
          path={"/account/security"}
          component={AccountSecurity}
        />
        <PrivateRoute
          exact
          path={"/account/security/pin"}
          component={AccountPin}
        />
        <PrivateRoute
          exact
          path={"/account/settings"}
          component={AccountSettings}
        />
        <PrivateRoute
          exact
          path={"/account/session"}
          component={AccountSession}
        />
        <PrivateRoute
          exact
          path={"/subscription"}
          component={AccountSubscription}
        />
        <PrivateRoute
          exact
          path="/account/subscription"
          component={SubscriptionInfo}
        />
        <PrivateRoute exact path={"/account/share"} component={AccountShare} />
        <PrivateRoute
          exact
          path={"/account/email/verification"}
          component={AccountEmailVerification}
        />
        <PrivateRoute exact path={"/my-documents"} component={MyDocuments} />
        <Route exact path={"/will-it"}>
          <WillIt />
        </Route>
        <Route exact path={"/account/security/pin-forgot"}>
          <PinForgot />
        </Route>
        <Route exact path={"/account/security/pin-unlock"}>
          <PinLock />
        </Route>
        <Route exact path={"/support"}>
          <Support />
        </Route>
        <Route exact path={"/terms"}>
          <Terms />
        </Route>
        <Route exact path={"/my-document"}>
          <FileInfo />
        </Route>
        <Route path={"/shared"}>
          <Shared />
        </Route>
        <Route path={"/shared-view"}>
          <SharedView />
        </Route>
        <Route path={"/shared-property"}>
          <SharedProperty />
        </Route>
        <Route path={"/shared-file"}>
          <SharedFile />
        </Route>
        <Route path={"/shared-item"}>
          <SharedInfoItem />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
