import {loaderHide, loaderShow} from "../Loader/loaderActions";
import Api from "../../../API/api";
import {GET_PROPERTIES, ITEM} from "../../../API/endpoints";
import {getProperties} from "./getProperties";
import {
    HIDE_PROPERTY_ITEM_MODAL,
    SHOW_PROPERTY_ITEM_MODAL,
} from "../../Types/Properties/properties";
import {getItemById, getPropertiesById} from "./getPropertiesById";
import {showToast} from "./toastActions";
import {hidePropertyModal} from "./propertyModal";

export const addItemToProperty = (id, body) => (dispatch) => {
    dispatch(loaderShow())
    const formData = new FormData()
    formData.append('value', body.value)
    formData.append('name', body.name)
    formData.append('note', body.note)
    formData.append('bought_date', body.bought_date)
    if (body.image.data) {
        formData.append('image', body.image.data)
    }
    Api.postData(`${GET_PROPERTIES}/${id}/item`, formData, localStorage.getItem('userToken'))
        .then(res => {
            dispatch(showToast({title: 'Property item', text: res.data.message, type: 'success'}))
            dispatch(hidePropertyItemModal())
            dispatch(getPropertiesById(id))
        })
        .catch(err => {
            dispatch(showToast({title: 'Property InfoItem', text: err.response.data.message, type: 'error'}))
            dispatch(loaderHide())
        })
}

export const editItem = (id, body, location) => (dispatch) => {
    dispatch(loaderShow())
    const formData = new FormData()
    formData.append('value', body.value)
    formData.append('name', body.name)
    formData.append('note', body.note)
    formData.append('bought_date', body.bought_date)
    if (body.image.data) {
        formData.append('image', body.image.data)
    }
    Api.postData(`${ITEM}/${id}/edit`, formData, localStorage.getItem('userToken'))
        .then(async (res) => {
            if (location == '/properties/item') {
                dispatch(getPropertiesById(localStorage.getItem('properties_by_id')))
            }
            dispatch(showToast({title: 'Item', text: res.data.message, type: 'success'}))
            dispatch(hidePropertyItemModal())
            await dispatch(getItemById(id))
        })
        .catch(err => {
            dispatch(showToast({title: 'Property', text: err.response.data.message, type: 'error'}))
            dispatch(loaderHide())
        })
}


export const hidePropertyItemModal = () =>{
    return {
        type: HIDE_PROPERTY_ITEM_MODAL,
    }
}
export const showPropertyItemModal = (action) => {
    return {
        type: SHOW_PROPERTY_ITEM_MODAL,
        payload: action
    }
}
