import React from "react";
import RenderItem from "./RenderItem";
import RenderEmpty from "./RenderEmpty";
import RenderDocs from "./RenderDocs";

export default ({ itemId, activeTab, data, typeList, docsData, editable, setEditable, getCheckboxId, idArray, setIsShared, setIsDelete }) => {
  return (
    <div className="properties-item__content">
      {activeTab ? (
        data.length > 0 ? (
          <RenderItem idArray={idArray} setIsShared={setIsShared} setIsDelete={setIsDelete} getCheckboxId={getCheckboxId} setEditable={setEditable} editable={editable} itemizedValue={false} data={data} />
        ) : (
          <RenderEmpty itemId={itemId} type={true} />
        )
      ) : docsData.length > 0 ? (
        <RenderDocs itemId={itemId} editable={editable} typeList={typeList} docsData={docsData} />
      ) : (
        <RenderEmpty itemId={itemId} type={false} />
      )}
    </div>
  );
};
