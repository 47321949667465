import React from "react";
import {PropertyDoc} from "../../Components";


export default ({typeList, docsData, dots = true}) => {
    return (
        <div className={`dashboard__list ${typeList ? '' : 'line'}`}>
            {docsData.map((item, idx) => (
                <PropertyDoc item={item} dots={dots} key={idx} link={'/my-document'}/>
            ))}
        </div>
    )
}
