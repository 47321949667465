import {loaderHide, loaderShow} from "../Loader/loaderActions";
import Api from "../../../API/api";
import {GET_PROPERTIES} from "../../../API/endpoints";
import {getProperties} from "./getProperties";
import {hidePropertyModal} from "./propertyModal";
import {showToast} from "./toastActions";

export const addProperty = (body) => (dispatch) => {
    dispatch(loaderShow())
    const formData = new FormData()
    if (body.image.data) {
        formData.append('image', body.image.data)
    }
    formData.append('name', body.name)
    formData.append('value', body.value)
    formData.append('type_id', body.type_id)

    Api.postData(GET_PROPERTIES, formData, localStorage.getItem('userToken'))
        .then(res => {
            dispatch(showToast({title: 'Property', text: res.data.message, type: 'success'}))
            dispatch(hidePropertyModal())
            dispatch(getProperties())
        })
        .catch(err => {
            dispatch(showToast({title: 'Property', text: err.response.data.message, type: 'error'}))
            dispatch(loaderHide())
        })
}

// export const getSuccess = (payload) => {
//     return {
//         type: ALL_PROPERTIES,
//         payload
//     }
// }
