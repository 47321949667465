import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default ({ propertiesById }) => {
  const property = useSelector(
    (state) => state.propertiesReducer.propertiesById
  );
  return (
    <div className="properties-item__nav">
      <Link to={"/properties"}>Properties</Link>
      <span>
        <img src="/assets/icons/arrow_gray_right.svg" alt="" />
      </span>
      <Link to={"/properties/item"}>
        {property?.name ?? localStorage.getItem("property-item")}
      </Link>
      <span>
        <img src="/assets/icons/arrow_gray_right.svg" alt="" />
      </span>
      <span>{propertiesById?.name}</span>
    </div>
  );
};
