import React, { useEffect, useState } from "react";

import "./index.scss";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getFileByDocument } from "../../../Store/Actions/Properties/UploadDocument";
import { Helmet } from "react-helmet";
import Api from "../../../API/api";
import {loaderHide, loaderShow} from "../../../Store/Actions/Loader/loaderActions";

export default () => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [pickedImg, setPickedImg] = useState({
        url: "",
        extension: "png",
    });
    useEffect(() => {
    }, []);
    useEffect(() => {
        dispatch(loaderShow())
        Api.getData(`share/document/${location.state?.uid}`)
            .then(res => {
                dispatch(loaderHide())
                const data = res.data.data.map((item, idx) => {
                    if (idx === 0) {
                        item.picked = true;
                    } else {
                        item.picked = false;
                    }
                    return item;
                });
                setData(data)
            })
            .catch(err => {
                dispatch(loaderHide())
            })
    }, []);
    const pickedFile = (id) => {
        const newArray = data.map((item) => {
            if (item.id === id) {
                item.picked = true;
            } else {
                item.picked = false;
            }
            return item;
        });
        setData(newArray);
    };
    useEffect(() => {
        data.length > 0
            ? data.forEach((item, idx) => {
                if (item.picked) {
                    setPickedImg({
                        url: item.url,
                        extension: item.extension,
                    });
                }
            })
            : setPickedImg("");
    }, [data]);
    const renderItem = () => {
        return data.map((item, idx) => {
            return (
                <div
                    key={item.id}
                    className={`file__list-item ${item.picked ? "active" : ""}`}
                >
                    <div
                        onClick={() => pickedFile(item.id)}
                        className="file__action"
                    ></div>
                    {item.extension === "pdf" ? (
                        <iframe src={item.url}></iframe>
                    ) : item.extension === "doc" || item.extension === "docx" ? (
                        <img className="doc" src="/assets/icons/doc.svg" alt="" />
                    ) : (
                        <img src={item.url} alt="" />
                    )}
                </div>
            );
        });
    };
    return (
        <div className="file">
            <Helmet>
                <title>File Info | Missit</title>
            </Helmet>
            <div className="file__nav">
                <div className="left">
                    <div onClick={() => history.goBack()} className="file__back">
            <span>
              <img src="/assets/icons/arrow.svg" alt="arrow" />
            </span>
                        <span>{location?.state?.name}</span>
                    </div>
                    <div className="file__nav-content"></div>
                </div>
                <div className="right"></div>
            </div>
            <div className="file__content">
                <div className="file__list">{data.length > 0 ? renderItem() : ""}</div>
                <div className="file__item">
                    {
                        data.length > 0 ?
                            pickedImg.extension === "doc" || pickedImg.extension === "docx" ? (
                                <div style={{ display: "block" }}>
                                    <div>Click to download</div>
                                    <a href={pickedImg.url}>
                                        <img className="doc" src="/assets/icons/doc.svg" alt="" />
                                    </a>
                                </div>
                            ) : pickedImg.extension === "png" ||
                            pickedImg.extension === "img" ||
                            pickedImg.extension === "jpg" ||
                            pickedImg.extension === "jepg" ? (
                                <img src={pickedImg.url} alt="" />
                            ) : (
                                <iframe src={pickedImg.url} width="100%" height="100%"></iframe>
                            ) : <h1>There is no documents yet</h1>
                    }
                </div>
            </div>
        </div>
    );
};
