import {
  FILL_MODAL_DETAILS,
  FILL_MODAL_RELATIVES, HIDE_ADDMEMBER_MODAL,
  HIDE_WILLITEM_MODAL, SHOW_ADDMEMBER_MODAL,
  SHOW_WILLITEM_MODAL
} from "../../Types/WillItem/willitem";
import Api from "../../../API/api";
import {GET_RELATIVES} from "../../../API/endpoints";

export const getRelatives = () => (dispatch) => {
    Api.getData(`${GET_RELATIVES}?passcode_token=${localStorage.getItem('passcode_token')}`, localStorage.getItem('userToken'))
      .then(res => {
          let array = []
          for (let key in res.data) {
              array = [...array, {
                label: `${res.data[key].name} ${res.data[key].relationship}`,
                value: res.data[key].id,
              }]
          }
          dispatch(fillItemRelatives(array))
      })
      .catch(err => {
      })
}

export const getItemDetails = (payload) => {
    return {
        type: FILL_MODAL_DETAILS,
        payload
    }
}

export const showWillItemModal = (payload) => {
    return {
        type: SHOW_WILLITEM_MODAL,
        payload
    }
}

export const hideWillItemModal = (payload) => {
    return {
        type: HIDE_WILLITEM_MODAL,
        payload
    }
}
export const showAddMemberModal = (payload) => {
  return {
    type: SHOW_ADDMEMBER_MODAL,
    payload
  }
}
export const hideAddMemberModal = (payload) => {
  return {
    type: HIDE_ADDMEMBER_MODAL,
    payload
  }
}
export const fillItemRelatives = (payload) => {
    return {
        type: FILL_MODAL_RELATIVES,
        payload
    }
}
