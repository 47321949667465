export const baseURL = "https://missit.com";
// export const baseURL = "https://missit.axeldemos.com";

export const LOGIN = "auth/login";
export const RESET_PASSWORD = "auth/reset-password";
export const FORGOT_VERIFY = "auth/verify";
export const FORGOT_SET_PASSWORD = "auth/set-password";

// PROFILE
export const GET_PROFILE = "profile";
export const CHANGE_PROFILE_PASSWORD = "profile/change-password";
export const CHANGE_PROFILE_PIN = "profile/set-passcode";
export const CHANGE_PROFILE_EMAIL = "profile/email";
export const INVITE_AGENTS = "profile/agents/recent";
export const SEND_INVITE_AGENTS = "profile/agents/send-invite";
export const CHANGE_PROFILE_AVATAR = "profile/image";
export const CHANGE_PROFILE_NAME = "profile/name";
export const CHANGE_PROFILE_ADDRESS = "profile/address";
export const CHANGE__PROFILE_BIRTH = "profile/date-of-birth";
export const SET_PIN = "profile/passcode/forgot/set";
export const FORGOT_PASSCODE = "profile/passcode/forgot";
export const FORGOT_PASSCODE_CONFIRM = "profile/passcode/forgot/confirm";

// Properties
export const GET_PROPERTIES = "property";
export const PROPERTY_DOCUMENT = "document";
export const PROPERTY_SHARE = "share";

//Will-It
export const GET_WILLIT = "will-it";
export const GET_PROPERTY = "filter/property";
export const GET_ITEM_STATUS = "filter/item-status";
export const GET_RELATIVES = "will-it/relatives";
export const GET_ASSIGN = "will-it/relatives";
export const CREATE_RELATIVE = "will-it/relatives";
export const GET_PASSCODE_TOKEN = "profile/passcode";

export const GET_ALL_ITEMS = "filter/items";
export const ITEM = "item";

//FAQ
export const GET_FAQ = "app/faq";
