import React, {useEffect, useState} from "react";
import {Button, Input} from "../../../UI";

import './index.scss'
import {DefaultModal, PropertyItem} from "../../../Components";
import {useDispatch, useSelector} from "react-redux";
import {getProperties, getSuccess} from "../../../Store/Actions/Properties/getProperties";
import {showPropertyModal} from "../../../Store/Actions/Properties/propertyModal";
import {login} from "../../../Store/Actions/Auth/loginActions";
import { Helmet } from 'react-helmet';
import {getPropertiesById, resetProperties} from "../../../Store/Actions/Properties/getPropertiesById";


export default () => {
    const dispatch = useDispatch()
    const propertiesData = useSelector(state => state.propertiesReducer.data)
    const propertiesById = useSelector(state => state.propertiesReducer.propertiesById)
    // STATE
    const [data, setData] = useState([])
    const [statusModal, setStatusModal] = useState(false)

    // LOAD
    useEffect(() => {
        propertiesData.properties && setData({...propertiesData})
    }, [propertiesData])
    useEffect(() => {
        !propertiesData.properties && dispatch(getProperties())
    }, [])
    // RENDER
    const renderHeader = () => {
        return (
            <header className="dashboard__header">
                <h1 className="dashboard__title">Properties</h1>
                <div className="dashboard__header-line">
                    <div className="left">
                        <div className="item">
                            <div className="item__icon">
                                <img src="/assets/icons/dollar.svg" alt=""/>
                            </div>
                            <div className="item__text">
                                <div className="item__key">
                                    Total Value:
                                </div>
                                <div className="item__value">
                                    {data?.total_value}
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="item__icon">
                                <img src="/assets/icons/home.svg" alt="home"/>
                            </div>
                            <div className="item__text">
                                <div className="item__key">
                                    Total Quantity:
                                </div>
                                <div className="item__value">
                                    {data?.total_quantity}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <div className="dashboard__header-add">
                            <Button type={"gray"} action={showModal}>
                                <span>
                                    <img src="/assets/icons/cube.svg" alt=""/>
                                </span>
                                <span>Add property</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
    const renderEmptyContent = () => {
        return (
            <div className="dashboard__content-empty">
                <div className="empty__img">
                    <img src="/assets/images/dashboard-empty.svg" alt="empty"/>
                </div>
                <div className="empty__title">There is no property yet</div>
                <p className="empty__description">Create new properties and add details about your items.</p>
                <div className="empty__btn">
                    <Button action={showModal} type="success"><span>Add new property</span></Button>
                </div>
            </div>
        )
    }
    const renderItem = () => {
        return (
            <div className="dashboard__list">
                {data.properties.map(item => (
                    <PropertyItem isMain={true} showEditModal={showModal} key={item.id} item={item} itemId={item.id}
                                  link={'/properties/item'}/>
                ))}
            </div>
        )
    }
    const renderContent = () => {
        return (
            <div className="dashboard__content">
                {data?.properties?.length > 0 ? renderItem() : renderEmptyContent()}
            </div>
        )
    }
    // ACTIONS
    const showModal = () => {
        dispatch(showPropertyModal())
    }
    const hideModal = () => {
        setStatusModal(false)
    }

    return (
        <section className="dashboard">
            <Helmet>
                <title>Properties | Missit</title>
            </Helmet>
            <div className="container dashboard__container">
                {renderHeader()}
                {renderContent()}
            </div>
        </section>
    )
}
