import React, {useState} from "react";
import {BackButton, Button} from "../../../UI";

import './index.scss'
import {useHistory, useLocation} from "react-router-dom";
import ReactCodeInput from "react-verification-code-input";
import {confirmCodeData} from "../../../Global/initialData";
import Api from "../../../API/api";
import {CHANGE_PROFILE_EMAIL} from "../../../API/endpoints";
import {loaderHide, loaderShow} from "../../../Store/Actions/Loader/loaderActions";
import {useDispatch} from "react-redux";
import {getProfile} from "../../../Store/Actions/Profile/getProfile";
import {showToast} from "../../../Store/Actions/Properties/toastActions";
import { Helmet } from "react-helmet";

const confirmCode = '123456'
export default () => {
    const location = useLocation()
    const history = useHistory()
    const dispatch = useDispatch()
    const [code, setCode] = useState({...confirmCodeData})

    const codeHandler = (value) => {
        setCode((old => ({...old, value})))
    }
    const submitHandler = () => {
        const params = {
            code: null,
        }
        if (code.value === confirmCode || code.value.length === 6) {
            setCode(old => ({...old, errorVisible: false}))
            params.code = code.value
        } else {
            setCode(old => ({...old, errorVisible: true}))
        }
        if (params.code) {
            dispatch(loaderShow())
            Api.postData(CHANGE_PROFILE_EMAIL, params, localStorage.getItem('userToken'))
              .then((res) => {
                  dispatch(showToast({title: 'Email', text: res.data.message, type: 'success'}))
                  dispatch(getProfile())
                  history.push('/account/settings')
              })
              .catch((err) => {
                  dispatch(showToast({title: 'Email', text: err.response.data.message, type: 'error'}))
                  dispatch(loaderHide())
              })
        }
    }
    return (
        <section className="confirm">
            <Helmet>
                <title>Email verification | Missit</title>
            </Helmet>
            <div className="confirm__container">
                <BackButton/>
                <h1 className="confirm__title">Email verification</h1>
                <p className="confirm__subtitle">We've sent a verification code to: <span>{location?.state?.email}</span>
                </p>
                <div className="confirm__code">
                    <ReactCodeInput className={'confirm__code-field'} onChange={codeHandler} fieldWidth={48}
                                    fieldHeight={48} autoFocus={false}/>
                    {code.errorVisible && <div className="confirm__code-error">{code.error}</div>}
                </div>
                <div className="confirm__try">
                    Didn’t receive a code? <span>Try again</span>
                </div>
                <div className="confirm__btn">
                    <Button disable={code.value.length === 6 ? false : true} type={"purple"}
                            action={submitHandler}><span>Confirm</span></Button>
                </div>
            </div>
        </section>
    )
}
