import {LOGIN_SUCCESS} from "../Types/Auth/login";

const initialState = {
    authToken: null,
}

export default function  authReducer (state = initialState, action) {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                authToken: action.payload
            }
        default:
            return state;
    }
}
