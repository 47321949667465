import { loaderHide, loaderShow } from "../Loader/loaderActions";
import Api from "../../../API/api";
import { GET_PROPERTIES, ITEM } from "../../../API/endpoints";
import {
  GET_ITEM_SUCCESS,
  GET_PROPERTIES_BY_ID,
  RESET_ITEM,
  RESET_PROPERTIES,
} from "../../Types/Properties/properties";

export const getPropertiesById = (id) => (dispatch) => {
  dispatch(loaderShow());
  Api.getData(
    `${GET_PROPERTIES}/${id}/items`,
    localStorage.getItem("userToken")
  )
    .then((res) => {
      dispatch(getSuccess(res.data.data));
      localStorage.setItem("properties_by_id", id);
      dispatch(loaderHide());
    })
    .catch((err) => {
      dispatch(loaderHide());
    });
};

export const getItemById = (id) => (dispatch) => {
  dispatch(loaderShow());
  Api.getData(`${ITEM}/${id}/`, localStorage.getItem("userToken"))
    .then((res) => {
      dispatch(getSuccessItem(res.data.data));
      localStorage.setItem("item_by_id", id);
      dispatch(loaderHide());
    })
    .catch((err) => {
      dispatch(loaderHide());
    });
};

export const getSuccess = (payload) => {
  return {
    type: GET_PROPERTIES_BY_ID,
    payload,
  };
};

export const getSuccessItem = (payload) => {
  return {
    type: GET_ITEM_SUCCESS,
    payload,
  };
};

export const resetProperties = () => {
  return {
    type: RESET_PROPERTIES,
  };
};

export const resetItem = () => {
  return {
    type: RESET_ITEM,
  };
};
