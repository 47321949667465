import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import { BackButton, Button, Input } from "../../../UI";

import { emailData } from "../../../Global/initialData";
import { validationEmail } from "../../../Global/validation";
import "./index.scss";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../../Store/Actions/Auth/forgotActions";

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // STATE
  const [email, setEmail] = useState({ ...emailData });

  // ACTIONS
  const inputHandler = (value, id) => {
    setEmail((old) => {
      return { ...old, value };
    });
  };

  const submitHandler = () => {
    const params = {
      email: null,
    };
    if (validationEmail(email.value)) {
      setEmail((old) => {
        return {
          ...old,
          errorVisible: false,
        };
      });
      params.email = email.value;
    } else {
      setEmail((old) => {
        return {
          ...old,
          errorVisible: true,
        };
      });
      params.email = null;
    }
    const redirect = () => {
      history.push("/confirm-code", {
        email: params.email,
        typePage: location.state?.typePage,
      });
    };
    if (params.email) {
      dispatch(forgotPassword({ email: params.email }, redirect));
    }
  };
  return (
    <section className="forgot">
      <Helmet>
        <title>Forgot Password | Missit</title>
      </Helmet>
      <div className={`forgot__container ${location.state?.type ?? "account"}`}>
        <BackButton />
        {location.state?.type !== "account" && (
          <div className="forgot__logo">
            <img src="/assets/big-logo.svg" alt="logo" />
          </div>
        )}
        <h1 className="forgot__title">Forgot password?</h1>
        <div className="forgot__form">
          <div className="forgot__input">
            <Input
              value={email.value}
              label={email.label}
              id={email.id}
              type={email.data}
              placeholder={email.placeholder}
              error={email.error}
              showError={email.errorVisible}
              action={inputHandler}
            />
          </div>
          <div className="forgot__descr">
            We'll send an email with a 6 digit confirmation code
          </div>
          <div className="forgot__btn">
            <Button type={"purple"} action={submitHandler}>
              <span>Send verification code</span>
            </Button>
          </div>
          {!location.state?.type && (
            <div className="forgot__link">
              <span>Don’t have an account?</span>
              <Link to={"/login"}>Sign in</Link>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
