import React, {useEffect} from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Api from "../../../API/api";
import { loginSuccess } from "../../../Store/Actions/Auth/loginActions";
import { showToast } from "../../../Store/Actions/Properties/toastActions";
import { BackButton, Button, Input } from "../../../UI";
import "./index.scss";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const [data, setData] = useState({
    photo: {
      preview_image: "",
      data_image: "",
    },
    name: {
      value: "",
      error: "Enter name",
      showError: false,
    },
    address: {
      value: "",
      error: "Enter correct address",
      showError: false,
    },
    city: {
      value: "",
      error: "Enter correct city",
      showError: false,
    },
    state: {
      value: "",
      error: "Enter correct state",
      showError: false,
    },
    zip: {
      value: "",
      error: "Enter correct zip",
      showError: false,
    },
  });

  useEffect(() => {
    if (data?.zip?.value?.length == 5) {
      localValidate()
    }
  }, [data?.zip?.value])

  const inputHandler = (value, id) => {
    setData((old) => ({
      ...old,
      [id]: {
        ...old[id],
        value: value,
      },
    }));
  };

  const objectToArray = (object) => Object.entries(object);

  const localValidate = () => {
    const r = objectToArray(data);
    r.forEach(([key, value]) => {
      if (value?.value?.length > 0) {
        setData((old) => ({
          ...old,
          [key]: { ...old[key], showError: false },
        }));
      } else {
        setData((old) => ({
          ...old,
          [key]: { ...old[key], showError: true },
        }));
      }

      if (key === "zip") {
        let zipValue = value.value.replace(/\D/g, "")
        if (zipValue.length !== 5) {
          setData((old) => ({
            ...old,
            zip: {
              ...old.zip,
              showError: true,
            },
          }));
        } else {
          setData((old) => ({
            ...old,
            zip: {
              ...old.zip,
              showError: false,
            },
          }));
        }
      }
    });
  };
  const submitHandler = (e) => {
    e.preventDefault();
    if (!data.name.showError && !data.address.showError && !data.city.showError && !data.state.showError && !data.zip.showError) {
      // const body = {
      //   verify_token: location?.state?.verify_token,
      //   profile_photo:
      //     data?.photo?.data_image === "" || !data?.photo?.data_image
      //       ? null
      //       : data?.photo?.data_image,
      //   name: data?.name?.value,
      //   address: data?.address?.value,
      //   city: data?.city?.value,
      //   state: data?.state?.value,
      //   zip: data?.zip?.value,
      // };
      const body = new FormData();
      body.append("verify_token", location?.state?.verify_token);
      body.append(
        "profile_photo",
        data?.photo?.data_image === "" || !data?.photo?.data_image
          ? null
          : data?.photo?.data_image
      );
      body.append("name", data?.name?.value);
      body.append("address", data?.address?.value);
      body.append("city", data?.city?.value);
      body.append("state", data?.state?.value);
      body.append("zip", data?.zip?.value);

      Api.postData("auth/register", body)
        .then((res) => {
          localStorage.setItem("userToken", res.data.data.token);
          dispatch(loginSuccess(res.data.data.token));
          history.push("/subscription");
        })
        .catch((err) => {
          dispatch(
            showToast({
              title: "Register",
              text: err.response.data.message,
              type: "error",
            })
          );
        });
    }
  };

  return (
    <section className="auth__about">
      <div className="about__container">
        <BackButton />
        <h1 className="about__title">About you</h1>

        <form action="#" className="about__form" onSubmit={submitHandler}>
          <div className="about__photo">
            <div className="about__photo-user">
              <input
                type="file"
                accept="image/*"
                id="photo"
                onChange={(e) => {
                  console.log(e.target.files, URL)
                  setData((old) => ({
                    ...old,
                    photo: {
                      preview_image: e.target.files.length !== 0 ? URL?.createObjectURL(e?.target?.files[0]) : "/assets/icons/default-user-2.svg",
                      data_image: e?.target?.files[0],
                    },
                  }));
                }}
              />
              <img
                src={
                  data?.photo?.preview_image === ""
                    ? "/assets/icons/default-user-2.svg"
                    : data?.photo?.preview_image
                }
                alt="user"
              />
            </div>
            <label htmlFor="photo" className="about__photo-btn">
              Upload Profile Photo
            </label>
          </div>
          <div className="about__field">
            <Input
              label="Name"
              placeholder="Enter client's name"
              id="name"
              type="text"
              error={data.name.error}
              showError={data?.name.showError}
              value={data?.name?.value}
              action={inputHandler}
              maxLength={46}
            >
              <img src="/assets/icons/user-cion.svg" alt="user" />
            </Input>
          </div>
          <h2 className="about__billing">Billing info</h2>
          <div className="about__field">
            <Input
              label="Address"
              placeholder="Enter Address"
              id="address"
              error={data.address.error}
              showError={data?.address.showError}
              value={data?.address?.value}
              action={inputHandler}
              maxLength={95}
            >
              <img src="/assets/icons/map-pin.svg" alt="map" />
            </Input>
          </div>

          <div className="about__fields cl-3">
            <div className="about__field">
              <Input
                label="City"
                placeholder="Enter City"
                id="city"
                error={data?.city?.error}
                value={data?.city?.value}
                showError={data?.city?.showError}
                action={inputHandler}
                maxLength={28}
              />
            </div>
            <div className="about__field">
              <Input
                label="State"
                placeholder="Enter State"
                id="state"
                error={data?.state?.error}
                value={data?.state?.value}
                showError={data?.state?.showError}
                action={inputHandler}
                maxLength={55}
              />
            </div>
            <div className="about__field">
              <Input
                label="Zip"
                placeholder="Enter Zip"
                id="zip"
                error={data?.zip?.error}
                showError={data?.zip?.showError}
                value={data?.zip?.value}
                action={inputHandler}
                maxLength={10}
              />
            </div>
          </div>

          <div className="about__btn">
            <Button type="purple">Continue</Button>
          </div>
        </form>
      </div>
    </section>
  );
};
