import { combineReducers } from "redux";
import authReducer from "./Reducers/AuthReducer";
import forgotReducer from "./Reducers/ForgotReducer";
import loaderReducer from "./Reducers/Loader";
import profileReducer from "./Reducers/ProfileReducer";
import propertiesReducer from "./Reducers/PropertiesReducer";
import agentsReducer from "./Reducers/AgentsReducer";
import willItemReducer from "./Reducers/WillItemReducer";
import willListReducer from './Reducers/WillListReducer'
import toastReducer from './Reducers/ToastReducer'
const rootReducer = combineReducers({
    authReducer,
    forgotReducer,
    loaderReducer,
    profileReducer,
    propertiesReducer,
    willItemReducer,
    willListReducer,
    agentsReducer,
    toastReducer
});

export default rootReducer;
