import React from "react";

import './index.scss'
import 'font-awesome/css/font-awesome.min.css';

export default ({getValue}) => {
    return (
        <div className={`search`}>
            <span className={'icon'}>
                <img src="/assets/icons/search.svg" alt="search"/>
            </span>
            <input onInput={(e) => getValue && getValue(e.target.value)} type="text" placeholder={`Search`}/>
        </div>
    )
}
