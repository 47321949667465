import React from "react";
import { useHistory } from "react-router-dom";

import "./index.scss";
import Api from "../../API/api";
import {useDispatch} from "react-redux";
import {loaderHide, loaderShow} from "../../Store/Actions/Loader/loaderActions";
import {getItemById, getPropertiesById} from "../../Store/Actions/Properties/getPropertiesById";

export default ({ item, dots = true, link, itemId }) => {
  const history = useHistory();
  const dispatch = useDispatch()
  const deleteDocument = () => {
    dispatch(loaderShow())
    Api.delete(`document/${item.id}`, {}, localStorage.getItem('userToken'))
        .then((res) => {
          dispatch(getPropertiesById(itemId))
          if (history.location.pathname === '/properties/item/info') {
            dispatch(getItemById(localStorage.getItem('item_by_id')))
          }
        })
        .catch(() => {
          dispatch(loaderHide())
        })
  }
  return (
    <div
      className={`property-doc`}
    >
      <div className="property-doc__preview">
        <span onClick={() => {
          history.push(link, { fileId: item.id, name: item.name });
        }} className="property-doc__icon">
          <img src={item.icon} alt="pdf" />
        </span>
        <div onClick={() => {
          history.push(link, { fileId: item.id, name: item.name });
        }} className="property-doc__bg"></div>
        {dots && (
          <div onClick={deleteDocument} className="property-doc__dots">
            <img src="/assets/icons/delete.svg" alt=""/>
          </div>
        )}
      </div>
      <div onClick={() => {
        history.push(link, { fileId: item.id, name: item.name });
      }} className="property-doc__footer">
        <div className="property-doc__title">{item.name}</div>
        <div className="property-doc__size">{item.size}</div>
      </div>
    </div>
  );
};
