import React, {useState} from "react";
import {PropertyItem} from "../../../Components";

export default ({data, itemizedValue, editable, setEditable, getCheckboxId, idArray, setIsShared, setIsDelete}) => {
    return (
        <div className="dashboard__list">
            {data.length > 0 && data.map((item, idx) => (
                <PropertyItem idArray={idArray} setIsShared={setIsShared} setIsDelete={setIsDelete} getCheckboxId={getCheckboxId} item={item} setEditable={setEditable} itemizedValue={itemizedValue} properties={false} itemId={item.id} key={idx} editable={editable} link={editable ? null : '/properties/item/info'}/>
            ))}
        </div>
    )
}
