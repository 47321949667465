import React, { useEffect, useState } from "react";
import { BackButton, Button, Input } from "../../../UI";
import { emailData } from "../../../Global/initialData";

import { DefaultModal } from "../../../Components";
import "./index.scss";
import { useHistory } from "react-router-dom";
import { validationEmail } from "../../../Global/validation";
import { useSelector, useDispatch } from "react-redux";
import Api from "../../../API/api";
import { CHANGE_PROFILE_EMAIL } from "../../../API/endpoints";
import {
  loaderHide,
  loaderShow,
} from "../../../Store/Actions/Loader/loaderActions";
import { showToast } from "../../../Store/Actions/Properties/toastActions";
import { Helmet } from "react-helmet";
import ChoosVerify from "../../../Components/ChoosVerify";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showNewEmail, setShowNewEmail] = useState(false);
  const [chooseVerify, setChooseVerify] = useState(false);
  const [changeMethod, setChangeMethod] = useState(null);
  const profileData = useSelector((state) => state.profileReducer.data);
  const [email, setEmail] = useState({
    ...emailData,
    value: "johnsmith@gmail.com",
  });
  const [modalData, setModalData] = useState({
    currentEmail: {
      ...emailData,
      id: "currentEmail",
      value: profileData?.contact_email,
      label: "Current email",
    },
    newEmail: {
      ...emailData,
      id: "newEmail",
      placeholder: "New email",
      label: "New email",
    },
    confirmEmail: {
      ...emailData,
      id: "confirmEmail",
      placeholder: "Confirm New Email",
      label: "Confirm New Email",
      error: "Confirmation mail and new mail do not match",
    },
  });

  useEffect(() => {
    setModalData((old) => ({
      ...old,
      currentEmail: {
        ...emailData,
        id: "currentEmail",
        value: profileData?.contact_email,
        label: "Current email",
      },
    }));
  }, [profileData]);

  const inputHandler = (value, id) => {
    setEmail({ ...email, value });
  };
  const changeEmailLabel = () => {
    return <div onClick={showModal}>Change email</div>;
  };
  const saveHandler = () => {};
  const cancelHandler = () => {};
  const checkInputs = (email, key) => {
    if (validationEmail(email)) {
      setModalData((old) => {
        return {
          ...old,
          [key]: { ...old[key], errorVisible: false },
        };
      });
      return true;
    } else {
      setModalData((old) => {
        return {
          ...old,
          [key]: { ...old[key], errorVisible: true },
        };
      });
      return false;
    }
  };
  const newEmailHandler = () => {
    const params = {
      currentEmail: null,
      newEmail: null,
      confirmEmail: null,
    };
    checkInputs(modalData.currentEmail.value, "currentEmail")
      ? (params.currentEmail = modalData.currentEmail.value)
      : (params.currentEmail = null);
    checkInputs(modalData.newEmail.value, "newEmail")
      ? (params.newEmail = modalData.newEmail.value)
      : (params.newEmail = null);
    checkInputs(modalData.confirmEmail.value, "confirmEmail")
      ? (params.confirmEmail = modalData.confirmEmail.value)
      : (params.confirmEmail = null);
    if (modalData.newEmail.value !== modalData.confirmEmail.value) {
      setModalData((old) => {
        return {
          ...old,
          confirmEmail: { ...old.confirmEmail, errorVisible: true },
        };
      });
      params.confirmEmail = null;
    } else {
      setModalData((old) => {
        return {
          ...old,
          confirmEmail: { ...old.confirmEmail, errorVisible: false },
        };
      });
      params.confirmEmail = modalData.confirmEmail.value;
    }
    if (params.currentEmail && params.newEmail && params.confirmEmail) {
      dispatch(loaderShow());
      const body = {
        email: params.newEmail,
        confirm_method: changeMethod,
      };
      Api.putData(`profile/email`, body, localStorage.getItem("userToken"))
        .then((res) => {
          history.push("/confirm-code", {
            typePage: "confirmNew",
            confirm_method: 'emailNew',
          });
          dispatch(
            showToast({
              title: "Email",
              text: res.data.message,
              type: "success",
            })
          );
          dispatch(loaderHide());
        })
        .catch((err) => {
          dispatch(loaderHide());
          dispatch(
            showToast({
              title: "Email",
              text: err.response.data.message,
              type: "error",
            })
          );
        });
    }
  };
  const modalInputHandler = (value, id) => {
    for (let key in modalData) {
      if (key === id) {
        setModalData((old) => {
          return {
            ...old,
            [key]: {
              ...old[key],
              value: value,
            },
          };
        });
      }
    }
  };

  const closeModal = () => {
    setShowNewEmail(false);
  };
  const showModal = () => {
    setChooseVerify(true);
  };
  const renderModal = () => (
    <DefaultModal close={closeModal} title={"New email"}>
      <div className="email__modal">
        <div className="email__modal-input">
          <Input
            value={modalData.currentEmail.value}
            label={modalData.currentEmail.label}
            id={modalData.currentEmail.id}
            type={modalData.currentEmail.type}
            placeholder={modalData.currentEmail.placeholder}
            error={modalData.currentEmail.error}
            showError={modalData.currentEmail.errorVisible}
            action={modalInputHandler}
            disabled={true}
          />
        </div>
        <div className="email__modal-input">
          <Input
            value={modalData.newEmail.value}
            label={modalData.newEmail.label}
            id={modalData.newEmail.id}
            type={modalData.newEmail.type}
            placeholder={modalData.newEmail.placeholder}
            error={modalData.newEmail.error}
            showError={modalData.newEmail.errorVisible}
            action={modalInputHandler}
          />
        </div>
        <div className="email__modal-input">
          <Input
            value={modalData.confirmEmail.value}
            label={modalData.confirmEmail.label}
            id={modalData.confirmEmail.id}
            type={modalData.confirmEmail.type}
            placeholder={modalData.confirmEmail.placeholder}
            error={modalData.confirmEmail.error}
            showError={modalData.confirmEmail.errorVisible}
            action={modalInputHandler}
          />
        </div>
        <div className="email__modal-btn">
          <Button type={"success"} action={newEmailHandler}>
            <span>Save</span>
          </Button>
        </div>
      </div>
    </DefaultModal>
  );

  return (
    <section className="account__email">
      <Helmet>
        <title> Account Email | Missit</title>
      </Helmet>
      {chooseVerify && (
        <ChoosVerify
          setChooseVerify={setChooseVerify}
          showModal={setShowNewEmail}
          setChangeMethod={setChangeMethod}
        />
      )}
      {showNewEmail && renderModal()}
      <div className="container account__container">
        <div className="account__nav">
          <BackButton type={"block"} link={"/account/settings"} />
          <div className="account__nav-info">
            <h1 className="account__nav-title">Email </h1>
            <h3 className="account__nav-description">Current Email</h3>
          </div>
        </div>
        <div onClick={showModal} className="account__email-link">
          <span>
            {profileData?.contact_email}
            <img src="/assets/icons/profile-arrow.svg" alt="" />
          </span>
        </div>
      </div>
    </section>
  );
};
