import React from "react";

import './index.scss'
export default ({title, close, children}) => {
    return (
        <div className="modal">
            <div className="modal__container middle">
                <div className="modal__header">
                    <div className="modal__title">{title ? title : 'Title'}</div>
                    <div onClick={() => close && close()} className="modal__close">
                        <img src="/assets/icons/modal-close.svg" alt="close"/>
                    </div>
                </div>
                <div className="modal__content">
                    {children}
                </div>
            </div>
            <div onClick={() => close && close()} className="modal__bg"></div>
        </div>
    )
}
