import React, {useState} from "react";
import {BackButton, Button, Input} from "../../../UI";
import {confirmPasswordData, passwordData} from "../../../Global/initialData";

import './index.scss'
import {validationConfirmPassword, validationPassword} from "../../../Global/validation";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {forgotSetPassword} from "../../../Store/Actions/Auth/forgotActions";
import { Helmet } from "react-helmet";

export default () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const password_token = useSelector(state => state.forgotReducer.password_token)
    // STATE
    const [inputsData, setInputsData] = useState({
        password: {...passwordData, label: 'New password'},
        repeatPassword: {...confirmPasswordData}
    })

    // ACTIONS
    const inputHandler = (value, id) => {
        for (let key in inputsData) {
            if (key === id) {
                setInputsData(old => {
                    return {
                        ...old,
                        [key]: {
                            ...old[key],
                            value: value
                        }
                    }
                })
            }
        }
    }
    const inputRender = () => {
        let result = Object.keys(inputsData).map((key) => [key, inputsData[key]]);
        return result.map(item => (
            <div key={item[1].id} className="new-password__input">
                <Input value={item[1].value} label={item[1].label}
                       id={item[1].id} type={item[1].type}
                       placeholder={item[1].placeholder}
                       error={item[1].error} showError={item[1].errorVisible}
                       action={inputHandler}/>
            </div>
        ))
    }
    const submitHandler = () => {
        const params = {
            password: null,
            repeatPassword: null,
        }
        if (validationPassword(inputsData.password.value)) {
            setInputsData(old => {
                return {...old, password: {...old.password, errorVisible: false}}
            })
            params.password = inputsData.password.value
        } else {
            setInputsData(old => {
                return {...old, password: {...old.password, errorVisible: true}}
            })
            params.password = null
        }
        if (validationConfirmPassword(inputsData.password.value, inputsData.repeatPassword.value)) {
            setInputsData(old => {
                return {...old, repeatPassword: {...old.repeatPassword, errorVisible: false}}
            })
            params.repeatPassword = inputsData.repeatPassword.value
        } else {
            setInputsData(old => {
                return {...old, repeatPassword: {...old.repeatPassword, errorVisible: true}}
            })
            params.repeatPassword = null
        }
        const redirect = () => {
            history.push('/login')
        }
        if (params.password && params.repeatPassword) {
            const body = {
                password: params.password,
                password_token
            }
            dispatch(forgotSetPassword(body, redirect))
        }
    }
    return (
        <section className="new-password">
            <Helmet>
                <title>Set password | Missit</title>
            </Helmet>
            <div className="new-password__container">
                <BackButton/>
                <div className="new-password__title">Set a new password</div>
                <div className="new-password__form">
                    {inputRender()}
                </div>
                <div className="new-password__btn">
                    <Button type={"success"} action={submitHandler}><span>Set</span></Button>
                </div>
            </div>
        </section>
    )
}
