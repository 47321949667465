import {
  ALL_PROPERTIES,
  GET_PROPERTIES_BY_ID,
  HIDE_PROPERTY_ITEM_MODAL,
  HIDE_PROPERTY_MODAL,
  SHOW_PROPERTY_MODAL,
  SHOW_PROPERTY_ITEM_MODAL,
  SHOW_CREATE_DOCUMENT_MODAL,
  HIDE_CREATE_DOCUMENT_MODAL,
  SHOW_DOCUMENT_UPLOAD_MODAL,
  HIDE_DOCUMENT_UPLOAD_MODAL,
  GET_ALL_DOCUMENTS,
  SHOW_MOVE_DOCUMENT_MODAL,
  HIDE_MOVE_DOCUMENT_MODAL,
  GET_ALL_ITEMS_SUCCESS,
  GET_FILE_BY_DOCUMENT_SUCCESS,
  GET_ITEM_SUCCESS,
  GET_ALL_TYPE_PROPERTY,
  MAIN_ID,
  RESET_PROPERTIES,
  SHOW_PROPERTY,
  RESET_ITEM,
  SHOW_ITEM,
  SHOW_SHARE_DOCUMENT_MODAL,
  HIDE_SHARE_DOCUMENT_MODAL,
  SHARE_PROPERTY,
  GET_SHARED_ITEMS,
  GET_SHARED_HISTORY,
  DELETE_ITEMS,
  DELETE_ITEMS_SHOW,
  DELETE_ITEMS_HIDE,
  EDITABLE_CHANGE,
} from "../Types/Properties/properties";

const initialState = {
  data: [],
  allItems: [],
  propertiesById: null,
  showProperty: false,
  showItem: false,
  allTypes: [],
  mainId: null,
  propertyModal: {
    id: null,
    show: false,
    data: {
      image: {
        preview: null,
        data: null,
      },
      value: "",
      name: "",
      type_id: null,
    },
  },
  addPropertyItem: {
    id: null,
    show: false,
    data: {
      image: {
        preview: null,
        data: null,
      },
      value: "",
      name: "",
      bought_date: "",
      note: "",
    },
  },
  documentUpload: {
    id: null,
    show: false,
    data: {
      id: "",
      name: "",
      files: [],
    },
  },
  createDocument: {
    id: null,
    show: false,
  },
  deleteItems: {
    show: false,
    data: null,
    itemId: null,
    editable: false,
  },
  documents: {
    data: [],
  },
  moveDocumentModal: {
    show: false,
    fileId: null,
  },
  fileByDocument: {
    data: [],
  },
  shareDocumentModal: {
    show: false,
    id: null,
  },
  itemInfo: null,
  shareToken: null,
  sharedItem: null,
  sharedHistory: null
};

export default function propertiesReducer(state = initialState, action) {
  switch (action.type) {
    case ALL_PROPERTIES:
      return {
        ...state,
        data: action.payload,
      };
    case MAIN_ID:
      return {
        ...state,
        mainId: action.payload,
      };
    case GET_ALL_ITEMS_SUCCESS:
      return {
        ...state,
        allItems: action.payload,
      };
    case GET_PROPERTIES_BY_ID:
      return {
        ...state,
        propertiesById: action.payload,
      };
    case SHOW_PROPERTY:
      return {
        ...state,
        showProperty: true,
      };
    case SHOW_ITEM:
      return {
        ...state,
        showItem: true,
      };
    case SHOW_PROPERTY_MODAL:
      return {
        ...state,
        propertyModal: {
          data: {
            id: action?.payload?.item?.id
              ? action?.payload?.item?.id
              : action?.payload?.itemId
              ? action?.payload?.itemId
              : null,
            image: {
              preview: action?.payload?.item?.preview_img
                ? action?.payload?.item?.preview_img
                : action?.payload?.item?.property_preview ?? null,
              data: null,
            },
            value: action?.payload?.item?.value ?? "",
            name: action?.payload?.item?.name ?? "",
            type_id: action?.payload?.item?.type_id,
          },
          show: true,
        },
      };
    case HIDE_PROPERTY_MODAL:
      return {
        ...state,
        propertyModal: {
          ...state.propertyModal,
          show: false,
          propertyModal: {
            id: null,
            show: false,
            data: {
              image: {
                preview: null,
                data: null,
              },
              value: "",
              name: "",
              type_id: null,
            },
          },
        },
      };
    case SHOW_PROPERTY_ITEM_MODAL:
      return {
        ...state,
        addPropertyItem: {
          ...state.addPropertyItem,
          show: true,
          id: action?.payload?.id ?? null,
          data: {
            image: {
              preview: action?.payload?.item?.preview_img ?? null,
              data: null,
            },
            name: action.payload?.item?.name ?? "",
            value: action.payload?.item?.value ?? "",
            bought_date: action.payload?.item?.bought_date ?? "",
            note: action.payload?.item?.note ?? "",
          },
        },
      };
    case HIDE_PROPERTY_ITEM_MODAL:
      return {
        ...state,
        addPropertyItem: {
          ...state.addPropertyItem,
          show: false,
          id: null,
          data: {
            image: {
              preview: null,
              data: null,
            },
            name: null,
            value: null,
            bought_date: null,
            note: null,
          },
        },
      };
    case SHOW_DOCUMENT_UPLOAD_MODAL:
      return {
        ...state,
        documentUpload: {
          ...state.documentUpload,
          show: true,
          data: {
            ...state.documentUpload.data,
            id: action.payload?.id || null,
          },
        },
      };
    case HIDE_DOCUMENT_UPLOAD_MODAL:
      return {
        ...state,
        documentUpload: {
          ...state.documentUpload,
          show: false,
          id: null,
        },
      };
    case SHOW_CREATE_DOCUMENT_MODAL:
      return {
        ...state,
        createDocument: {
          ...state.createDocument,
          show: true,
          id: action.payload?.id ?? null,
        },
      };
    case HIDE_CREATE_DOCUMENT_MODAL:
      return {
        ...state,
        createDocument: {
          ...state.createDocument,
          show: false,
          id: null,
        },
      };
    case GET_ALL_DOCUMENTS:
      return {
        ...state,
        documents: {
          ...state.documents,
          data: action.payload,
        },
      };
    case SHOW_MOVE_DOCUMENT_MODAL:
      return {
        ...state,
        moveDocumentModal: {
          ...state.moveDocumentModal,
          show: true,
          fileId: action.payload.fileId,
        },
      };
    case HIDE_MOVE_DOCUMENT_MODAL:
      return {
        ...state,
        moveDocumentModal: {
          ...state.moveDocumentModal,
          show: false,
          fileId: null,
        },
      };
    case GET_FILE_BY_DOCUMENT_SUCCESS:
      return {
        ...state,
        fileByDocument: {
          ...state.fileByDocument,
          data: action.payload.data,
        },
      };
    case GET_ITEM_SUCCESS:
      return {
        ...state,
        itemInfo: action.payload,
      };
    case GET_ALL_TYPE_PROPERTY:
      return {
        ...state,
        allTypes: action.payload,
      };
    case RESET_PROPERTIES:
      return {
        ...state,
        propertiesById: null,
      };
    case RESET_ITEM:
      return {
        ...state,
        itemInfo: null,
      };
    case SHOW_SHARE_DOCUMENT_MODAL:
      return {
        ...state,
        shareDocumentModal: {
          ...state.shareDocumentModal,
          show: true,
          id: action.payload
        },
      };
    case HIDE_SHARE_DOCUMENT_MODAL:
      return {
        ...state,
        shareDocumentModal: {
          ...state.shareDocumentModal,
          show: false,
        },
      };
    case SHARE_PROPERTY:
      return {
        ...state,
        shareToken: action.payload
      };
    case GET_SHARED_ITEMS:
      return {
        ...state,
        sharedItem: action.payload
      }
    case GET_SHARED_HISTORY:
      return {
        ...state,
        sharedHistory: action.payload
      }
    case DELETE_ITEMS_SHOW:
      return  {
        ...state,
        deleteItems: {
          show: true,
          data: action.payload,
          itemId: action.itemId,
          editable: action.editable
        }
      }
    case DELETE_ITEMS_HIDE:
      return  {
        ...state,
        deleteItems: {
          ...state.deleteItems,
          show: false,
        }
      }
    case EDITABLE_CHANGE:
      return {
        ...state,
        deleteItems: {
          ...state.deleteItems,
          editable: action.editable
        }
      }
    default:
      return state;

  }
}
