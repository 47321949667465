import React, { useEffect, useState } from "react";
import { DefaultModal } from "../index";
import { Button, Input } from "../../UI";
import FileUploadProgress from "react-fileupload-progress";
import { useDispatch, useSelector } from "react-redux";

import "./index.scss";
import {
  addFileToDocument,
  hideUploadFileModal,
  showCreateDocumentModal,
} from "../../Store/Actions/Properties/UploadDocument";
import { getAllDocuments } from "../../Store/Actions/Properties/getAllDocuments";
import Select from "../../UI/Select";
import Api from "../../API/api";
import { useLocation } from "react-router-dom";

export default () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const uploadModal = useSelector(
    (state) => state.propertiesReducer.documentUpload
  );
  const mainID = useSelector((state) => state.propertiesReducer?.mainId);
  const documents = useSelector(
    (state) => state.propertiesReducer.documents.data
  );
  const [selected, setSelected] = useState(null);
  const [files, setFiles] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [idOptions, setIdOptions] = useState([]);
  useEffect(() => {
    if (mainID) {
      Api.getData(
        `property/${mainID}/documents`,
        localStorage.getItem("userToken")
      )
        .then((res) => {
          setIdOptions(res.data.data);
        })
        .catch((err) => {});
      return;
    } else if (location.pathname === "/properties/item") {
      Api.getData(
        `property/${localStorage.getItem("properties_by_id")}/documents`,
        localStorage.getItem("userToken")
      )
        .then((res) => {
          setIdOptions(res.data.data);
        })
        .catch((err) => {});
      return;
    } else if (location.pathname === "/properties/item/info") {
      Api.getData(
        `property/${localStorage.getItem("item_by_id")}/documents`,
        localStorage.getItem("userToken")
      )
        .then((res) => {
          setIdOptions(res.data.data);
        })
        .catch((err) => {});
      return;
    }
    dispatch(getAllDocuments());
  }, []);
  const renderList = () => {
    return files.map((item, idx) => (
      <div key={idx} className="upload-modal__item">
        <div className="upload-modal__item-img">
          <img src="/assets/icons/doc.svg" alt="" />
        </div>
        <div className="upload-modal__item-name">{item.name}</div>
        <div className="upload-modal__item-delete">
          <img
            onClick={() => {
              setFiles((old) => old.filter((elem, elemX) => idx !== elemX));
            }}
            src="/assets/icons/modal-close.svg"
            alt=""
          />
        </div>
      </div>
    ));
  };
  useEffect(() => {
    let array;
    if (location.pathname === '/my-documents') {
      array = documents.map((item) => {
        return { value: item.id, label: item.name };
      });
      setSelectOptions(array);
      return;
    }
    if (mainID) {
      array = idOptions.map((item) => {
        return { value: item.id, label: item.name };
      });
      setSelectOptions(array);
      return;
    } else if (localStorage.getItem("properties_by_id")) {
      array = idOptions.map((item) => {
        return { value: item.id, label: item.name };
      });
      setSelectOptions(array);
      return;
    } else if (localStorage.getItem("item_by_id")) {
      array = idOptions.map((item) => {
        return { value: item.id, label: item.name };
      });
      setSelectOptions(array);
      return;
    }

  }, [idOptions, documents]);
  const fileHandler = (e) => {
    setFiles([...e.target.files]);
  };
  const addFile = () => {
    const body = {
      id: selected ?? uploadModal.data.id,
      files,
    };
    dispatch(addFileToDocument(body));
  };
  const handleSelect = (value) => {
    setSelected(value);
  };
  const handleCreateDocument = () => {
    dispatch(showCreateDocumentModal());
    dispatch(hideUploadFileModal());
  };
  return (
    <DefaultModal
      title={"Upload File"}
      close={() => dispatch(hideUploadFileModal())}
    >
      <div className="upload-modal">
        <div className="upload-modal__container">
          {!uploadModal.data.id && (
            <>
              <div className="upload-modal__select">
                <Select
                    options={selectOptions}
                    placeholder={"Select document"}
                    handleSelect={(e) => handleSelect(e.value)}
                    handleButton={handleCreateDocument}
                    showButton={true}
                    buttonText={"Create document +"}
                    typeButton={"willBtn"}
                />
              </div>
              <div className="account__hr"></div>
            </>
          )}
          <div className="upload-modal__image">
            <>
              <div className="img-placeholder">
                <img src="/assets/icons/image-placeholder.png" alt="" />
              </div>
              <div className="cover">Drag & Drop</div>
              <div className="browse">Browse</div>
            </>
            <div className="input">
              <input onChange={(e) => fileHandler(e)} type="file" />
            </div>
          </div>
          <div className="upload-modal__list">{renderList()}</div>
          <div className="upload-modal__btn">
            <Button type={"success"} action={addFile}>
              <span>Save</span>
            </Button>
          </div>
        </div>
      </div>
    </DefaultModal>
  );
};
