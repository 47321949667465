const validationEmail = (value) => {
  if (value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
    return true;
  } else {
    return false;
  }
};

const validationPassword = (value) => {
  if (
    value.match(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/
    )
  ) {
    return true;
  } else {
    return false;
  }
};
const validationText = (value) => {
  if (value.length >= 3) {
    return true;
  } else {
    return false;
  }
};

const validationConfirmPassword = (password, confirmPassword) => {
  if (
    password === confirmPassword &&
    confirmPassword !== null &&
    confirmPassword.length !== 0
  ) {
    return true;
  } else {
    return false;
  }
};
export {
  validationEmail,
  validationPassword,
  validationConfirmPassword,
  validationText,
};
