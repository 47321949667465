import {
  validationConfirmPassword,
  validationEmail,
  validationPassword,
} from "../../../Global/validation";

export const validate = (setInputsData, inputsData) => {
  const params = {
    email: null,
    password: null,
    password_confirmation: null,
  };
  if (validationEmail(inputsData.email.value)) {
    setInputsData((old) => {
      return { ...old, email: { ...old.email, errorVisible: false } };
    });
    params.email = inputsData.email.value;
  } else {
    setInputsData((old) => {
      return { ...old, email: { ...old.email, errorVisible: true } };
    });
  }
  if (validationPassword(inputsData.password.value)) {
    setInputsData((old) => {
      return { ...old, password: { ...old.password, errorVisible: false } };
    });
    params.password = inputsData.password.value;
  } else {
    setInputsData((old) => {
      return { ...old, password: { ...old.password, errorVisible: true } };
    });
  }
  if (
    validationConfirmPassword(
      inputsData.password.value,
      inputsData.repeatPassword.value
    )
  ) {
    setInputsData((old) => {
      return {
        ...old,
        repeatPassword: { ...old.repeatPassword, errorVisible: false },
      };
    });
    params.password_confirmation = inputsData.repeatPassword.value;
  } else {
    setInputsData((old) => {
      return {
        ...old,
        repeatPassword: { ...old.repeatPassword, errorVisible: true },
      };
    });
  }

  return params;
};
