import React, {useEffect, useState} from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Api from "../../../API/api";
import {
  confirmPasswordData,
  emailData,
  passwordData,
} from "../../../Global/initialData";
import {
  validationConfirmPassword,
  validationEmail,
  validationPassword,
} from "../../../Global/validation";
import {
  loaderHide,
  loaderShow,
} from "../../../Store/Actions/Loader/loaderActions";
import { showToast } from "../../../Store/Actions/Properties/toastActions";
import { Button, Input } from "../../../UI";

import { validate } from "./validate";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [inputsData, setInputsData] = useState({
    email: { ...emailData },
    password: { ...passwordData },
    repeatPassword: { ...confirmPasswordData },
  });

  const inputHandler = (value, id) => {
    for (let key in inputsData) {
      if (key === id) {
        setInputsData((old) => {
          return {
            ...old,
            [key]: {
              ...old[key],
              value: value,
            },
          };
        });
      }
    }
  };
  const inputRender = () => {
    let result = Object.keys(inputsData).map((key) => [key, inputsData[key]]);
    return result.map((item) => (
      <div key={item[1].id} className="login__input">
        <Input
          value={item[1].value}
          label={item[1].label}
          id={item[1].id}
          type={item[1].type}
          placeholder={item[1].placeholder}
          error={item[1].error}
          showError={item[1].errorVisible}
          action={inputHandler}
        >
          {item[1].isPassword && (
            <img
              onClick={() => {
                for (let key in inputsData) {
                  if (key === item[1].id) {
                    setInputsData((old) => ({
                      ...old,
                      [key]: {
                        ...old[key],
                        type: old[key].type == "password" ? "text" : "password",
                      },
                    }));
                  }
                }
              }}
              src={item[1].type === "password" ? './assets/icons/eye.svg' : './assets/icons/eye-off.svg'}
              alt="svg"
            />
          )}
        </Input>
      </div>
    ));
  };

  const submitHandler = (e) => {
    e.preventDefault();
    // e?.preventDefault();
    const data = validate(setInputsData, inputsData);
    if (data.email && data.password && data.password_confirmation) {
      dispatch(loaderShow());
      Api.postData("auth/sign-up", data)
        .then((res) => {
          dispatch(loaderHide());
          history.push("/confirm-code", {
            email: inputsData?.email?.value,
            typePage: "signup",
            token: res.data?.data?.verify_token,
            data: data,
          });
        })
        .catch((err) => {
          dispatch(loaderHide());
          dispatch(
            showToast({
              title: "Sign up",
              text: err.response.data.message,
              type: "error",
            })
          );
        });
    }
  };

  console.log(inputsData, '123')

  return (
    <section className="login">
      <Helmet>
        <title>Signup | Missit</title>
      </Helmet>
      <div className="left">
        <div className="login__img">
          <img src="/assets/images/login-bg.png" alt="login background" />
        </div>
      </div>
      <div className="right">
        <form onSubmit={submitHandler} className="login__content">
          <div className="login__logo">
            <img src="/assets/big-logo.svg" alt="logo" />
          </div>
          <h1 className="login__title">Sign Up</h1>
          <div className="login__form">{inputRender()}</div>
          {/* <div className="login__link">
            <Link to={"/forgot-password"}>Forgot password?</Link>
          </div> */}
          <div className="login__btn">
            <Button type={"purple"}>
              <span>Sign Up</span>
            </Button>
          </div>
        </form>
        <div className="login__hr"></div>
        <div className="login__ac">
          Already have an account?{"  "}
          <span className="login__link">
            <Link to="/login">Login</Link>
          </span>
        </div>
      </div>
    </section>
  );
};
