import React from "react";
import {Link, NavLink} from "react-router-dom";

export const AuthList = () => {
    return (
        <ul className="nav__list">
            <li>
                <NavLink activeClassName='active' to={'/properties'}>Properties</NavLink>
            </li>
            <li>
                <NavLink activeClassName='active' to={'/my-documents'}>My Docs</NavLink>
            </li>
            <li>
                <NavLink activeClassName='active' to={"/will-it"}>Will it</NavLink>
            </li>
            <li>
                <NavLink activeClassName='active' to={"/shared"}>Shared</NavLink>
            </li>
        </ul>
    )
}

