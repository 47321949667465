import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import { Button } from "../../UI";
import RenderDropdownOptions from "./RenderDropdownOptions";
import { useDispatch } from "react-redux";
import { showPropertyItemModal } from "../../Store/Actions/Properties/addItemToProperty";
import {
  showCreateDocumentModal,
  showUploadFileModal,
} from "../../Store/Actions/Properties/UploadDocument";
import { DefaultModal } from "../../Components";
import Api from "../../API/api";
import { ITEM } from "../../API/endpoints";
import { getItemById } from "../../Store/Actions/Properties/getPropertiesById";
import { loaderShow } from "../../Store/Actions/Loader/loaderActions";

export default ({ itemId, propertiesById, dropdownShow, itemDropdown }) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [data, setData] = useState({
    image: {
      preview: null,
      data: null,
    },
  });
  const closeModal = () => {
    setModal(false);
  };
  const showModal = () => {
    setModal(true);
  };
  const addImage = (pathPreview, dataPreview) => {
    setData((old) => {
      return {
        ...old,
        image: {
          preview: pathPreview,
          data: dataPreview,
        },
      };
    });
  };

  const submitHandler = () => {
    dispatch(loaderShow());
    const formData = new FormData();
    formData.append("photos[]", data.image.data);
    Api.postData(
      `${ITEM}/${itemId}/upload-photos`,
      formData,
      localStorage.getItem("userToken")
    )
      .then((res) => {
        setModal(false);
        dispatch(getItemById(itemId));
        setData({
          image: {
            preview: null,
            data: null,
          },
        });
      })
      .catch((err) => {});
  };
  const uploadImageModal = () => {
    return (
      <DefaultModal title="Upload Images" close={closeModal}>
        <div className="property-add__modal">
          <div className="property-modal__image">
            {data.image.preview ? (
              <div className="property-modal__preview">
                <img src={data.image.preview} alt="" />
              </div>
            ) : (
              <>
                <div className="img-placeholder">
                  <img src="/assets/icons/image-placeholder.png" alt="" />
                </div>
                <div className="cover">Cover image</div>
                <div className="browse">Browse Files</div>
              </>
            )}
            <div className="input">
              <input
                onChange={(e) =>
                  addImage(
                    URL.createObjectURL(e.target.files[0]),
                    e.target.files[0]
                  )
                }
                type="file"
              />
            </div>
          </div>
          <div className="account__hr"></div>
          <Button action={submitHandler} type="success">
            <span>Save</span>
          </Button>
        </div>
      </DefaultModal>
    );
  };
  return (
    <header className="properties-item__header">
      {modal && uploadImageModal()}
      <div className="left">
        <div className="properties-item__photo">
          <img
            src={`${
              propertiesById?.property_preview ??
              propertiesById?.preview_img ??
              `/assets/images/default_preview.png`
            }`}
            alt=""
          />
        </div>
      </div>
      <div className="center">
        <div className="header__top">
          <h1 className="properties-item__title">
            {propertiesById?.name ?? ""}
          </h1>
          <div onClick={() => dropdownShow()} className="properties-item__dots">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            {itemDropdown && (
              <div className="property-item__dropdown">
                <RenderDropdownOptions
                  propertiesById={propertiesById}
                  itemId={itemId}
                />
              </div>
            )}
          </div>
        </div>
        <div className="header__center">
          {/* <div className="properties-item__by">
                        <span>Created by:</span>
                        <span>John Smith</span>
                    </div>
                    <div className="properties-item__shared">
                        <span className="text">Shared 1 people</span>
                        <span className="icon">
                                <img data-event="click focus" data-tip data-for="showShared" src="/assets/icons/default-user.svg" alt=""/>
                                <ReactTooltip  globalEventOff="click"  id="showShared">
                                    <div className="list">
                                        <div className="item">
                                            <div className="user">
                                                <img src="/assets/icons/default-user.svg" alt=""/>
                                            </div>
                                            <div className="info">
                                                <div className="email">Kevin_Peterson@gmail.com</div>
                                                <div className="action">Can view</div>
                                            </div>
                                        </div>
                                    </div>
                                </ReactTooltip>
                            </span>
                    </div> */}
        </div>
        <div className="header__bottom">
          <div className="properties-item__info">
            <div className="icon">
              <img src="/assets/icons/gray-coolicon.png" alt="" />
            </div>
            <div className="text">
              <div className="key">Value:</div>
              <div className="value">
                {`$ ${propertiesById?.value
                  .split("")
                  .reverse()
                  .join("")
                  .replace(/(\d{3})(?!$)/g, "$1,")
                  .split("")
                  .reverse()
                  .join("")}` ?? "$ 0"}
              </div>
            </div>
          </div>
          <div className="properties-item__info">
            <div className="icon">
              <img src="/assets/icons/gray-inbox.svg" alt="" />
            </div>
            <div className="text">
              <div className="key">Bought date:</div>
              <div className="value">
                {propertiesById?.bought_date ?? "22.22.2222"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="right">
        <div className="properties-item__btn">
          <Button action={() => dispatch(showUploadFileModal())} type={"gray"}>
            <span>
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_4451_2246)">
                  <path
                    d="M13.8346 13.8333L10.5013 10.5L7.16797 13.8333"
                    stroke="#7D8793"
                    strokeWidth="1.625"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.5 10.5V18"
                    stroke="#7D8793"
                    strokeWidth="1.625"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17.4909 15.8269C18.3037 15.3838 18.9458 14.6826 19.3158 13.834C19.6858 12.9855 19.7627 12.0379 19.5344 11.1408C19.3061 10.2436 18.7855 9.44812 18.0548 8.87973C17.3241 8.31134 16.425 8.00247 15.4992 8.00186H14.4492C14.197 7.02623 13.7269 6.12047 13.0742 5.35269C12.4215 4.5849 11.6033 3.97507 10.681 3.56903C9.7587 3.163 8.75636 2.97132 7.74933 3.00843C6.7423 3.04553 5.75679 3.31044 4.86688 3.78325C3.97697 4.25605 3.20583 4.92444 2.61142 5.73818C2.01701 6.55191 1.61481 7.48981 1.43505 8.48135C1.25529 9.4729 1.30266 10.4923 1.57358 11.4629C1.8445 12.4335 2.33194 13.3301 2.99923 14.0852"
                    stroke="#7D8793"
                    strokeWidth="1.625"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.8346 13.8333L10.5013 10.5L7.16797 13.8333"
                    stroke="#7D8793"
                    strokeWidth="1.625"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4451_2246">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(0.5 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </span>
            <span>Upload file</span>
          </Button>
        </div>
        <div className="properties-item__btn">
          <Button type={"gray"} action={() => showModal()}>
            <span>
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_4451_2240)">
                  <path
                    d="M11.243 1.70927L17.9097 5.0426C18.1874 5.18059 18.421 5.39329 18.5844 5.65681C18.7479 5.92033 18.8345 6.2242 18.8347 6.53427V14.4759C18.8345 14.786 18.7479 15.0899 18.5844 15.3534C18.421 15.6169 18.1874 15.8296 17.9097 15.9676L11.243 19.3009C11.0115 19.4168 10.7561 19.4771 10.4972 19.4771C10.2383 19.4771 9.98289 19.4168 9.75134 19.3009L3.08468 15.9676C2.80728 15.8278 2.57447 15.6133 2.4125 15.3483C2.25053 15.0832 2.16585 14.7782 2.16801 14.4676V6.53427C2.16817 6.2242 2.25483 5.92033 2.41824 5.65681C2.58165 5.39329 2.81533 5.18059 3.09301 5.0426L9.75968 1.70927C9.99014 1.59475 10.244 1.53516 10.5013 1.53516C10.7587 1.53516 11.0125 1.59475 11.243 1.70927V1.70927Z"
                    stroke="#7D8793"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4451_2240">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(0.5 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </span>
            <span>Upload Images</span>
          </Button>
        </div>
      </div>
    </header>
  );
};
