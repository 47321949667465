import {loaderHide, loaderShow} from "../Loader/loaderActions";
import Api from "../../../API/api";
import {PROPERTY_SHARE,} from "../../../API/endpoints";
import {
    GET_SHARED_HISTORY,
    GET_SHARED_ITEMS,
    SHARE_PROPERTY,
} from "../../Types/Properties/properties";
import {showToast} from "./toastActions";
import {hideShareDocumentModal} from "./UploadDocument";
import {showEditable} from "./deletePropery";
const localhost = 'https://client.missit.com'
export const shareProperty = (ids, email, setIdArray) => (dispatch) => {
    dispatch(loaderShow())
    const formData = new FormData()
    for (let i = 0; i < ids.length; i++) {
        formData.append(`items[${i}]`, ids[i])
    }
    Api.postData(`${PROPERTY_SHARE}`, formData, localStorage.getItem('userToken'))
        .then(res => {
            dispatch(loaderHide())
            dispatch(getSharedToken(res.data.data.uid))
            email && dispatch(shareWithEmail(email, `${localhost}/shared-view?uid=${res.data.data.uid}`, res.data.data.uid, setIdArray))
            if (!email) {
              navigator.clipboard.writeText(`${localhost}/shared-view?uid=${res.data.data.uid}`);
              dispatch(showToast({title: 'Copy Link', text: 'Link was successfully copied to clipboard!', type: 'success'}))
              dispatch(hideShareDocumentModal())
              dispatch(showEditable(false))
              setIdArray([])
            }
            // dispatch(hideShareDocumentModal())
            // dispatch(showToast({title: "Share", text: "Successfully shared an item!", type: "success"}))
        })
        .catch(err => {
            dispatch(showToast({title: 'Share Error', text: err.response.data.message, type: 'error'}))
            dispatch(loaderHide())
        })
}

export const getSharedItems = (uid) => (dispatch) => {
    dispatch(loaderShow())
    Api.getData(`${PROPERTY_SHARE}/${uid}`)
        .then((res) => {
            dispatch(setSharedItems(res.data))
            dispatch(loaderHide())
        })
        .catch(err => {
            dispatch(showToast({title: 'Get Shared Items Error', text: err.response.data.message, type: 'error'}))
            dispatch(loaderHide())
        })
}

export const shareWithEmail = (email, link, uid, setIdArray) => (dispatch) => {
    dispatch(loaderShow())
    const formData = new FormData()
    formData.append('email', email)
    formData.append('link', link)
    Api.postData(`${PROPERTY_SHARE}/${uid}/email`, formData, localStorage.getItem('userToken'))
        .then(res => {
            dispatch(loaderHide())
            dispatch(hideShareDocumentModal())
            dispatch(showEditable(false))
            dispatch(showToast({title: "Share", text: "Share link was successfully sent!", type: "success"}))
            setIdArray([])
        })
        .catch(err => {
            dispatch(showToast({title: 'Share Error', text: err.response.data.message, type: 'error'}))
            dispatch(loaderHide())
        })
}

export const getSharedHistory = (page, perPage) => (dispatch) =>  {
    dispatch(loaderShow())
    Api.getData(`${PROPERTY_SHARE}/history?page=${page}&per_page=${perPage}`, localStorage.getItem("userToken"))
        .then( (res) => {
            const data = res.data.data.map(item => {
                item.items.forEach(elem => {
                    elem.status = false
                })
                return item
            })
            dispatch(setSharedHistory(res.data.data))
            dispatch(loaderHide())
        })
        .catch(err => {
            // dispatch(showToast({title: 'Get Shared History Error', text: err.response.data.message, type: 'error'}))
            dispatch(loaderHide())
        })
}

export const getSharedToken = (payload) => {
    return {
        type: SHARE_PROPERTY,
        payload
    }
}

export const setSharedItems = (payload) => {
    return {
        type: GET_SHARED_ITEMS,
        payload
    }
}

export const setSharedHistory = (payload) => {
    return {
        type: GET_SHARED_HISTORY,
        payload
    }
}
