import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import Api from "../../../API/api";
import { Stripe } from "../../../Components/Stripe";
import { BackButton } from "../../../UI";
import moment from "moment";

import "./index.scss";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const [priceStripeId, setPriceStripeId] = useState(null);
  const [type, setType] = useState(true);
  const [data, setData] = useState(null);
  const [allData, setAllData] = useState(null);
  const [currentData, setCurrentData] = useState(null);
  const [stripeType, setStripeType] = useState(null);

  const getSub = () => {
    Api.getData(
      "profile/subscriptions/packages",
      localStorage.getItem("userToken")
    )
      .then((res) => {
        setAllData(res.data.data);
        setData(res.data.data[0]);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getSub();
  }, []);

  const settedType = (index) => {
    let s = allData[index];
    let a = s.packages.filter((item) => item.type === stripeType)[0];
    setCurrentData(a);
  };

  return (
    <section className="account__share session">
      <Helmet>
        <title>Account Share | Missit</title>
      </Helmet>
      <div className="container account__container">
        <div className="account__nav">
          <BackButton type={"text"} />
        </div>

        <div className="subscription__content">
          <div className="left">
            <h1 className="subscription__subtitle">
              Select a plan that's right for you
            </h1>
            <div className="subscription__types">
              {allData?.length > 0 && (
                <>
                  <div
                    className={`subscription__type ${type ? "active" : ""}`}
                    key={allData[0]?.stripe_id}
                    onClick={() => {
                      setType(true);
                      setData(allData[0]);
                      settedType(0);
                    }}
                  >
                    {allData[0]?.title}
                  </div>
                  <div
                    className={`subscription__type ${!type ? "active" : ""}`}
                    key={allData[1]?.stripe_id}
                    onClick={() => {
                      setType(false);
                      setData(allData[1]);
                      settedType(1);
                    }}
                  >
                    {allData[1]?.title}
                  </div>
                </>
              )}
            </div>
            <div className="subscription__border-block">
              <div className="subscription__options">
                {data?.packages?.map((item, index) => (
                  <div className="subscription__option" key={index}>
                    <input type="radio" id={item?.price_stripe_id} name="sub" />
                    <label
                      htmlFor={item?.price_stripe_id}
                      onClick={() => {
                        setCurrentData(item);
                        setPriceStripeId(item?.price_stripe_id);
                        setStripeType(item?.type);
                      }}
                    >
                      <div className="subscription__option-left">
                        <div className="radio"></div>
                        <h4>{item?.type}</h4>
                      </div>
                      <div className="subscription__option-right">
                        <div className="price">
                          ${item?.monthly_price}/month
                        </div>
                        <div className="total-price">
                          ${item?.monthly_price * 12} billed annualy
                        </div>
                      </div>
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div className="subscription__summary">
              <h2 className="subscription__subtitle">Order summary</h2>
              <div className="subscription__border-block">
                <div className="subscription__total">
                  <span>Total fee:</span>
                  <span>$ {currentData?.total_price ?? 0} USD</span>
                </div>
                {
                  currentData !== null &&
                  <div className="subscription__descr">
                    We will bill you every {currentData?.type == "Monthly" ? 'month' : `year on ${moment().format("MMMM Do")}`}, unless you cancel.
                  </div>
                }
              </div>
            </div>
            <h2 className="subscription__subtitle">Payment info</h2>
            <div className="subscription__border-block">
              <Stripe
                price_stripe_id={priceStripeId}
                totalFee={currentData?.total_price}
              />
            </div>
          </div>
          <div className="right">
            <h2 className="subscription__subtitle">Order summary</h2>
            <div className="subscription__border-block">
              <div className="subscription__total">
                <span>Total fee:</span>
                <span>$ {currentData?.total_price ?? 0} USD</span>
              </div>
              {
                currentData !== null &&
                <div className="subscription__descr">
                  We will bill you every {currentData?.type == "Monthly" ? 'month' : `year on ${moment().format("MMMM Do")}`}, unless you cancel.
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
