import React, { useState, useEffect } from "react";

import { Button } from "../../UI";
import { NotAuthList } from "./notAuthList";
import { AuthList } from "./authList";
import { RenderProfile } from "./renderProfile";

import "./index.scss";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../Store/Actions/Auth/loginActions";
import { showToast } from "../../Store/Actions/Properties/toastActions";

export default () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [burgerShow, setBurgerShow] = useState(false);
  const authListener = useSelector((state) => state.authReducer.authToken);
  const [auth, setAuth] = useState(false);
  useEffect(() => {
    auth || localStorage.getItem("userToken") ? setAuth(true) : setAuth(false);
  }, [authListener]);
  const renderBtn = () => {
    return (
      <div className="nav__btn">
        {location.pathname !== "/login" && (
          <Link to="/login">
            <Button type={"purple"}>
              <span>SIGN IN</span>
            </Button>
          </Link>
        )}
      </div>
    );
  };
  const logout = () => {
    dispatch(
      logOut(() => {
        history.push("/login");
      })
    );
    dispatch(
      showToast({ title: "Log out", text: "Log out success", type: "success" })
    );
  };
  useEffect(() => {
    if (burgerShow) {
      document.querySelector("html").style.overflow = "hidden";
    } else {
      document.querySelector("html").style.overflow = "visible";
    }
  }, [burgerShow]);
  return (
    <>
      <nav className={`nav ${auth ? "active" : ""}`}>
        <div className="container nav__content">
          <div className="left">
            <Link to="/properties" className="nav__logo">
              <img src="/assets/logo.svg" alt="" />
            </Link>
            {auth ? <AuthList /> : <NotAuthList />}
          </div>
          <div className="right">{auth ? <RenderProfile /> : renderBtn()}</div>
        </div>
      </nav>
      <div className="burger">
        <div className="burger__left">
          <Link to="/properties" className="nav__logo">
            <img src="/assets/logo.svg" alt="" />
          </Link>
        </div>
        <div
          onClick={() => !setBurgerShow(!burgerShow)}
          className="burger__right"
        >
          <div className="burger__line"></div>
          <div className="burger__line"></div>
          <div className="burger__line"></div>
          {burgerShow && (
            <div className="burger__content">
              {auth ? (
                <div className="burger__content-container">
                  <NavLink activeClassName="active" to={"/properties"}>
                    Properties
                  </NavLink>
                  <NavLink activeClassName="active" to={"/my-documents"}>
                    My Docs
                  </NavLink>
                  <NavLink activeClassName="active" to={"/will-it"}>
                    Will it
                  </NavLink>
                  <NavLink activeClassName="active" to={"/shared"}>
                    Shared
                  </NavLink>
                  <Link
                    to={"/account/settings"}
                    className="user-dropdown__link"
                  >
                    Account Settings
                  </Link>
                  <Link to={"/support"} className="user-dropdown__link">
                    Support
                  </Link>
                  <Link to={"/terms"} className="user-dropdown__link">
                    Terms of Use
                  </Link>
                  <div
                    onClick={() => {
                      logout();
                    }}
                    className="logout"
                  >
                    Log out
                  </div>
                </div>
              ) : (
                <div className="burger__content-container noauth">
                  <Link to={"our"}>OUR BENEFITS</Link>
                  <Link to={"/advantages"}>ADVANTAGES</Link>
                  <Link to={"/agent-portal"}>AGENT PORTAL</Link>
                  {location.pathname === "/login" ? null : (
                    <Link to={"/login"}>SIGN IN</Link>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
