import React from "react";
import { useSelector } from "react-redux";
import "./index.scss";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ setChooseVerify, showModal, setChangeMethod }) => {
  const profileData = useSelector((state) => state.profileReducer.data);

  const formattedPhone = (value) => {
    let replacedInput = value
      ?.replace(/\+1/g, "")
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    let phoneFormatted = !replacedInput[2]
      ? replacedInput[1]
      : "(" +
        replacedInput[1] +
        ") " +
        replacedInput[2] +
        (replacedInput[3] ? "-" + replacedInput[3] : "");

    return phoneFormatted;
  };

  const choose = (type) => {
    setChooseVerify(false);
    showModal(true);
    setChangeMethod(type);
  };
  return (
    <div className="choose">
      <div className="choose__container">
        <div className="choose__header">
          <h2 className="choose__title">Where to send a code ?</h2>
          <div
            className="choose__close"
            onClick={() => {
              setChooseVerify(false);
            }}
          >
            <img src="/assets/icons/close.svg" alt="close" />
          </div>
        </div>
        <div className="choose__content">
          <div
            className="choose__field"
            onClick={() => {
              choose("phone_number");
            }}
          >
            <div className="choose__label">Phone Number</div>
            <div className="choose__block">
              <div className="choose__text">
                +1 {formattedPhone(profileData?.contact_phone)}
              </div>
              <div className="choose__arrow">
                <img src="/assets/icons/arrow-right.svg" alt="arrow" />
              </div>
            </div>
          </div>
          <div
            className="choose__field"
            onClick={() => {
              choose("email");
            }}
          >
            <div className="choose__label">Email Address</div>
            <div className="choose__block">
              <div className="choose__text">{profileData?.contact_email}</div>
              <div className="choose__arrow">
                <img src="/assets/icons/arrow-right.svg" alt="arrow" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="choose__bg"
        onClick={() => {
          setChooseVerify(false);
        }}
      ></div>
    </div>
  );
};
