import React, {useEffect, useState} from 'react'
import {Helmet} from "react-helmet";
import {Link, useHistory, useLocation} from "react-router-dom";
import Api from "../../../API/api";
import RenderDropdownOptions from "../../InfoItem/RenderDropdownOptions";
import {Button, Search} from "../../../UI";
import {showUploadFileModal} from "../../../Store/Actions/Properties/UploadDocument";

export default () => {
    const history = useHistory()
    const location = useLocation()
    const [data, setData] = useState(null)
    const [activeTab, setActiveTab] = useState(true);
    const [typeList, setTypeList] = useState(true);
    const [docsData, setDocsData] = useState([]);
    const [initialDocsData, setInitialDocsData] = useState([]);

    useEffect(() => {
        getItem()
    }, [])
    const getItem = () => {
        if (location.state?.id) {
            Api.getData(`share/items/${location.state?.id}`)
                .then(res => {
                    setData(res.data.data)
                    setDocsData(res.data.data.documents)
                    setInitialDocsData(res.data.data.documents)
                })
                .catch(err => {
                })
        } else {
            history.push('/properties')
        }
    }
    const getSearchValue = (value) => {
        let newData = [...docsData];
        newData = newData.filter((item) =>
            item.name.toLowerCase().includes(value)
        );
        value.length > 0 ? setDocsData(newData) : setDocsData(initialDocsData);
    };
    return (
        <section className="properties-item">
            <Helmet>
                <title>Shared Item | Missit</title>
            </Helmet>
            <div className={'container properties-item__container'}>
                <div className="properties-item__nav">
                    <a onClick={() => {
                        history.goBack()
                        history.goBack()
                    }}>Properties</a>
                    <span>
                        <img src="/assets/icons/arrow_gray_right.svg" alt="" />
                      </span>
                    <a onClick={() => history.goBack()}>
                        {location.state?.parentName ?? ''}
                    </a>
                    <span>
                        <img src="/assets/icons/arrow_gray_right.svg" alt="" />
                    </span>
                    <span>{data?.name ?? ''}</span>
                </div>
                <header className="properties-item__header">
                    <div className="left">
                        <div className="properties-item__photo">
                            <img
                                src={`${
                                    data?.property_preview ??
                                    data?.preview_img ??
                                    `/assets/images/default_preview.png`
                                }`}
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="center">
                        <div className="header__top">
                            <h1 className="properties-item__title">
                                {data?.name ?? ""}
                            </h1>
                        </div>
                        <div className="header__center">
                        </div>
                        <div className="header__bottom">
                            <div className="properties-item__info">
                                <div className="icon">
                                    <img src="/assets/icons/gray-coolicon.png" alt="" />
                                </div>
                                <div className="text">
                                    <div className="key">Value:</div>
                                    <div className="value">
                                        {`$ ${data?.value
                                            .split("")
                                            .reverse()
                                            .join("")
                                            .replace(/(\d{3})(?!$)/g, "$1,")
                                            .split("")
                                            .reverse()
                                            .join("")}` ?? "$ 0"}
                                    </div>
                                </div>
                            </div>
                            <div className="properties-item__info">
                                <div className="icon">
                                    <img src="/assets/icons/gray-inbox.svg" alt="" />
                                </div>
                                <div className="text">
                                    <div className="key">Bought date:</div>
                                    <div className="value">
                                        {data?.bought_date ?? ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right"></div>
                </header>
                <div className="properties-item__tabs">
                    <div onClick={() => setActiveTab(true)}
                         className={`properties-item__tab ${activeTab ? 'active' : ''}`}>
                        <div className="icon">
                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_4451_2240)">
                                    <path
                                        d="M11.243 1.70927L17.9097 5.0426C18.1874 5.18059 18.421 5.39329 18.5844 5.65681C18.7479 5.92033 18.8345 6.2242 18.8347 6.53427V14.4759C18.8345 14.786 18.7479 15.0899 18.5844 15.3534C18.421 15.6169 18.1874 15.8296 17.9097 15.9676L11.243 19.3009C11.0115 19.4168 10.7561 19.4771 10.4972 19.4771C10.2383 19.4771 9.98289 19.4168 9.75134 19.3009L3.08468 15.9676C2.80728 15.8278 2.57447 15.6133 2.4125 15.3483C2.25053 15.0832 2.16585 14.7782 2.16801 14.4676V6.53427C2.16817 6.2242 2.25483 5.92033 2.41824 5.65681C2.58165 5.39329 2.81533 5.18059 3.09301 5.0426L9.75968 1.70927C9.99014 1.59475 10.244 1.53516 10.5013 1.53516C10.7587 1.53516 11.0125 1.59475 11.243 1.70927V1.70927Z"
                                        stroke="#222B45" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_4451_2240">
                                        <rect width="20" height="20" fill="white" transform="translate(0.5 0.5)"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div className="text">Images</div>
                    </div>
                    <div onClick={() => setActiveTab(false)}
                         className={`properties-item__tab ${!activeTab ? 'active' : ''}`}>
                        <div className="icon">
                            <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8.2 1H2.6C2.17565 1 1.76869 1.16857 1.46863 1.46863C1.16857 1.76869 1 2.17565 1 2.6V15.4C1 15.8243 1.16857 16.2313 1.46863 16.5314C1.76869 16.8314 2.17565 17 2.6 17H12.2C12.6243 17 13.0313 16.8314 13.3314 16.5314C13.6314 16.2313 13.8 15.8243 13.8 15.4V6.6L8.2 1Z"
                                    stroke="#979B9D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M8.19922 1V6.6H13.7992" stroke="#979B9D" strokeWidth="1.5" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </svg>
                        </div>
                        <div className="text">Docs</div>
                    </div>
                </div>
                {!activeTab && (
                    <div className="properties-item__docs-header">
                        <div></div>
                        <div className="properties-item__search">
                            <Search getValue={getSearchValue}/>
                        </div>
                        <div className="properties-item__types">
                            <div onClick={() => setTypeList(true)}
                                 className={`properties-item__type ${typeList ? 'active' : ''}`}>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="6.5" y="9.5" width="6" height="6" rx="2" transform="rotate(90 6.5 9.5)"
                                          fill="#2B1D8D"/>
                                    <rect x="6.5" y="0.5" width="6" height="6" rx="2" transform="rotate(90 6.5 0.5)"
                                          fill="#2B1D8D"/>
                                    <rect x="15.5" y="0.5" width="6" height="6" rx="2" transform="rotate(90 15.5 0.5)"
                                          fill="#2B1D8D"/>
                                    <rect x="15.5" y="9.5" width="6" height="6" rx="2" transform="rotate(90 15.5 9.5)"
                                          fill="#2B1D8D"/>
                                </svg>
                            </div>
                            <div onClick={() => setTypeList(false)}
                                 className={`properties-item__type ${!typeList ? 'active' : ''}`}>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="16" y="10.5" width="5" height="16" rx="2.5" transform="rotate(90 16 10.5)"
                                          fill="#ACAFB1"/>
                                    <rect x="16" y="0.5" width="5" height="16" rx="2.5" transform="rotate(90 16 0.5)"
                                          fill="#ACAFB1"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                )}
                {activeTab && data?.photos.length > 0 ? (
                    <div className="dashboard__list">
                        {
                            data?.photos.map(item => (
                                <div className={"property-item nn"}>
                                    <div className="property-item__img">
                                        <img src={item?.url} alt=""/>
                                    </div>
                                    <div className="property-item__info">
                                        <div className="proeprty-item__title">{item?.filename}</div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                ) : <div></div>}
                {!activeTab && docsData.length > 0 && (
                    <div className={`dashboard__list ${typeList ? '' : 'line'}`}>
                        {
                            docsData.map(item => (
                                <div onClick={() => history.push('/shared-file', {name: item.name, uid: item.uid})} className={`property-doc`} >
                                    <div className="property-doc__preview">
                                            <span className="property-doc__icon">
                                              <img src={item.icon} alt="pdf" />
                                            </span>
                                        <div className="property-doc__bg"></div>
                                    </div>
                                    <div className="property-doc__footer">
                                        <div className="property-doc__title">{item.name}</div>
                                        <div className="property-doc__size">{item.size}</div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                )}
            </div>
        </section>
    )
}
