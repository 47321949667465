import React, { useState, useEffect } from "react";
import { propertiesDropdownOptions } from "../../Global/initialData";

import "./index.scss";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getItemById,
  getPropertiesById,
  resetItem,
  resetProperties,
} from "../../Store/Actions/Properties/getPropertiesById";
import {
  deleteProperty,
  deletePropertyItem, showEditable,
} from "../../Store/Actions/Properties/deletePropery";
import { showPropertyModal } from "../../Store/Actions/Properties/propertyModal";
import { showPropertyItemModal } from "../../Store/Actions/Properties/addItemToProperty";
import { itemDropdown, mainDropdown } from "./dropdownOptions";
import {
  showShareDocumentModal,
  showUploadFileModal,
} from "../../Store/Actions/Properties/UploadDocument";
import { login } from "../../Store/Actions/Auth/loginActions";
import {
  mainID,
  showItemHandler,
  showPropertyHandler,
} from "../../Store/Actions/Properties/getProperties";
import {shareProperty} from "../../Store/Actions/Properties/shareProperty";
import {Checkbox} from "../../UI";
import {loaderHide, loaderShow} from "../../Store/Actions/Loader/loaderActions";
import Api from "../../API/api";

export default ({
  item,
  properties = true,
  link,
  delImg = false,
  itemId,
  showEditModal,
  dots = true,
  isMain,
  itemizedValue = true,
  editable,
  setEditable,
  getCheckboxId,
    idArray,
  setIsShared,
  setIsDelete,
    //               setShowDropdown,
    // showDropdown
}) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  // STATE
  let dropdownOptions = properties ? [...mainDropdown] : [...itemDropdown];
  const [selectedItemId, setSelectedItemId] = useState(null)
  const [showDropdown, setShowDropdown] = useState(false);
  const propertiesById = useSelector(
    (state) => state.propertiesReducer.propertiesById
  );
  const dataModal = useSelector((state) => state.propertiesReducer.deleteItems);
  // LOAD
  useEffect(() => {
    return window.addEventListener("mousedown", (e) => {
      if (e.target.closest(".property-item") === null) {
        setShowDropdown(false);
      }
    });
  }, []);
  const dropdownActions = {
    add_item_main: (item) => {
      dispatch(showPropertyItemModal({ id: item.id }));
      setShowDropdown(false);
    },
    delete_main: (item) => {
      dispatch(deleteProperty(item.id));
      setShowDropdown(false);
    },
    edit_main: (elem) => {
      dispatch(showPropertyModal({ item: elem }));
      setShowDropdown(false);
    },
    upload_main: () => {
      dispatch(showUploadFileModal());
      setShowDropdown(false);
    },
    share_main: (item) => {
      // dispatch(shareProperty([item.id]))
      dispatch(showShareDocumentModal([item.id]));
      setShowDropdown(false);
    },
    delete_item: (item) => {
      setShowDropdown(false)
      setEditable(true)
      getCheckboxId(item.id)
      dispatch(showEditable(true))
      setIsDelete(true)
    },
    edit_item: (item) => {
      dispatch(showPropertyItemModal({ id: item.id, item: item }));
    },
    share_item: (item) => {
      setShowDropdown(false)
      setEditable(true)
      getCheckboxId(item.id)
      dispatch(showEditable(true))
      setIsShared(true)
      // dispatch(shareProperty([item.id]))
      // dispatch(showShareDocumentModal([item.id]))

    }
  };
  useEffect(() => {
    dropdownOptions.map((item) => {
      for (let key in dropdownActions) {
        if (item.id === key) {
          item.action = dropdownActions[key];
        }
      }
    });
  }, [idArray]);
  // console.log(idArray)
  // ACTIONS
  // const deleteItemAction = () => {
  //     dispatch(deleteProperty(item.id))
  // }
  // const addAction = () => {
  //     dispatch(showPropertyItemModal({itemId}))
  // }
  // const uploadAction = () => {
  //     console.log('upload')
  // }
  // const downloadAction = () => {
  //     console.log('download')
  // }
  // const editActionItem = () => {
  //     console.log(1234)
  // }
  // const moveAction = () => {
  //     console.log('move')
  // }
  // const shareAction = () => {
  //     console.log('share')
  // }
  // const editAction = (elem) => {
  //     dispatch(showPropertyModal({item: elem, itemId}))
  // }
  // const deleteAction = () => {
  //     setShowDropdown(false)
  //     dispatch(deleteProperty(item.id))
  // }
  const deleteImg = () => {
    dispatch(loaderShow())
    Api.delete(`file/${item.id}`, {}, localStorage.getItem('userToken'))
      .then((res) => {
        dispatch(getItemById(localStorage.getItem('item_by_id')))
      })
      .catch(() => {
        dispatch(loaderHide())
      })
  }
  const toItem = () => {
    if (link) {
      if (location.pathname === "/properties") {
        dispatch(resetProperties());
        dispatch(showPropertyHandler());
        dispatch(getPropertiesById(item?.id));
        dispatch(mainID(item?.id));
      } else if (location.pathname === "/properties/item") {
        dispatch(resetItem());
        dispatch(showItemHandler());
        dispatch(getItemById(item?.id));
        dispatch(mainID(item?.id));
      }
      history.push(link, { itemId, isMain });
    }
  };
  const dropdownShow = () => {
    setShowDropdown(!showDropdown);
  };

  // RENDER
  const renderDropdownOptions = () => {
    return dropdownOptions.map((elem) => (
      <div
        onClick={() => {
          elem.action(item)
          showDropdown && setShowDropdown(false)
        }}
        id={elem.id}
        key={elem.id}
        className={`option ${elem?.border ? "border" : ""}`}
      >
        <span className="icon">
          <img src={elem.icon} alt="" />
        </span>
        <span className="text">{elem.text}</span>
      </div>
    ));
  };
  return (
    <div
        key={item?.id}
      id={item?.id}
      className={`property-item ${item?.filename ? "nn" : ""} ${dataModal?.editable ? 'nn' : ''} ${delImg ? 'del' : ''}`}
    >
      <div className="property-item__preview">
        <div className="property-item__img">
          <img
            src={
              item.preview_img ??
              item.url ??
              "/assets/images/default_preview.png"
            }
            alt=""
          />
        </div>
        <div onClick={() => toItem()} className="property-item__bg"></div>
        {dots && !dataModal?.editable === true && (
          <div onClick={() => {
            dropdownShow()
            setSelectedItemId(item?.id)
          }} className="property-item__dots">
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
        {delImg && (
          <div onClick={() => {deleteImg()}} className="property-del__dots">
            <img src="/assets/icons/delete.svg" alt=""/>
          </div>
        )}
        {editable && dataModal.editable ? <div className="property-item__checkbox">
          <Checkbox status={idArray && idArray.includes(item?.id)} action={getCheckboxId} uniq={Math.floor(Math.random()*505223)} id={item?.id} />
        </div> : null}
        {!item?.filename && (
          <div className="property-item__price">
            <span className="icon">
              <img src="/assets/icons/coolicon.svg" alt="" />
            </span>
            <span className="value">
              {item?.currency}{" "}
              {item?.value
                .split("")
                .reverse()
                .join("")
                .replace(/(\d{3})(?!$)/g, "$1,")
                .split("")
                .reverse()
                .join("") ?? "120,000"}
            </span>
          </div>
        )}
      </div>
      <div onClick={() => toItem()} className="property-item__info">
        <div className="property-item__title">
          {item?.name ?? item?.filename ?? "House 2"}
        </div>
        {itemizedValue && !item?.filename && (
          <div className="property-item__itemized">
            <span className="key">Itemized Value:</span>
            <span className="value">
              {" "}
              {item?.itemized_value ? `${item?.itemized_value}` : ""}
            </span>
          </div>
        )}
      </div>
      {showDropdown && (
        <div className="property-item__dropdown">{renderDropdownOptions()}</div>
      )}
    </div>
  );
};
