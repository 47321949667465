import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import { hideShareDocumentModal } from "../../Store/Actions/Properties/UploadDocument";
import { Button, Input } from "../../UI";
import { DefaultModal } from "../index";

import "./index.scss";
import {shareProperty, shareWithEmail} from "../../Store/Actions/Properties/shareProperty";
import share from "../../Sections/Account/Share";
import {showEditable} from "../../Store/Actions/Properties/deletePropery";

export default ({idArray, setIdArray}) => {
  const localhost = 'https://client.missit.com'
  // const localhost = 'http://localhost:3000'
  const [link, setLink] = useState('')
  const [email, setEmail] = useState('')
  const dispatch = useDispatch();
  const itemId = useSelector((state) => state.propertiesReducer.shareDocumentModal.id)
  const sharedToken = useSelector((state) => state.propertiesReducer.shareToken)
  useEffect(() => {
    setLink(localhost + `/shared-view?uid=${sharedToken}`)
  }, [sharedToken])
  const hideModal = () => {
    dispatch(hideShareDocumentModal());
  };
  const onShareWithEmail = () => {
    // dispatch(shareWithEmail(email, `${localhost}/shared-view?uid=${sharedToken}`, sharedToken))
    dispatch(shareProperty(itemId, email, setIdArray))
  }
  const copyLink = () => {
    // navigator.clipboard.writeText(link);
    dispatch(shareProperty(itemId, '', setIdArray))
    // setIdArray([])
  }
  return (
    <DefaultModal close={hideModal} title={`Share ${itemId.length} item(s)`}>
      <div className="share">
        <div className="share__search">
          <div className="share__search_input">
            <input onChange={(e) => setEmail(e.target.value)} type="text" placeholder="Add an email address" />
          </div>
          <Button action={onShareWithEmail} type={"share__btn"}>Share</Button>
        </div>
        <div className="share__copy">
          <img src="/assets/icons/link.svg" />
          <a onClick={copyLink} href="#">Copy Link</a>
          <input type="hidden" value={link} />
        </div>
      </div>
    </DefaultModal>
  );
};
