import React, {useEffect, useState} from 'react'
import {BackButton, Button, Input} from "../../../UI";

import './index.scss'
import {DefaultModal} from "../../../Components";
import {shareEmail} from "../../../Global/initialData";
import {validationEmail} from "../../../Global/validation";
import Api from "../../../API/api";
import {SEND_INVITE_AGENTS} from "../../../API/endpoints";
import {loaderHide, loaderShow} from "../../../Store/Actions/Loader/loaderActions";
import {useDispatch, useSelector} from "react-redux";
import {getResentAgents} from "../../../Store/Actions/Profile/getResentAgents";
import { Helmet } from 'react-helmet';

export default () => {
    const dispatch = useDispatch()
    const [shareInputData, setShareInputData] = useState({
        email: {...shareEmail, id: "email"},
    })
    const [modal, setModal] = useState(false)

    useEffect(() => {
        dispatch(getResentAgents())
    }, [])

    const list = useSelector((state) => state.agentsReducer.data)

    const renderModal = () => (
        <DefaultModal title={"Share"} close={hideModal}>
            <div className="share__modal-info">
                <div>
                    <img src="/assets/icons/success-arrow.svg" alt="success"/>
                    <span>Invitation link successfully sent to</span>
                </div>
                <div>{shareInputData.email.value}</div>
            </div>
            <div className="share__modal-description">
                Please ask them to check Spam/Junk folder if they don’t get it within 15 minutes.
            </div>
            <div className="share__modal-btn">
                <Button action={submitHandler} type={'purple'}><span>Okay</span></Button>
            </div>
        </DefaultModal>
    )
    const inputHandler = (value, id) => {
        for (let key in shareInputData) {
            if (key === id) {
                setShareInputData(old => {
                    return {
                        ...old,
                        [key]: {
                            ...old[key],
                            value: value,
                            errorVisible: false
                        }
                    }
                })
            }
        }
    }
    const checkInput = (email, key) => {
        if (validationEmail(email)) {
            setShareInputData(old => {
                return {
                    ...old,
                    [key]: {...old[key], errorVisible: false}
                }
            })
            return true
        } else {
            setShareInputData(old => {
                return {
                    ...old,
                    [key]: {...old[key], errorVisible: true}
                }
            })
            return false
        }
    }
    const submitHandler = () => {
        hideModal()
    }
    const showModal = () => {
        const params = {
            email: null,
        }
        checkInput(shareInputData.email.value, 'email') ? params.email = shareInputData.email.value : params.email = null;
        if(params.email) {
            dispatch(loaderShow())
            Api.postData(SEND_INVITE_AGENTS, params, localStorage.getItem('userToken'))
              .then(() => {
                  setModal(true)
                  dispatch(getResentAgents())
              })
              .catch((err) => {
                  dispatch(loaderHide())
              })
        }
    }
    const hideModal = () => {
        setModal(false)
    }
    const renderEmpty = () => (
        <div className="share__empty">
            <div className="img"><img src="/assets/images/share-list-no.png" alt=""/></div>
            <div className="text">Your recent agents will be here.</div>
        </div>
    )
    const renderItem = () => {
        return list.map((item, idx) => (
            <div className="share__list-item" key={idx}>
                <div className="key">{item.name}</div>
                {<div className="value"><span>Left time :</span><span style={{color: item.status_color}}>23:16m</span></div>}
            </div>
        ))
    }
    return (
        <section className="account__share">
            <Helmet>
                <title>Account Share | Missit</title>
            </Helmet>
            {modal && renderModal()}
            <div className="container account__container">
                <div className="account__nav">
                    <BackButton type={"block"} link={'/account/settings'}/>
                    <div className="account__nav-info">
                        <h1 className="account__nav-title">Share access with an agent</h1>
                        <h3 className="account__nav-description">Provision of access.</h3>
                    </div>
                </div>
                <div className="share__description">
                    <div className="img">
                        <img src="/assets/icons/key.svg" alt=""/>
                    </div>
                    <div className="text">Enter the email of the agent you want to give access, remember that access
                        will become inactive after 24 hours.
                    </div>
                </div>
                <div className="account__hr"></div>
                <div className="share__input">
                    <Input value={shareInputData.email.value}
                           id={shareInputData.email.id} type={shareInputData.email.type}
                           placeholder={shareInputData.email.placeholder}
                           error={shareInputData.email.error} showError={shareInputData.email.errorVisible}
                           action={inputHandler}/>
                    <div onClick={() => showModal()} className="button">Share</div>
                </div>
                <div className="account__hr"></div>
                <div className="share__subtitle">Your list of agents</div>
                <div className="share__list">{list?.length > 0 ? renderItem() : renderEmpty()}</div>
            </div>
        </section>
    )
}
