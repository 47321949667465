import React from "react";
import { useDispatch } from "react-redux";
import { showPropertyItemModal } from "../../../Store/Actions/Properties/addItemToProperty";
import { showCreateDocumentModal } from "../../../Store/Actions/Properties/UploadDocument";
import { Button } from "../../../UI";

export default ({ itemId, type }) => {
  const dispatch = useDispatch();
  const addItem = () => {
    dispatch(showPropertyItemModal({ id: itemId }));
  };
  const addDocument = () => {
    dispatch(showCreateDocumentModal({id: itemId}));
  };
  return (
    <div className="dashboard__content-empty">
      <div className="empty__img">
        <img src="/assets/images/properties-item__empty.svg" alt="empty" />
      </div>
      <div className="empty__title">There are {type ? 'no items' : 'no documents'} yet</div>
      <p className="empty__description">
        Here you can add and manage {type ? 'items' : 'documents'} related to this property.
      </p>
      <div className="empty__btn">
        <Button action={type ? addItem : addDocument} type="success">
          <span>Add {type ? "item" : "document"}</span>
        </Button>
      </div>
    </div>
  );
};
