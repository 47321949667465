const emailData = {
  id: "email",
  placeholder: "Enter email",
  label: "Email",
  type: "email",
  value: "",
  error: "Please enter valid email",
  errorVisible: false,
};
const passwordData = {
  id: "password",
  placeholder: "Enter password",
  label: "Password",
  type: "password",
  value: "",
  error: "Minimum 6 characters, 1 lowercase, 1numeric and 1 special character",
  errorVisible: false,
  textVisible: false,
  isPassword: true,
};
const confirmPasswordData = {
  id: "repeatPassword",
  placeholder: "Repeat new password",
  label: "Repeat password",
  type: "password",
  value: "",
  error: "password and repeat password is not match",
  errorVisible: false,
  textVisible: false,
  isPassword: true,
};
const confirmCodeData = {
  value: "",
  error: "Please enter valid code",
  errorVisible: false,
};

const fullNameData = {
  id: "fullName",
  placeholder: "Enter full name",
  label: "Full name",
  type: "text",
  value: "",
  error: "Min length 8 symbols",
  errorVisible: false,
};

const profileInfoData = {
  id: "profileData",
  placeholder: "Full name",
  label: "Full name",
  type: "text",
  value: "",
  error: "Min length 8 symbols",
  errorVisible: false,
};

const addressData = {
  id: "address",
  placeholder: "Enter address",
  label: "Address",
  type: "text",
  value: "",
  error: "Please",
  errorVisible: false,
};

const dateBirthData = {
  id: "birth",
  placeholder: "MM / DD / YYYY",
  label: "Date of birth",
  type: "text",
  value: "",
  error: "Please",
  errorVisible: false,
};
const shareEmail = {
  id: "shareEmail",
  placeholder: "Enter email",
  type: "text",
  value: "",
  error: "Please enter valid email",
  errorVisible: false,
};
const addMember = {
  id: "addMember",
  placeholder: "Enter assignees full name",
  type: "text",
  value: "",
  error: "Min length 3 symbols",
  errorVisible: false,
};

export {
  emailData,
  passwordData,
  confirmPasswordData,
  confirmCodeData,
  fullNameData,
  addressData,
  dateBirthData,
  shareEmail,
  profileInfoData,
  addMember,
};
