import {AGENTS_DATA} from "../Types/Profile/profile";

const initialState = {
  data: null
}

export default function agentsReducer (state = initialState, action) {
  switch (action.type) {
    case AGENTS_DATA:
      return {
        ...state,
        data: action.payload
      }
    default:
      return state;
  }
}
