import React from "react";
import {PropertyItem} from "../../Components";

export default ({data}) => {
    return (
        <div className="dashboard__list">
            {data.map((item, idx) => (
                <PropertyItem item={item} dots={false} delImg={true} properties={false} key={idx}/>
            ))}
        </div>
    )
}
