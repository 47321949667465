import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { BackButton, Button, Input } from "../../../UI";
import Api from "../../../API/api";
import { showToast } from "../../../Store/Actions/Properties/toastActions";
import {
  loaderHide,
  loaderShow,
} from "../../../Store/Actions/Loader/loaderActions";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [phone, setPhone] = useState("");
  const [showError, setShowError] = useState(false);

  const formattedPhone = (value) => {
    let replacedInput = value
      ?.replace(/\+1/g, "")
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    let phoneFormatted = !replacedInput[2]
      ? replacedInput[1]
      : "(" +
        replacedInput[1] +
        ") " +
        replacedInput[2] +
        (replacedInput[3] ? "-" + replacedInput[3] : "");

    return phoneFormatted;
  };

  const inputHandler = (value) => {
    setPhone(`+1 ${formattedPhone(value)}`);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (phone.length === 17) {
      setShowError(false);
      dispatch(loaderShow());
      Api.postData("auth/set-phone", {
        phone: "+" + phone.replace(/\D/g, ""),
        verify_token: location.state.verify_token,
      })
        .then((res) => {
          dispatch(loaderHide());
          history.push("/confirm-code", {
            phone: phone,
            typePage: "verify_phone_number",
            token: res.data?.data?.verify_token,
            data: {
              phone: "+" + phone.replace(/\D/g, ""),
              verify_token: location.state.verify_token,
            },
          });
        })
        .catch((err) => {
          dispatch(loaderHide());
          dispatch(
            showToast({
              title: "Phone number",
              text: err.response.data.message,
              type: "error",
            })
          );
        });
    } else {
      setShowError(true);
    }
    // history.push("/confirm-code", { phone });
  };

  return (
    <section className="confirm">
      <Helmet>
        <title>Add Phone Number | Missit</title>
      </Helmet>
      <div className="confirm__container">
        <BackButton />
        <h1 className="confirm__title">Please enter your phone number</h1>

        <form action="#" className="confirm__form" onSubmit={submitHandler}>
          <Input
            id="phone_number"
            type="tel"
            label="Phone Number"
            placeholder="+1 (123) 567 8910"
            value={phone}
            action={inputHandler}
            error={"Please enter correct number"}
            showError={showError}
          />

          <p className="confirm__subtitle">
            We'll send you a code to verify your phone number
          </p>
          <div className="confirm__btn">
            <Button type={"purple"}>
              <span>Send verification code</span>
            </Button>
          </div>
        </form>
      </div>
    </section>
  );
};
