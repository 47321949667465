export const mainDropdown = [
  {
    id: "add_item_main",
    text: "Add item",
    icon: "/assets/icons/hexagon.svg",
  },
  {
    id: "upload_main",
    text: "Upload document",
    icon: "/assets/icons/upload-cloud.svg",
    border: true,
  },
  {
    id: "share_main",
    text: "Share property",
    icon: "/assets/icons/share.svg",
  },
  {
    id: "edit_main",
    text: "Edit",
    icon: "/assets/icons/edit.svg",
  },
  {
    id: "delete_main",
    text: "Delete this property",
    icon: "/assets/icons/delete.svg",
  },
];

export const itemDropdown = [
  {
    id: "share_item",
    text: "Share",
    icon: "/assets/icons/share.svg",
  },
  {
    id: "edit_item",
    text: "Edit",
    icon: "/assets/icons/edit.svg",
  },
  {
    id: "delete_item",
    text: "Delete",
    icon: "/assets/icons/delete.svg",
  },
];
