import React, { useEffect, useState } from "react";

import { DefaultModal } from "../index";
import { useDispatch, useSelector } from "react-redux";
import {
  getRelatives,
  hideWillItemModal,
  showAddMemberModal,
} from "../../Store/Actions/WillItem/getItemDetails";
import Select from "./../../UI/Select";
import { Button } from "../../UI";

import "./index.scss";
import Api from "../../API/api";
import { GET_ASSIGN } from "../../API/endpoints";
import {
  loaderHide,
  loaderShow,
} from "../../Store/Actions/Loader/loaderActions";
import { getWillList } from "../../Store/Actions/WillItem/getWillList";
import { showToast } from "../../Store/Actions/Properties/toastActions";

export default () => {
  const dispatch = useDispatch();
  const willItemModal = useSelector(
    (state) => state.willItemReducer.willItemModal
  );
  const [relativeId, setRelativeId] = useState(null);
  const hideModal = () => {
    dispatch(hideWillItemModal());
    dispatch(getRelatives());
  };
  const handleAddMember = () => {
    dispatch(hideWillItemModal());
    dispatch(showAddMemberModal());
  };
  const handleWill = (e) => {
    setRelativeId(e.value);
  };
  const handleSave = () => {
    dispatch(loaderShow());
    const formData = new FormData();
    formData.append("passcode_token", localStorage.getItem("passcode_token"));
    Api.postData(
      `${GET_ASSIGN}/${relativeId}/${willItemModal.data.id}`,
      formData,
      localStorage.getItem("userToken")
    )
      .then((res) => {
        hideModal();
        dispatch(
          showToast({
            title: "Assigned",
            text: res.data.message,
            type: "success",
          })
        );
        dispatch(getWillList());
      })
      .catch((err) => {
        dispatch(
          showToast({
            title: "Assigned",
            text: err.response.data.message,
            type: "error",
          })
        );
        dispatch(loaderHide());
      });
  };
  return (
    <div className="itemDetails">
      <DefaultModal close={hideModal} title={"Details"}>
        <div className="content">
          <div className="willModal__preview">
            <img src={willItemModal.data.preview_img} alt="" />
          </div>
          <div className="willModal__info">
            <p className="willModal__name">{willItemModal.data.name}</p>
            <p className="willModal__price">{willItemModal.data.value}</p>
          </div>
          <div className="willModal__select">
            <label>Will to:</label>
            <div>
              <Select
                placeholder="Choose"
                defaultValue={
                  !willItemModal.data?.inheritor_id
                    ? null
                    : {
                      value: willItemModal.data.inheritor_id,
                      label: `${willItemModal.data.inheritor_name} ${willItemModal.data.inheritor_relationship}`,
                    }
                }
                options={willItemModal.relatives}
                showButton={true}
                buttonText={"Add member... +"}
                typeButton={"willBtn"}
                handleButton={handleAddMember}
                handleSelect={handleWill}
              />
            </div>
          </div>
        </div>
        <div className="willModal__btn">
          <Button
            action={handleSave}
            type={"success"}
            disable={relativeId === null ? true : false}
          >
            <span>Save</span>
          </Button>
        </div>
      </DefaultModal>
    </div>
  );
};
