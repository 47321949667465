import React from "react";
import {Link} from "react-router-dom";

export const NotAuthList = () => {
    return (
        <ul className="nav__list n">
            {/*<li>*/}
            {/*    <Link to={'our'}>OUR BENEFITS</Link>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*    <Link to={'/advantages'}>ADVANTAGES</Link>*/}
            {/*</li>*/}
            <li>
                <a target='_blank' href='https://missit.com/agent'>AGENT PORTAL</a>
            </li>
        </ul>
    )
}
