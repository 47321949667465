import React, { useEffect, useState } from "react";

import "./index.scss";
import linksData from "./linksData";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Api from "../../../API/api";
import { CHANGE_PROFILE_AVATAR } from "../../../API/endpoints";
import {
  loaderHide,
  loaderShow,
} from "../../../Store/Actions/Loader/loaderActions";
import { useDispatch } from "react-redux";
import { getProfile } from "../../../Store/Actions/Profile/getProfile";
import { showToast } from "../../../Store/Actions/Properties/toastActions";
import { Helmet } from "react-helmet";

export default () => {
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.profileReducer.data);
  const [links, setLinks] = useState([...linksData]);
  const renderLinks = () => {
    return links.map((link) => (
      <Link to={link.to} key={link.id} className="settings__link">
        <div className="link__icon">
          <img src={link.icon} alt="" />
        </div>
        <div className="link__text">
          <div className="link__title">{link.title}</div>
          <div className="link__description">
            {link?.id === "subscription"
              ? profileData?.next_period_date
                ? link.description + " " + profileData?.next_period_date
                : "Your subscription canceled"
              : link.description}
          </div>
        </div>
      </Link>
    ));
  };

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  const uploadAvatar = (e) => {
    dispatch(loaderShow());
    const body = new FormData();
    body.append("image", e.target.files[0]);
    if (body) {
      Api.postData(
        CHANGE_PROFILE_AVATAR,
        body,
        localStorage.getItem("userToken")
      )
        .then((res) => {
          dispatch(
            showToast({
              title: "Avatar",
              text: res.data.message,
              type: "success",
            })
          );
          dispatch(getProfile());
        })
        .catch((err) => {
          dispatch(
            showToast({
              title: "Avatar",
              text: err.response.data.message,
              type: "error",
            })
          );
          dispatch(loaderHide());
        });
    }
  };

  return (
    <section className="account__settings">
      <Helmet>
        <title>Account Settings | Missit</title>
      </Helmet>
      <div className="container account__container">
        <h1 className="settings__title">Account Settings</h1>
        <div className="settings__user">
          <div className="settings__user-photo">
            <span className="photo">
              <img
                src={
                  profileData?.contact_image
                    ? profileData?.contact_image
                    : `/assets/icons/default-user.svg`
                }
                alt="user"
              />
            </span>
            <span className="edit">
              <input
                onChange={(e) => {
                  uploadAvatar(e);
                }}
                type="file"
                id="changePhoto"
              />
              <label htmlFor="changePhoto">
                <img src="/assets/icons/photo-edit.svg" alt="change photo" />
              </label>
            </span>
          </div>
          <div className="settings__user-name">{profileData?.name}</div>
          <div className="settings__user-email">
            {profileData?.contact_email}
          </div>
        </div>
        <div className="settings__links">{renderLinks()}</div>
      </div>
    </section>
  );
};
