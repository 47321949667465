import { Route, useHistory } from "react-router-dom";

export default function PrivateRoute({
  path,
  exact,
  component: Component,
  ...props
}) {
  const history = useHistory();
  // const {loginData} = useSelector(LoginData);
  const token = localStorage.getItem("userToken");
  if (!token) {
    history.push("/login");
    return <div></div>;
  }
  return (
    <Route exact={exact} path={path}>
      <Component {...props} />
    </Route>
  );
}
