export const ALL_PROPERTIES = "ALL_PROPERTIES";
export const GET_ALL_ITEMS_SUCCESS = "GET_ALL_ITEMS_SUCCESS";
export const GET_PROPERTIES_BY_ID = "GET_PROPERTIES_BY_ID";
export const SHOW_PROPERTY = "SHOW_PROPERTY";
export const SHOW_ITEM = "SHOW_ITEM";
export const RESET_PROPERTIES = "RESET_PROPERTIES";
export const RESET_ITEM = "RESET_ITEM";
export const MAIN_ID = "MAIN_ID";
export const SHOW_PROPERTY_MODAL = "SHOW_PROPERTY_MODAL";
export const HIDE_PROPERTY_MODAL = "HIDE_PROPERTY_MODAL";
export const SHARE_PROPERTY = "SHARE_PROPERTY";
export const GET_SHARED_ITEMS = "GET_SHARED_ITEMS";
export const GET_SHARED_HISTORY = "GET_SHARED_HISTORY";

export const SHOW_PROPERTY_ITEM_MODAL = "SHOW_PROPERTY_ITEM_MODAL";
export const HIDE_PROPERTY_ITEM_MODAL = "HIDE_PROPERTY_ITEM_MODAL";

export const SHOW_CREATE_DOCUMENT_MODAL = "SHOW_CREATE_DOCUMENT_MODAL";
export const HIDE_CREATE_DOCUMENT_MODAL = "HIDE_CREATE_DOCUMENT_MODAL";

export const SHOW_DOCUMENT_UPLOAD_MODAL = "SHOW_DOCUMENT_UPLOAD_MODAL";
export const HIDE_DOCUMENT_UPLOAD_MODAL = "HIDE_DOCUMENT_UPLOAD_MODAL";

export const SHOW_SHARE_DOCUMENT_MODAL = "SHOW_SHARE_DOCUMENT_MODAL";
export const HIDE_SHARE_DOCUMENT_MODAL = "HIDE_SHARE_DOCUMENT_MODAL";

export const GET_ALL_DOCUMENTS = "GET_ALL_DOCUMENTS";

export const SHOW_MOVE_DOCUMENT_MODAL = "SHOW_MOVE_DOCUMENT_MODAL";
export const HIDE_MOVE_DOCUMENT_MODAL = "HIDE_MOVE_DOCUMENT_MODAL";

export const GET_FILE_BY_DOCUMENT_SUCCESS = "GET_FILE_BY_DOCUMENT_SUCCESS";

export const GET_ITEM_SUCCESS = "GET_ITEM_SUCCESS";

export const GET_ALL_TYPE_PROPERTY = "GET_ALL_TYPE_PROPERTY";

export const DELETE_ITEMS_SHOW = 'DELETE_ITEMS_SHOW';
export const DELETE_ITEMS_HIDE = 'DELETE_ITEMS_HIDE';
export const EDITABLE_CHANGE = 'EDITABLE_CHANGE';
