import React, { useState, useEffect } from "react";
import { Button, Input, Calendar } from "../../UI";
import { DefaultModal } from "../index";
import moment from "moment";

import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { addProperty } from "../../Store/Actions/Properties/addProperty";
import { editProperty } from "../../Store/Actions/Properties/editProperty";
import {
  addItemToProperty,
  editItem,
  hidePropertyItemModal,
} from "../../Store/Actions/Properties/addItemToProperty";
import { useLocation } from "react-router-dom";
export default () => {
  const location = useLocation()
  const dispatch = useDispatch();
  const propertyModal = useSelector(
    (state) => state.propertiesReducer.addPropertyItem
  );
  const [addImageListener, setAddImageListener] = useState(false);
  const [addPropertyData, setAddPropertyData] = useState({
    ...propertyModal.data,
  });
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectDate, setSelectDate] = useState("");

  const hideModal = () => {
    dispatch(hidePropertyItemModal());
  };
  useEffect(() => {
    return window.addEventListener("mousedown", (e) => {
      if (e.target.closest(".sdp") === null) {
        setShowCalendar(false);
      }
    });
  }, []);
  const addImage = (pathPreview, dataPreview) => {
    setAddImageListener(true);
    setAddPropertyData((old) => {
      return {
        ...old,
        image: {
          preview: pathPreview,
          data: dataPreview,
        },
      };
    });
  };
  const addHandler = () => {
    if (propertyModal?.id && propertyModal?.data.name) {
      dispatch(editItem(propertyModal?.id, addPropertyData, location.pathname));
    } else {
      dispatch(addItemToProperty(propertyModal.id, addPropertyData));
    }
  };
  const inputHandler = (value, id) => {
    for (let key in addPropertyData) {
      if (key === id) {
        setAddPropertyData((old) => {
          return {
            ...old,
            [key]: value,
          };
        });
      }
    }
  };
  const datePicker = (date) => {
    setShowCalendar(false);
    let str = moment(date).format("MM.DD.yyyy");
    setAddPropertyData((old) => ({
      ...old,
      bought_date: str.replaceAll("/", "."),
    }));
    setSelectDate(str.replaceAll("/", "."));
  };
  return (
    <DefaultModal close={hideModal} title={"Add Item"}>
      <div className="property-add__modal">
        <div className="property-modal__image">
          {addPropertyData.image.preview ? (
            <div className="property-modal__preview">
              <img src={addPropertyData.image.preview} alt="" />
            </div>
          ) : (
            <>
              <div className="img-placeholder">
                <img src="/assets/icons/image-placeholder.png" alt="" />
              </div>
              <div className="cover">Cover image</div>
              <div className="browse">Browse Files</div>
            </>
          )}
          <div className="input">
            <input
              onChange={(e) =>
                addImage(
                  URL.createObjectURL(e.target.files[0]),
                  e.target.files[0]
                )
              }
              accept="image/*"
              type="file"
            />
          </div>
        </div>
        <div className="property-modal__container">
          <div className="property-modal__input">
            <Input
              value={addPropertyData.name}
              label={"Item Name"}
              id={"name"}
              type={"text"}
              placeholder={"Enter name"}
              action={inputHandler}
            />
          </div>
          <div className="property-modal__input">
            <Input
              value={addPropertyData.value}
              label={"Value"}
              id={"value"}
              type={"number"}
              placeholder={"$0.0"}
              action={inputHandler}
            />
          </div>
          <div

            className="property-modal__input information__address"
          >
            <Input
              value={addPropertyData.bought_date}
              label={"Bought Date"}
              id={"bought_date"}
              type={"text"}
              placeholder={"Choose date "}
              action={inputHandler}
              disabled={true}
              click={() => {
                setShowCalendar(true);
              }}
            />
            {showCalendar && <Calendar action={datePicker} />}
          </div>
          <div className="property-modal__input">
            <Input
              value={
                addPropertyData.note === "null" ? "" : addPropertyData.note
              }
              label={"Note (Optional)"}
              id={"note"}
              type={"text"}
              placeholder={"Add additional information..."}
              action={inputHandler}
            />
          </div>
          <div className="account__hr"></div>
          <div className="property-modal__btn">
            <Button
              action={addHandler}
              type={"purple"}
              disable={
                addPropertyData.image &&
                addPropertyData.name.length !== 0 &&
                addPropertyData.value.length !== 0
                  ? false
                  : true
              }
            >
              <span>Save</span>
            </Button>
          </div>
        </div>
      </div>
    </DefaultModal>
  );
};
