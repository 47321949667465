import React, {useEffect, useState} from "react";
import {Button, Input} from "../../UI";
import {DefaultModal} from "../index";

import './index.scss'
import {useDispatch, useSelector} from "react-redux";
import {hidePropertyModal} from "../../Store/Actions/Properties/propertyModal";
import {addProperty} from "../../Store/Actions/Properties/addProperty";
import {editProperty} from "../../Store/Actions/Properties/editProperty";
import {useLocation} from "react-router-dom";
import Api from "../../API/api";
import { GET_PROPERTIES } from "../../API/endpoints";
import Select from "../../UI/Select";
export default () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const propertyModal = useSelector(state => state.propertiesReducer.propertyModal)
    const allTypes = useSelector(state => state.propertiesReducer.allTypes)
    const [addImageListener, setAddImageListener] = useState(false)
    const [addPropertyData, setAddPropertyData] = useState({...propertyModal.data})
    const [typeOptions, setTypeOptions] = useState([])

    const hideModal = () => {
        dispatch(hidePropertyModal())
    }
    const addImage = (pathPreview, dataPreview) => {
        setAddImageListener(true)
        setAddPropertyData(old => {
            return {
                ...old,
                image: {
                    preview: pathPreview,
                    data: dataPreview
                }
            }
        })
    }
    useEffect(() => {
        setTypeOptions(allTypes)
    }, [allTypes])
    const addHandler = () => {
        if (propertyModal?.data.id) {
            if (location.pathname !== '/properties/item/info') {
                dispatch(editProperty(propertyModal?.data.id, addPropertyData, location))
            } else {

            }
        } else {
            dispatch(addProperty(addPropertyData))
        }
    }
    const inputHandler = (value, id) => {
        for (let key in addPropertyData) {
            if (key === id) {
                setAddPropertyData(old => {
                    return {
                        ...old,
                        [key]: value
                    }
                })
            }
        }
    }
    const selectType = value => {
        setAddPropertyData(old => ({
            ...old,
            type_id: value
        }))
    }
    const renderSelect = () => {
        if (!addPropertyData?.type_id) {
            return (
                <Select placeholder="Select type" options={typeOptions} handleSelect={(e) => selectType(e.value)} />
            )
        } else {
            const label = allTypes.filter(item => item.value == addPropertyData.type_id)[0].label
            const defaultValue = {value: addPropertyData?.type_id, label}
            return (
                <Select defaultValue={defaultValue} placeholder="Select type" options={typeOptions} handleSelect={(e) => selectType(e.value)} />
            )
        }
    }
    return (
        <DefaultModal close={hideModal} title={addPropertyData.id !== null ? 'Edit Property' : 'Add Property'}>
            <div className="property-add__modal">
                <div className="property-modal__image">
                    {addPropertyData.image.preview ? (
                        <div className="property-modal__preview">
                            <img src={addPropertyData.image.preview} alt=""/>
                        </div>
                    ) : (
                        <>
                            <div className="img-placeholder">
                                <img src="/assets/icons/image-placeholder.png" alt=""/>
                            </div>
                            <div className="cover">Cover image</div>
                            <div className="browse">Browse Files</div>
                        </>
                    )}
                    <div className="input"><input
                        accept="image/*"
                        onChange={(e) => addImage(URL.createObjectURL(e.target.files[0]), e.target.files[0])}
                        type="file"/></div>
                </div>
                <div className="property-modal__container">
                    <div className="property-modal__input">
                        <Input value={addPropertyData.name} label={'Property Name'} id={"name"} type={"text"}
                               placeholder={"Enter name"} action={inputHandler}/>
                    </div>
                    <div className="property-modal__input">
                        <Input value={addPropertyData.value} label={'Property Value'} id={"value"} type={"number"}
                               placeholder={"$0.0"} action={inputHandler}/>
                    </div>
                    <div className="property-modal__select">
                        <span>Select type:</span>
                        {renderSelect()}
                    </div>
                    <div className="account__hr"></div>
                    <div className="property-modal__btn">
                        <Button action={addHandler} type={"purple"}
                                disable={addPropertyData.image && addPropertyData.name.length !== 0 && addPropertyData.value.length !== 0 ? false : true}>
                            <span>{addPropertyData.id !== null ? 'Edit Property' : 'Add Property'}</span>
                        </Button>
                    </div>
                </div>
            </div>
        </DefaultModal>
    )
}
