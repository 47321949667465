import React, { useState } from "react";
import { BackButton, Button } from "../../../UI";

import "./index.scss";
import { useHistory, useLocation } from "react-router-dom";
import ReactCodeInput from "react-verification-code-input";
import { confirmCodeData } from "../../../Global/initialData";
import { useDispatch, useSelector } from "react-redux";
import { forgotCode } from "../../../Store/Actions/Auth/forgotActions";
import { FORGOT_PASSCODE_CONFIRM } from "../../../API/endpoints";
import Api from "../../../API/api";
import {
  loaderHide,
  loaderShow,
} from "../../../Store/Actions/Loader/loaderActions";
import { showToast } from "../../../Store/Actions/Properties/toastActions";
import { Helmet } from "react-helmet";
import willIt from "../../WillIt";
const confirmCode = "123456";
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const verify_token = useSelector((state) => state.forgotReducer.verify_token);
  const profileData = useSelector((state) => state.profileReducer.data);
  const [code, setCode] = useState({ ...confirmCodeData });
  const [verifyTokenS, setVerifyTokenS] = useState(null);
  const [sendClicked, setSendClicked] = useState(false);

  console.log(location.state.confirm_method);

  const codeHandler = (value) => {
    setCode((old) => ({ ...old, value }));
  };

  const forgotConfirmApi = (body, redirect) => {
    Api.postData(
      FORGOT_PASSCODE_CONFIRM,
      body,
      localStorage.getItem("userToken")
    )
      .then((res) => {
        dispatch(loaderHide());
        redirect(res.data.data.token);
      })
      .catch((err) => {
        dispatch(loaderHide());
        dispatch(
          showToast({
            title: "Confirm verification code",
            text: err.response.data.message,
            type: "error",
          })
        );
      });
  };

  const formattedPhone = (value) => {
    let replacedInput = value
      ?.replace(/\+1/g, "")
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    let phoneFormatted = !replacedInput[2]
      ? replacedInput[1]
      : "(" +
        replacedInput[1] +
        ") " +
        replacedInput[2] +
        (replacedInput[3] ? "-" + replacedInput[3] : "");

    return phoneFormatted;
  };

  const verifyPhoneApi = (body) => {
    dispatch(loaderShow());
    Api.postData("auth/verify-phone", body)
      .then((res) => {
        dispatch(loaderHide());
        history.push("/signup/about", {
          verify_token: res.data.data.verify_token,
        });
      })
      .catch((err) => {
        dispatch(loaderHide());
        dispatch(
          showToast({
            title: "Confirm verification code",
            text: err.response.data.message,
            type: "error",
          })
        );
      });
  };

  const verifyEmailApi = (body) => {
    Api.postData("auth/verify-email", body)
      .then((res) => {
        dispatch(loaderHide());
        history.push("/signup/phone-number", {
          verify_token: res.data.data.verify_token,
        });
      })
      .catch((err) => {
        dispatch(loaderHide());
        dispatch(
          showToast({
            title: "Confirm verification code",
            text: err.response.data.message,
            type: "error",
          })
        );
      });
  };

  const redirect = (token) => {
    if (location.state?.phone) {
      history.push("/account/phone-number");
    } else {
      if (location.state?.typePage === "pin") {
        history.push("/account/security/pin-forgot", {
          type: "create",
          token: token,
        });
      } else {
        history.push("/new-password");
      }
    }
  };

  const confirmTerminate = (body) => {
    dispatch(loaderShow());
    Api.postData(
      `sessions/verify-terminate`,
      { token: body?.verify_token, code: body.code },
      localStorage.getItem("userToken")
    )
      .then((res) => {
        dispatch(
          showToast({
            title: "Confirm verification code",
            text: res?.data?.message,
            type: "success",
          })
        );
        dispatch(loaderHide());
        history.push("/account/session");
      })
      .catch((err) => {
        dispatch(loaderHide());
        dispatch(
          showToast({
            title: "Confirm verification code",
            text: err.response.data.message,
            type: "error",
          })
        );
      });
  };

  const verifyWillIt = (body) => {
    dispatch(loaderShow());
    Api.postData(`will-it/verify-otp`, body, localStorage.getItem("userToken"))
      .then((res) => {
        dispatch(loaderHide());
        dispatch(
          showToast({
            title: "Confirm verification code",
            text: res?.data?.message,
            type: "success",
          })
        );
        history.push("/will-it");
      })
      .catch((err) => {
        dispatch(loaderHide());
        dispatch(
          showToast({
            title: "Confirm verification code",
            text: err.response.data.message,
            type: "error",
          })
        );
      });
  };

  const verifyNewEmail = (body) => {
    dispatch(loaderShow());
    Api.postData("profile/email", body, localStorage.getItem("userToken"))
      .then((res) => {
        dispatch(
          showToast({
            title: "Confirm verification code",
            text: res?.data?.message,
            type: "success",
          })
        );
        dispatch(loaderHide());
        history.push("/account/settings");
      })
      .catch((err) => {
        dispatch(loaderHide());
        dispatch(
          showToast({
            title: "Confirm verification code",
            text: err.response.data.message,
            type: "error",
          })
        );
      });
  };

  const verifyNewNumber = (body) => {
    dispatch(loaderShow());
    Api.postData("profile/phone", body, localStorage.getItem("userToken"))
      .then((res) => {
        dispatch(
          showToast({
            title: "Confirm verification code",
            text: res?.data?.message,
            type: "success",
          })
        );
        dispatch(loaderHide());
        history.push("/account/settings");
      })
      .catch((err) => {
        dispatch(loaderHide());
        dispatch(
          showToast({
            title: "Confirm verification code",
            text: err.response.data.message,
            type: "error",
          })
        );
      });
  };

  const submitHandler = () => {
    const params = {
      code: null,
    };

    if (code.value === confirmCode || code.value.length === 6) {
      setCode((old) => ({ ...old, errorVisible: false }));
      params.code = code.value;
    } else {
      setCode((old) => ({ ...old, errorVisible: true }));
    }

    if (params.code) {
      const body = {
        verify_token: verifyTokenS
          ? verifyTokenS
          : location.state?.typePage === "signup" ||
            location.state?.typePage === "verify_phone_number" ||
            location.state?.typePage === "session" ||
            location.state?.typePage === "willit"
          ? location?.state?.token
          : verify_token,
        code: params.code,
      };
      if (
        location.state?.typePage === "pin" ||
        location.state.typePage === "signup"
      ) {
        dispatch(loaderShow());
        if (location.state.typePage === "signup") {
          verifyEmailApi(body);
        } else {
          forgotConfirmApi(body);
        }
      } else if (location.state?.typePage === "session") {
        confirmTerminate(body);
      } else if (location.state.typePage === "verify_phone_number") {
        verifyPhoneApi(body);
      } else if (location.state.typePage === "willit") {
        verifyWillIt(body);
      } else if (location.state.confirm_method === "emailNew") {
        verifyNewEmail(body);
      } else if (location.state.typePage === "confirmNew") {
        if (location.state.confirm_method === "email") {
          // verifyNewEmail(body);
          verifyNewNumber(body);
        } else {
          verifyNewNumber(body);
        }
      } else {
        dispatch(forgotCode(body, redirect));
      }
    }
  };

  const willItTryAgain = () => {
    dispatch(loaderShow());
    Api.postData(`will-it/send-otp`, {}, localStorage.getItem("userToken"))
      .then((res) => {
        dispatch(loaderHide());
        // history.push("/confirm-code", {
        //   typePage: "willit",
        //   token: res.data.data.verify_token,
        // });
        setVerifyTokenS(res.data.data.verify_token);
      })
      .catch((err) => {
        dispatch(
          showToast({
            title: "Confirm verification code",
            text: err?.response.data?.message,
            type: "error",
          })
        );
        dispatch(loaderHide());
      });
  };

  const signupTryAgain = () => {
    dispatch(loaderShow());
    Api.postData("auth/sign-up", location?.state?.data)
      .then((res) => {
        dispatch(loaderHide());
        setVerifyTokenS(res.data?.data?.verify_token);
      })
      .catch((err) => {
        dispatch(loaderHide());
        dispatch(
          showToast({
            title: "Try Again",
            text: err.response.data.message,
            type: "error",
          })
        );
      });
  };

  const verifyPhoneTryAgain = () => {
    dispatch(loaderShow());
    Api.postData("auth/set-phone", location?.state?.data)
      .then((res) => {
        dispatch(loaderHide());
        setVerifyTokenS(res.data?.data?.verify_token);
      })
      .catch((err) => {
        dispatch(loaderHide());
        dispatch(
          showToast({
            title: "Try Again",
            text: err.response.data.message,
            type: "error",
          })
        );
      });
  };

  const tryAgain = () => {
    if (location.state.typePage === "willit") {
      willItTryAgain();
    } else if (location?.state?.typePage === "signup") {
      signupTryAgain();
    } else if (location?.state?.typePage === "verify_phone_number") {
      verifyPhoneTryAgain();
    }
  };

  return (
    <section className="confirm">
      <Helmet>
        <title>Confirm | Missit</title>
      </Helmet>
      <div className="confirm__container">
        <BackButton
          link={location?.state?.typePage === "willit" ? "/properties" : "back"}
        />
        <h1 className="confirm__title">
          {location.state?.phone && location.state?.type !== "session"
            ? "Phone number verification"
            : "Confirmation"}
        </h1>
        <p className="confirm__subtitle">
          {location.state.typePage == "willit" && !sendClicked
            ? "We'll send"
            : "We've sent"}{" "}
          a verification code to:{" "}
          {location.state?.phone ? (
            <span>{location.state?.phone}</span>
          ) : location?.state?.email ? (
            <span>{location.state?.email}</span>
          ) : location.state.typePage == "willit" ? (
            <span>+1 {formattedPhone(profileData?.contact_phone)}</span>
          ) : location.state.confirm_method == "email" ? (
            <span>{profileData?.contact_email}</span>
          ) : location.state.confirm_method == "phone_number" ? (
            <span>+1 {formattedPhone(profileData?.contact_phone)}</span>
          ) : (
            <span>{profileData?.contact_email}</span>
          )}
        </p>
        <div className="confirm__code">
          <ReactCodeInput
            className={"confirm__code-field"}
            onChange={codeHandler}
            fieldWidth={48}
            fieldHeight={48}
            autoFocus={false}
          />
          {code.errorVisible && (
            <div className="confirm__code-error">{code.error}</div>
          )}
        </div>
        <div
          className="confirm__try"
          onClick={() => {
            if (location.state.typePage === "willit" && !sendClicked) {
              setSendClicked(true);
              tryAgain();
            } else if (location.state.typePage === "willit" && !sendClicked) {
              willItTryAgain();
            } else {
              tryAgain();
            }
          }}
        >
          {location.state.typePage === "willit" && !sendClicked ? (
            <div>
              To get a confirmation code, please click: <span>Send a code</span>
            </div>
          ) : (
            <div>
              Didn't receive a code? <span>Try again</span>
            </div>
          )}
        </div>
        <div className="confirm__btn">
          <Button
            disable={code.value.length === 6 ? false : true}
            type={"purple"}
            action={submitHandler}
          >
            <span>Confirm</span>
          </Button>
        </div>
      </div>
    </section>
  );
};
