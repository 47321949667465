import {LOADER_HIDE, LOADER_SHOW} from "../Types/Loader/loader";

const initialState = {
    show: false,
}

export default function  loaderReducer (state = initialState, action) {
    switch (action.type) {
        case LOADER_SHOW:
            return {
                ...state,
                show: true
            }
        case LOADER_HIDE:
            return {
                ...state,
                show: false
            }
        default:
            return state;
    }
}
