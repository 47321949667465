// ACTIONS
const itemMore = (data, setData, id) => {
  let array = data.map((item) => {
    if (item.id === id) {
      item.more = !item.more;
    }
    return item;
  });
  setData(array);
};
const deleteAction = (data, setData, id) => {
  let array = data.filter((item) => item.id !== id);
  setData(array);
};

// DATA
const dropdownOptions = [
  // {
  //   id: "share",
  //   text: "Share",
  //   icon: "/assets/icons/share.svg",
  //   // action: downloadAction
  // },

  {
    id: "move",
    text: "Move to",
    icon: "/assets/icons/move.svg",
    border: true,
    // action: moveAction
  },
  {
    id: "delete",
    text: "Delete file",
    icon: "/assets/icons/delete.svg",
    action: deleteAction,
  },
];
const initialData = [
  {
    id: 1,
    checked: false,
    name: "Name Item",
    description: "Description InfoItem",
    size: 162,
    type: "auto",
    more: false,
  },
];

export { initialData, dropdownOptions, itemMore };
