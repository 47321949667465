import axios from "axios";
import { baseURL } from "./endpoints";

const http = axios.create({
  baseURL: baseURL,
});

http.interceptors.response.use(
  (request) => {
    return request;
  },
  (error) => {
    if (error?.response?.status == "401") {
      localStorage.removeItem("userToken");
      // window.location.href = "/login";
    } else if (error?.response?.status == "402") {
      if (window.location?.pathname !== "/subscription") {
        window.location.href = "/subscription";
      }
    }
    return Promise.reject(error);
  }
);

const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "Tablet Web";
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "Mobile Web";
  }
  return "Desktop Web";
};

const setHeaders = (token) => {
  if (!token) return;
  return {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      AppType: getDeviceType(),
      DeviceModel: getDeviceType(),
    },
  };
};

const Api = {
  getData: (url, token) => http.get("/api/v1/" + url, setHeaders(token)),
  // getDataById: (url, id, token) => http.get("/api/v1/" + url, setHeaders(token)),
  postData: (url, body, token) =>
    http.post("/api/v1/" + url, body, setHeaders(token)),
  putData: (url, content, token) =>
    http.put("/api/v1/" + url, content, setHeaders(token)),
  delete: (url, body, token) =>
    http.delete("/api/v1/" + url, { data: body, ...setHeaders(token) }),
  patchData: (url, content, token) =>
    http.patch("/api/v1/" + url, content, setHeaders(token)),
};

export default Api;
