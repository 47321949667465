import {
  FILL_MODAL_DETAILS,
  FILL_MODAL_RELATIVES, HIDE_ADDMEMBER_MODAL,
  HIDE_WILLITEM_MODAL, SHOW_ADDMEMBER_MODAL,
  SHOW_WILLITEM_MODAL
} from "../Types/WillItem/willitem";

const initialState = {
  data: [],
  willItemModal: {
    show: false,
    data: {
      id: null,
      preview_img: '',
      value: '',
      name: '',
    },
    relatives: null,
  },
  addMemberModal: {
    show: false,
  }
}

export default function willItemReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_WILLITEM_MODAL:
      return {...state, willItemModal: {show: true}}
    case HIDE_WILLITEM_MODAL: {
      return {...state, willItemModal: {show: false}}
    }
    case SHOW_ADDMEMBER_MODAL: {
      return {...state, addMemberModal: {show: true}}
    }
    case HIDE_ADDMEMBER_MODAL: {
      return {...state, addMemberModal: {show: false}}
    }
    case FILL_MODAL_DETAILS: {
      return {
        ...state, willItemModal: {
          ...state.willItemModal,
          data: action.payload
        }
      }
    }
    case FILL_MODAL_RELATIVES: {
      return {
        ...state,
        willItemModal: {
          ...state.willItemModal,
          relatives: action.payload
        }
      }
    }
    default:
      return state;
  }
}
