import {loaderHide, loaderShow} from "../Loader/loaderActions";
import Api from "../../../API/api";
import {GET_ALL_DOCUMENTS} from "../../Types/Properties/properties";
import {PROPERTY_DOCUMENT} from "../../../API/endpoints";

export const getAllDocuments = (body) => (dispatch) => {
    dispatch(loaderShow())
    Api.getData(`${PROPERTY_DOCUMENT}s${body?.name ? `?name=${body?.name}` : ''}${body?.name ? body?.itemId ? `&itemId=${body?.itemId}` : '' : body?.itemId ? `?itemId=${body?.itemId}` : ''}`, localStorage.getItem('userToken'))
        .then(res => {
            dispatch(getSuccess(res.data.data))
            dispatch(loaderHide())
        })
        .catch(err => {
            dispatch(loaderHide())
        })
}

export const getSuccess = (payload) => {
    return {
        type: GET_ALL_DOCUMENTS,
        payload
    }
}
