import Api from "../../../API/api";
import { LOGIN } from "../../../API/endpoints";
import { LOGIN_SUCCESS, LOG_OUT } from "../../Types/Auth/login";
import { loaderHide, loaderShow } from "../Loader/loaderActions";
import { showToast } from "../Properties/toastActions";
import { forgotSendCode } from "./forgotActions";
import {getProperties} from "../Properties/getProperties";

export const login = (payload, redirect) => (dispatch) => {
  dispatch(loaderShow());
  Api.postData(LOGIN, payload)
    .then((res) => {
      if (!res.data.data?.password_token) {
        dispatch(
          showToast({ title: "Login", text: res.data.message, type: "success" })
        );
        dispatch(loaderHide());
        dispatch(loginSuccess(res.data.data.token));
        localStorage.setItem("userToken", res.data.data.token);
        localStorage.getItem("userToken") && dispatch(getProperties())
        redirect(true);
      } else {
        redirect(false);
        dispatch(loaderHide());
        dispatch(forgotSendCode(res.data.data?.password_token));
      }
    })
    .catch((err) => {
      dispatch(
        showToast({
          title: "Login",
          text: err.response.data.message,
          type: "error",
        })
      );
      dispatch(loaderHide());
    });
};

export const loginSuccess = (payload) => {
  return {
    type: LOGIN_SUCCESS,
    payload,
  };
};

export const logOut = (redirect) => (dispatch) => {
  dispatch(loaderShow());
  console.log(
    localStorage.getItem("userToken"),
    'localStorage.getItem("userToken")'
  );
  Api.postData("auth/logout", {}, localStorage.getItem("userToken"))
    .then((res) => {
      dispatch(loaderHide());
      dispatch(logoutSuccess());
        localStorage.removeItem("userToken");
      redirect();
    })
    .then((err) => {
      dispatch(loaderHide());
    });
};

export const logoutSuccess = () => {
  return {
    type: LOG_OUT,
  };
};
