/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from "react";
import { BackButton, Button, Input } from "../../../UI";
import { DefaultModal } from "../../../Components";

import "./index.scss";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import Api from "../../../API/api";
import { useDispatch } from "react-redux";
import ChoosVerify from "../../../Components/ChoosVerify";
import { showToast } from "../../../Store/Actions/Properties/toastActions";
import {
  loaderHide,
  loaderShow,
} from "../../../Store/Actions/Loader/loaderActions";
export default () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.profileReducer.data);
  const [statusModal, setStatusModal] = useState(false);
  const [changeMethod, setChangeMethod] = useState(null);
  const [chooseVerify, setChooseVerify] = useState(false);

  const [modalData, setModalData] = useState({
    newNumber: {
      id: "newNumber",
      placeholder: "New Phone Number",
      label: "New Phone Number",
      error: "Please enter correct phone number",
      value: "",
      showError: false,
    },
    confirmNumber: {
      id: "confirmNumber",
      placeholder: "Confirm New Phone Number",
      label: "Confirm New Phone Number",
      error: "Confirmation phone number and new phone number do not match",
      value: "",
      showError: false,
    },
  });

  const formattedPhone = (value) => {
    if (value && value !== "") {
      let replacedInput = value
        ?.replace(/\+1/g, "")
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      let phoneFormatted = !replacedInput[2]
        ? replacedInput[1]
        : "(" +
          replacedInput[1] +
          ") " +
          replacedInput[2] +
          (replacedInput[3] ? "-" + replacedInput[3] : "");

      return phoneFormatted;
    } else {
      return "";
    }
  };

  const modalInputHandler = (value, id) => {
    if (value.length <= 17) {
      for (let key in modalData) {
        if (key === id) {
          setModalData((old) => {
            return {
              ...old,
              [key]: {
                ...old[key],
                value: value,
              },
            };
          });
        }
      }
    }
  };

  const hideModal = () => {
    setStatusModal(false);
  };
  const showModal = () => {
    setStatusModal(true);
  };
  const newPhoneHandler = () => {
    if (
      modalData.newNumber.value.length == 17 &&
      modalData.newNumber.value === modalData.confirmNumber.value
    ) {
      const body = {
        phone_number: "+" + modalData.newNumber.value.replace(/\D/g, ""),
        confirm_method: changeMethod,
      };
      dispatch(loaderShow());
      Api.putData(`profile/phone`, body, localStorage.getItem("userToken"))
        .then((res) => {
          history.push("/confirm-code", {
            typePage: "confirmNew",
            confirm_method: changeMethod,
          });
          dispatch(
            showToast({
              title: "Phone Number",
              text: res?.data?.message,
              type: "success",
            })
          );
          dispatch(loaderHide());
        })
        .catch((err) => {
          dispatch(
            showToast({
              title: "Phone Number",
              text: err?.response?.data?.message,
              type: "error",
            })
          );
          dispatch(loaderHide());
        });
    } else {
      dispatch(
        showToast({
          title: "Phone Number",
          text: "Please enter correct phone number",
          type: "error",
        })
      );
    }
    // history.push("/confirm-code", { phone: "+1 111 111 1111" });
  };
  const renderModal = () => {
    return (
      <DefaultModal close={hideModal} title={"Phone Number"}>
        <Helmet>
          <title>Account Phone | Missit</title>
        </Helmet>
        <div className="account__phone-modal">
          <div className="phone-modal__input">
            <Input
              value={`+1 ${formattedPhone(profileData?.contact_phone)}`}
              disabled={true}
              label={"Current Phone Number"}
              placeholder={"+1 111 111 1111"}
            />
          </div>
          <div className="phone-modal__input">
            <Input
              value={
                modalData.newNumber.value.length > 0
                  ? `+1 ${formattedPhone(modalData.newNumber.value)}`
                  : ""
              }
              label={modalData.newNumber.label}
              id={modalData.newNumber.id}
              type={modalData.newNumber.type}
              placeholder={modalData.newNumber.placeholder}
              error={modalData.newNumber.error}
              showError={modalData.newNumber.errorVisible}
              action={modalInputHandler}
              maxLength={18}
            />
          </div>
          <div className="phone-modal__input">
            <Input
              value={
                modalData.confirmNumber.value.length > 0
                  ? `+1 ${formattedPhone(modalData.confirmNumber.value)}`
                  : ""
              }
              label={modalData.confirmNumber.label}
              id={modalData.confirmNumber.id}
              type={modalData.confirmNumber.type}
              placeholder={modalData.confirmNumber.placeholder}
              error={modalData.confirmNumber.error}
              showError={modalData.confirmNumber.errorVisible}
              action={modalInputHandler}
              maxLength={18}
            />
          </div>
          <div className="account__hr"></div>
          <div className="phone-modal__btn">
            <Button type={"success"} action={newPhoneHandler}>
              <span>Save</span>
            </Button>
          </div>
        </div>
      </DefaultModal>
    );
  };
  const changePhone = () => (
    <div
      onClick={() => {
        setChooseVerify(true);
      }}
    >
      Change phone number
    </div>
  );
  return (
    <section className="account__phone">
      {statusModal && renderModal()}
      {chooseVerify && (
        <ChoosVerify
          setChooseVerify={setChooseVerify}
          showModal={setStatusModal}
          setChangeMethod={setChangeMethod}
        />
      )}
      <div className="container account__container">
        <div className="account__nav">
          <BackButton type={"block"} link={"/account/settings"} />
          <div className="account__nav-info">
            <h1 className="account__nav-title">Phone Number </h1>
            <h3 className="account__nav-description">Current Phone Number</h3>
          </div>
        </div>
        <div className="account__phone-input">
          <Input
            value={`+1 ${formattedPhone(profileData?.contact_phone)}`}
            disabled={true}
            placeholder={"+1 111 111 1111"}
            label={"Phone"}
            actionName={changePhone}
          />
        </div>
        {/* <div className="account__btns">
          <div className="account__btn">
            <Button action={saveHandler} type={"success"}>
              <span>Save</span>
            </Button>
          </div>
          <div className="account__btn">
            <Button action={cancelHandler} type={"gray"}>
              <span>Cancel</span>
            </Button>
          </div>
        </div> */}
      </div>
    </section>
  );
};
