import {loaderHide, loaderShow} from "../Loader/loaderActions";
import Api from "../../../API/api";
import {GET_PROPERTIES} from "../../../API/endpoints";
import {
  ALL_PROPERTIES,
  DELETE_ITEMS_HIDE,
  DELETE_ITEMS_SHOW,
  EDITABLE_CHANGE,
  HIDE_EDITABLE
} from "../../Types/Properties/properties";
import {getProperties} from "./getProperties";
import {getPropertiesById} from "./getPropertiesById";
import {showToast} from "./toastActions";
import {hideShareDocumentModal} from "./UploadDocument";

export const deleteProperty = (id, redirect = () => {
}) => (dispatch) => {
  dispatch(loaderShow())
  Api.delete(`${GET_PROPERTIES}/${id}`, {}, localStorage.getItem('userToken'))
    .then(res => {
      dispatch(showToast({title: 'Property', text: res.data.message, type: 'success'}))
      redirect()
      dispatch(getProperties())
    })
    .catch(err => {
      dispatch(showToast({title: 'Property', text: err.response.data.message, type: 'success'}))
      dispatch(loaderHide())
    })
}

export const deletePropertyItem = (id, itemId, setIdArray) => (dispatch) => {
  dispatch(loaderShow())
  Api.delete(`${GET_PROPERTIES}/${id}`, {}, localStorage.getItem('userToken'))
    .then(res => {
      dispatch(getPropertiesById(itemId))
      dispatch(showToast({title: 'Delete', text: 'Items successfully deleted!', type: 'success'}))
      dispatch(hideShareDocumentModal())
      dispatch(showEditable(false))
      setIdArray([])
    })
    .catch(err => {
      dispatch(loaderHide())
    })
}

export const showDeleteModal = (payload, itemId, editable) => {
  return {
    type: DELETE_ITEMS_SHOW,
    payload,
    itemId,
    editable
  }
}
export const showEditable = (editable) => {
  return {
    type: EDITABLE_CHANGE,
    editable,
  }
}
export const hideDeleteModal = () => {
  return {
    type: DELETE_ITEMS_HIDE
  }
}
// export const getSuccess = (payload) => {
//     return {
//         type: ALL_PROPERTIES,
//         payload
//     }
// }
