import React from "react";
import RenderItem from "./RenderItem";
import RenderEmpty from "./RenderEmpty";
import RenderDocs from "./RenderDocs";

export default ({ itemId, activeTab, data, typeList, docsData }) => {
  return (
    <div className="properties-item__content">
      {activeTab ? (
        data.length > 0 ? (
          <RenderItem data={data} />
        ) : (
          <RenderEmpty itemId={itemId} type={true} />
        )
      ) : docsData.length > 0 ? (
        <RenderDocs typeList={typeList} dots={true} docsData={docsData} />
      ) : (
        <RenderEmpty itemId={itemId} type={false} />
      )}
    </div>
  );
};
