import React, { useEffect } from "react";
import itemOptions from "./itemOptions";
import { useDispatch, useSelector } from "react-redux";
import { deleteProperty } from "../../Store/Actions/Properties/deletePropery";
import { useHistory, useLocation } from "react-router-dom";
import { showPropertyModal } from "../../Store/Actions/Properties/propertyModal";
import { getPropertiesById } from "../../Store/Actions/Properties/getPropertiesById";
import { showPropertyItemModal } from "../../Store/Actions/Properties/addItemToProperty";
import { showShareDocumentModal } from "../../Store/Actions/Properties/UploadDocument";
import {shareProperty} from "../../Store/Actions/Properties/shareProperty";

export default ({ propertiesById, itemId = "id" }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const redirect = () => {
    history.push("/properties");
  };
  const actions = {
    share: () => {
      // dispatch(shareProperty([itemId]))
      dispatch(showShareDocumentModal([localStorage.getItem('item_by_id')]))
    },
    delete: () => {
      dispatch(deleteProperty(localStorage.getItem('item_by_id'), redirect));
    },
    edit: async () => {
      if (location.pathname === "/properties/item/info") {
        dispatch(showPropertyItemModal({ id: localStorage.getItem('item_by_id'), item: propertiesById }));
      } else {
        dispatch(showPropertyModal({ item: propertiesById, itemId: localStorage.getItem('item_by_id') }));
      }
    },
    download: () => {

    },
  };
  useEffect(() => {
    itemOptions.map((item) => {
      for (let key in actions) {
        if (item.id === key) {
          item.action = actions[key];
        }
      }
      return item;
    });
  }, []);
  return itemOptions.map((item) => (
    <div
      onClick={() => item.action(localStorage.getItem('item_by_id'))}
      id={item.id}
      key={item.id}
      className={`option ${item?.border ? "border" : ""}`}
    >
      <span className="icon">
        <img src={item.icon} alt="" />
      </span>
      <span className="text">{item.text}</span>
    </div>
  ));
};
