import React, { useEffect, useRef, useState } from "react";

import "./index.scss";
import { Button, Paginate, Search } from "../../UI";
import Api from "../../API/api";
import { GET_ITEM_STATUS, GET_PROPERTY, GET_WILLIT } from "../../API/endpoints";
import {
  loaderHide,
  loaderShow,
} from "../../Store/Actions/Loader/loaderActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Select from "./../../UI/Select";
import {
  getItemDetails,
  getRelatives,
  showWillItemModal,
} from "../../Store/Actions/WillItem/getItemDetails";
import { getWillList } from "../../Store/Actions/WillItem/getWillList";
import { Helmet } from "react-helmet";
import { showToast } from "../../Store/Actions/Properties/toastActions";
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const list = useSelector((state) => state.willListReducer.data);
  const willItemModal = useSelector(
    (state) => state.willItemReducer.willItemModal
  );
  const profileData = useSelector((state) => state.profileReducer.data);
  const [property, setProperty] = useState([]);
  const [itemStatus, setItemStatus] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [currentItems, setCurrentItems] = useState(null);
  const [query, setQuery] = useState({
    assigned: null,
    property: null,
    status: null,
    search: null,
  });
  let propertyRef = useRef();
  let assignRef = useRef();
  let statusRef = useRef();
  const changePIN = (data) => {
    console.log(data, "123");
    if (data?.status === 403) {
      // dispatch(loaderShow());
      // Api.postData(`will-it/send-otp`, {}, localStorage.getItem("userToken"))
      //   .then((res) => {
      history.push("/confirm-code", {
        typePage: "willit",
        // token: res.data.data.verify_token,
      });
      //     })
      //     .catch((err) => {
      //       dispatch(
      //         showToast({
      //           title: "Confirm verification code",
      //           text: err?.response.data?.message,
      //           type: "error",
      //         })
      //       );
      //       dispatch(loaderHide());
      //     });
      // }
    }
  };
  const lockWill = () => {
    dispatch(loaderShow());
    Api.postData("will-it/lock", {}, localStorage.getItem("userToken"))
      .then((res) => {
        history.push("/confirm-code", {
          typePage: "willit",
        });
        dispatch(
          showToast({
            title: "Lock Will It",
            text: res?.data?.message,
            type: "success",
          })
        );
        dispatch(loaderHide());
      })
      .catch((err) => {
        dispatch(loaderHide());
        if (err?.response?.status !== 403) {
          dispatch(
            showToast({
              title: "Lock Will It",
              text: err?.response?.message,
              type: "error",
            })
          );
        }
      });
  };
  useEffect(() => {
    dispatch(getWillList(query, changePIN));
    getProperty();
    getItemStatus();
  }, []);
  console.log(list);
  useEffect(() => {
    dispatch(getRelatives());
  }, [list]);
  useEffect(() => {
    let s = Object.entries(query).filter(
      ([key, value]) => value !== null
    ).length;
    // if (s > 0) {
    dispatch(getWillList(query, changePIN));
    // }
  }, [query]);

  const renderList = () => {
    return list.map((item) => (
      <div
        onClick={() => {
          dispatch(showWillItemModal());
          dispatch(getItemDetails(item));
          dispatch(getRelatives());
        }}
        key={item.id}
        className="will__item"
      >
        <div className="will__item-left">
          <div className="will__item-preview">
            <img src={item.preview_img} alt="preview" />
          </div>
          <div className="will__item-content">
            <p className="will__item-title">{item.name}</p>
            <p className="will__item-desc">
              Home {item.propertyName ? `> ${item.propertyName}` : null}{" "}
              {item.name ? `> ${item.name}` : null}
            </p>
          </div>
        </div>
        <div className="will__item-right">
          <div className="will__item-content">
            {item.inheritor_name || item.inheritor_relationship ? (
              <div>
                <p className="will__item-title">{item.inheritor_name}</p>
                <p className="will__item-desc">{item.inheritor_relationship}</p>
              </div>
            ) : (
              <p className="will__item-none">Not assigned</p>
            )}
          </div>
        </div>
      </div>
    ));
  };

  const getProperty = () => {
    Api.getData(GET_PROPERTY, localStorage.getItem("userToken"))
      .then((res) => {
        let propertyArray = res.data.data;
        setProperty(
          propertyArray.map((item) => ({
            ...item,
            label: item.name,
            value: item.id,
          }))
        );
      })
      .catch((err) => {});
  };
  const getItemStatus = () => {
    Api.getData(GET_ITEM_STATUS, localStorage.getItem("userToken"))
      .then((res) => {
        let array = [];
        for (let key in res.data.data) {
          array = [
            ...array,
            {
              value: key,
              label:
                key === "assigned"
                  ? `Assigned: ${res.data.data[key]} items`
                  : `Not Assigned: ${res.data.data[key]} items`,
            },
          ];
        }
        let itemsStatus = res.data.data;
        let result = Object.keys(itemsStatus).map((key) => [
          key,
          itemsStatus[key],
        ]);
        setItemStatus(array);
      })
      .catch((err) => {});
  };

  const handlePropertyFilter = (e) => {
    setQuery((old) => ({
      ...old,
      property: e?.value,
    }));
  };
  const handleAssignFilter = (e) => {
    setQuery((old) => ({
      ...old,
      assigned: e?.value,
    }));
  };
  const handleStatusFilter = (e) => {
    setQuery((old) => ({
      ...old,
      status: e?.value === "assigned" ? true : false,
    }));
  };
  const getValueSearch = (value) => {
    setSearchValue(value);
  };
  const resetFilter = () => {
    propertyRef?.current?.clearValue();
    assignRef?.current?.clearValue();
    statusRef?.current?.clearValue();
    setQuery({
      assigned: null,
      property: null,
      status: null,
    });
  };
  const handleSearch = () => {
    setQuery((old) => ({
      ...old,
      search: searchValue,
    }));
    if (query.search !== null) {
      // dispatch(getWillList(query));
    }
  };

  return (
    <div className="will container">
      <Helmet>
        <title>Will It | Missit</title>
      </Helmet>
      <div className="will__header">
        <h1 className="will__title">Will it</h1>
        <div onClick={lockWill} className="will__lock">
          <span>
            <img src="/assets/icons/will-lock.svg" alt="lock" />
          </span>
          Lock
        </div>
      </div>
      <div className="will__content">
        <div className="will__content-header">
          <div className="will__content-left">
            <div className="will__search">
              <Search getValue={getValueSearch} />
            </div>
            <div className="will__btn">
              <Button type={"purple"} action={handleSearch}>
                <span>Search</span>
              </Button>
            </div>
          </div>
          <div className="will__filters">
            <div className="will__select">
              <Select
                customRef={propertyRef}
                options={property}
                placeholder={"By Property"}
                handleSelect={handlePropertyFilter}
              />
            </div>
            <div className="will__select">
              <Select
                customRef={assignRef}
                options={willItemModal.relatives}
                placeholder={"By Assignee"}
                handleSelect={handleAssignFilter}
              />
            </div>
            <div className="will__select">
              <Select
                customRef={statusRef}
                options={itemStatus}
                placeholder={"By Info Item Status"}
                handleSelect={handleStatusFilter}
              />
            </div>
            <div
              onClick={() => {
                resetFilter();
              }}
              className="will__reset"
            >
              <img src="/assets/icons/reset-filter.svg" alt="" />
            </div>
          </div>
        </div>
        <div className="will__head">
          <div className="will__head-column">Name</div>
          <div className="will__head-column">Assignee</div>
        </div>
        <div className="will__main">
          {list?.length > 0 ? (
            renderList()
          ) : (
            <div className="will__empty">
              <img src="/assets/images/will-placeholder.png" alt="" />
              <p className="will__empty-title">
                {list ? "There is nothing yet" : "Confirm Verification code"}
              </p>
              {list && (
                <p className="will__empty-text">
                  Here you can assign your items to your close people
                </p>
              )}
            </div>
          )}
        </div>
        {/*<div className="will__paginate">*/}
        {/*  <Paginate*/}
        {/*    list={list}*/}
        {/*    PerPage={3}*/}
        {/*    currentItems={currentItems}*/}
        {/*    setCurrentItems={setCurrentItems}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
    </div>
  );
};
